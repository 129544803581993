import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {Box, Grid, IconButton} from '@material-ui/core';
import TextField from '../../../TextField/TextField';
import SelectField from '../../../SelectField/SelectField';
import classNames from 'classnames';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../../../store';
import {ReduxState} from '../../../../store/types';
import {SipTrunksListItem} from '../../../../store/reducers/extensions/extensions/reducer';
import CustomizedChip from '../../../Chip/Chip';
import {
    generateDisplayNumberList, SipTrunkFormProps, SipTrunkFormType,
    useStyles,
} from './SipTrunkForm.utils';
import {maxTrunkIdLength} from "../../../../views/SipTrunks/List/CreateNewSipTrunkDialog.utils";
import PasswordTextField from "../../../PasswordTextField/PasswordTextField";
import {getConfigFromPasswordRulesOrDefault} from "../../../../utils/passwordGenerator";
import CopyContentIcon from "../../../CopyContentIcon/CopyContentIcon";
import {CreateSipTrunkFaultString} from "../../../../store/types/SipTrunk";
import {PasswordFaultCode} from "../../../../store/types/Extension";
import {DID_NUMBERS_FETCH_API_ERROR} from "../../../../store/sagas/extensions/extensions/saga";
import AccessDenied from "../../../PermissionProvider/AccessDenied";

const SipTrunkForm: React.FC<SipTrunkFormProps> = (
    {
        didNumbers,
        accountId,
        timeZones,
        countries,
        apiErrors,
        subdivisionsList,
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
        prefixEnabled,
        callback
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const extension = useSelector<ReduxState, SipTrunksListItem | undefined>(
        (state) => state.sipTrunks.sipTrunkDetails?.sipTrunk,
    );

    const configData = useSelector(
        (state: ReduxState) => state.generic.configData,
    );

    const countriesOptionsList = useMemo(
        () => [
            {
                iso_3166_1_a2: '',
                name: '',
            },
            ...(countries || []),
        ],
        [countries],
    );

    const {
        values,
        handleChange,
        errors,
        setFieldValue,
        setFieldError,
        handleSubmit,
        dirty,
        isValid,
    } = useFormikContext<SipTrunkFormType>();

    const [copyPasswordNameVisible, setCopyPasswordNameVisible] = useState(
        false,
    );

    const handlePasswordCopyVisible = () => {
        setCopyPasswordNameVisible((prevState) => !prevState);
    };

    useEffect(() => {
        handleDirtyChange?.('details', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('details', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('details', () => isValid);
    }, [isValid]);

    const renderDidTags = useCallback(
        (selected: string[]) => {
            return selected.map((v) => (
                <CustomizedChip
                    key={v}
                    label={v}
                    handleDelete={
                        v !== extension?.account_info?.did_number
                            ? () => {
                                setFieldValue(
                                    'didNumber',
                                    values.didNumber.filter((w) => w !== v),
                                );
                            }
                            : undefined
                    }
                />
            ));
        },
        [extension, values.didNumber],
    );

    const getSubdivisions = useCallback((iso_3166_1_a2?: string) => {
        if (iso_3166_1_a2) {
            dispatch(
                actions.getSubdivisionData.request({
                    iso_3166_1_a2,
                }),
            );
        }
    }, []);

    const items = useMemo(() => {
        return (
            didNumbers
                ?.filter(
                    (v) =>
                        !v.i_account ||
                        v.i_account === accountId ||
                        v.i_master_account === accountId,
                )
                .map((v) => v.did_number) || []
        );
    }, [didNumbers]);

    const displayItems = useMemo(() => {
        return (
            didNumbers?.map((item) =>
                generateDisplayNumberList(
                    item.did_number,
                    values.displayNumber,
                    prefixEnabled || false,
                    '+',
                ),
            ) || []
        );
    }, [didNumbers]);

    useEffect(() => {
        let apiErrorsExist = false;
        if (
            apiErrors?.faultcode ===
            'Server.Customer.Ext_HG_number_already_in_use' ||
            apiErrors?.faultcode ===
            'Client.Customer.Ext_HG_number_already_in_use'
        ) {
            setFieldError(
                'extensionNumber',
                t('errors:faxMailboxes.numberInUse'),
            );
            apiErrorsExist = true;
        } else if (
            apiErrors?.faultstring === CreateSipTrunkFaultString.DuplicateId
        ) {
            apiErrorsExist = true;
            setFieldError(
                'sipTrunk',
                t('errors:sipTrunks.sipTrunkNumberExists'),
            );
        } else if (apiErrors?.faultcode == PasswordFaultCode.InvalidChars) {
            setFieldError(
                'servicePassword',
                t('errors:sipTrunks.invalidChars'),
            );
            apiErrorsExist = true;
        } else if (apiErrors?.faultstring == CreateSipTrunkFaultString.InvalidId) {
            setFieldError(
                'sipTrunk',
                t('errors:sipTrunks.sipTrunkNumberInvalid'),
            );
            apiErrorsExist = true
        }

        if(apiErrorsExist && callback)
        {
            callback();
        }
    }, [apiErrors]);


    if(apiErrors?.faultcode === DID_NUMBERS_FETCH_API_ERROR) {
        return <AccessDenied subPageMode />;
    }

    return (
        <div className={classes.inputs}>
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.topContainer,
                )}
            >
                <div className={classes.rowContainer}>
                    <TextField
                        id="sipTrunk"
                        label={t('screens:sipTrunks.trunkId')}
                        onChange={handleChange}
                        value={values.sipTrunk}
                        icon={
                            <IconWithTooltip
                                dataQa="sip-trunk-number-tooltip"
                                tooltipText={t(
                                    'tooltips:sipTrunks.trunkId',
                                )}
                            />
                        }
                        iconPosition="end"
                        dataQa="sip-trunk-number-input"
                        helperText={errors.sipTrunk}
                        setFieldError={setFieldError}
                        required
                        maxLength={maxTrunkIdLength}
                    />

                    <PasswordTextField
                        onMouseEnter={handlePasswordCopyVisible}
                        onMouseLeave={handlePasswordCopyVisible}
                        id="servicePassword"
                        label={t('screens:extensions.servicePassword')}
                        value={values.servicePassword}
                        onChange={(e) => setFieldValue('servicePassword', e.target.value.replaceAll(' ', ''))}
                        setFieldValue={setFieldValue}
                        dataQa="sip-service-password-input"
                        setFieldError={setFieldError}
                        error={errors.servicePassword}
                        isRequired={true}
                        passwordRulesConfig={getConfigFromPasswordRulesOrDefault(
                            configData
                        )}
                        maxLength={32}
                        icon={
                            <>
                                <div
                                    style={{
                                        visibility: copyPasswordNameVisible
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        className={classes.visibilityIcon}
                                        data-testid="service-password-visibility-button"
                                        data-qa="service-password-visibility-button"
                                    >
                                        <CopyContentIcon
                                            copyContent={values.servicePassword}
                                            tooltipMsg={t(
                                                'tooltips:extensions.copyServicePassword',
                                            )}
                                        />
                                    </IconButton>
                                </div>
                            </>
                        }
                    />
                </div>

                <div className={classes.rowContainer}>
                    <SelectField
                        label={t('screens:extensions.didNumber')}
                        onChange={(e, value) =>
                            setFieldValue('didNumber', value)
                        }
                        items={items}
                        value={values.didNumber}
                        multiple
                        icon={
                            <IconWithTooltip
                                dataQa="sip-trunk-did-numbers-tooltip"
                                tooltipText={t('tooltips:sipTrunks.didNumbers')}
                            />
                        }
                        dataQa="sip-trunk-didnumbers-input"
                        getOptionDisabled={(v) =>
                            v === extension?.account_info?.did_number
                        }
                        renderTags={renderDidTags}
                        disableClearable
                        disableAutoSettingValue
                    />

                    <SelectField
                        onChange={(e, value) =>
                            setFieldValue('displayNumber', value)
                        }
                        label={t('screens:extensions.displayNumber')}
                        items={displayItems}
                        value={values.displayNumber || null}
                        icon={
                            <IconWithTooltip
                                dataQa="sip-trunk-display-number-tooltip"
                                tooltipText={t(
                                    'tooltips:sipTrunks.displayNumber',
                                )}
                            />
                        }
                        dataQa="sip-trunk-displaynumber-input"
                        disableAutoSettingValue
                    />
                </div>
            </Grid>

            <div className={classes.separator}/>

            <Grid item className={classes.itemsContainer}>
                <div className={classes.rowContainer}>
                    <TextField
                        id="emailAddress"
                        label={t(
                            'screens:extensions.emailAddress',
                        )}
                        onChange={handleChange}
                        value={values.emailAddress}
                        icon={
                            <IconWithTooltip
                                dataQa="sip-trunk-email-tooltip"
                                tooltipText={t(
                                    'tooltips:sipTrunks.emailAddress',
                                )}
                            />
                        }
                        iconPosition="end"
                        dataQa="sip-trunk-email-input"
                        helperText={
                            !!errors.emailAddress
                                ? t(
                                    `errors:extensions.emailAddress`,
                                )
                                : ''
                        }
                        setFieldError={setFieldError}
                        maxLength={128}
                    />

                    <SelectField
                        label={t('screens:extensions.timezone')}
                        items={
                            timeZones?.map(
                                (v) => v.time_zone_name,
                            ) || []
                        }
                        onChange={(_, value) =>
                            setFieldValue('timezone', value)
                        }
                        value={values.timezone || null}
                        dataQa="sip-trunk-timezone-input"
                        helperText={errors.timezone || ''}
                        setFieldError={setFieldError}
                        disableClearable
                    />
                </div>
                <div className={classes.rowContainer}>
                    <TextField
                        label={t('screens:extensions.office')}
                        value={values.office}
                        readOnly
                        dataQa="sip-trunk-office-input"
                    />
                    <div style={{flex: 1}}/>
                </div>

            </Grid>

            <Grid item className={classes.itemsContainer}>
                <Box className={classes.emergencyHeaderContainer}>
                            <span className={classes.emergencyHeader}>
                                {t('screens:extensions.emergencyLocation')}
                            </span>

                    <IconWithTooltip
                        dataQa="sip-trunk-emergency-location-tooltip"
                        tooltipText={t(
                            'tooltips:sipTrunks.emergencyLocation',
                        )}
                    />
                </Box>
                <div className={classes.rowContainer}>
                    <SelectField
                        label={t('screens:extensions.country')}
                        items={
                            countriesOptionsList?.map((v) => v.name) ||
                            []
                        }
                        value={
                            countriesOptionsList?.find(
                                (v) =>
                                    v.iso_3166_1_a2 === values.country,
                            )?.name || null
                        }
                        onChange={(_, value) => {
                            const country = countriesOptionsList?.find(
                                (v) => v.name === value,
                            )?.iso_3166_1_a2;

                            setFieldValue(
                                'country',
                                country || '',
                                false,
                            );
                            setFieldValue('state', '');
                            getSubdivisions(country);
                        }}
                        dataQa="sip-trunk-country-input"
                    />

                    <TextField
                        id="city"
                        label={t('screens:extensions.city')}
                        onChange={handleChange}
                        value={values.city}
                        dataQa="sip-trunk-city-input"
                        maxLength={31}
                    />
                </div>
                <div className={classes.rowContainer}>
                    <TextField
                        id="address"
                        label={t('screens:extensions.address')}
                        onChange={handleChange}
                        value={values.address}
                        dataQa="sip-trunk-address-input"
                        maxLength={41}
                    />

                    <TextField
                        id="postalCode"
                        label={t('screens:extensions.postalCode')}
                        onChange={handleChange}
                        value={values.postalCode}
                        dataQa="sip-trunk-postal-code-input"
                        maxLength={10}
                        helperText={errors.postalCode}
                        setFieldError={setFieldError}
                    />
                </div>
                <div className={classes.rowContainer}>
                    <SelectField
                        label={t('screens:extensions.provinceState')}
                        dataQa="sip-trunk-state-input"
                        items={
                            subdivisionsList?.map((v) => v.name) || []
                        }
                        value={values.state || null}
                        onChange={(_, value) => {
                            setFieldValue('state', value || null);
                        }}
                        disabled={!values.country}
                    />

                    <div style={{flex: 1}}/>
                </div>
            </Grid>

        </div>
    );
};

export default SipTrunkForm;
