import React from "react";
import {ReactComponent as Lock} from '../../assets/lock_full.svg';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../styles/Colors";
import {PermissionType} from "../../store/types/Permission";


const useStyles = makeStyles(()=>({
    container: {
        borderColor: Colors.Secondary1,
        borderRadius:4,
        borderWidth:1,
        borderStyle:"solid",
        width:"fit-content",
        padding:8,
        display:"flex",
        alignItems:"center",
        color:Colors.Text3,
        lineHeight:'20px',
        fontWeight:400,
        marginBottom:24
    },
    icon:{
        marginRight:10
    },
    firstPart:{
        display:"flex",
        alignItems:"center",
        fontSize:16,
        fontWeight:700,
        marginRight:5
    }
}))
const ReadOnlyWarning: React.VFC<{permission:PermissionType}> = ({permission}) => {

    const classes = useStyles();

    if(permission !== PermissionType.ReadOnly)
    {
        return <></>
    }

    return (
        <div className={classes.container} id={'read-only-warning-component'}>
            <span className={classes.firstPart}>
                <Lock className={classes.icon}/>
                Read-only access.
            </span>
            <span>You don`t have permission to change these options.</span>
        </div>
    )
}

export default ReadOnlyWarning

