import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {IconButton} from '@material-ui/core';
import {Colors} from '../../styles/Colors';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {ChevronLeft} from '@material-ui/icons';
import history from '../../history';
import GeneralForm from '../../components/Forms/RingGroups/GeneralForm';
import {APIErrorInterface, ReduxState} from '../../store/types';
import {ApiFile} from '../../store/types/ApiFile';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../store';
import Loader from '../../components/Loader/Loader';
import GroupMembersForm from '../../components/Forms/RingGroups/GroupMembersForm';
import CallQueueForm from '../../components/Forms/RingGroups/CallQueueForm';
import {useEditRingGroupFormData} from '../../hooks/useEditRingGroupFormData';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../components/AlertDialog/DialogContainer';
import {getTabNumber} from '../../utils/getTabNumber';
import classNames from 'classnames';
import usePageTitle from '../../hooks/usePageTitle';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import {
    EditRingGroupForm,
    formValidationSchema,
    RingGroupDetailsProps,
    useStyles,
} from './RingGroupDetails.utils';
import {Routes} from '../../routes/routes';
import {Permission, PermissionType} from '../../store/types/Permission';
import {useRawPermissions} from '../../hooks/usePermissions';
import {generateTabs} from '../../utils/generateTabs';
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import NotFound from '../NotFound/NotFound';
import {RING_GROUP_NOT_FOUND_BY_ID_ERROR_CODE} from '../../store/sagas/ringgroups/saga';
import {CustomerOfficeType} from '../../store/types/CustomerInfo';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import ErrorNotification from "../../components/TabErrors/ErrorNotification";
import {partialValidation} from "../../components/Forms/RingGroups/callQueueFormUtils";

const RingGroupDetails: React.VFC<RingGroupDetailsProps> = (
    {
        id,
        forceSaveIsActive,
    }) => {
    const [tabIndex, setTabIndex] = useState(getTabNumber([0, 1, 2]));
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [validationSchema, setValidationSchema] = useState<any>(formValidationSchema)

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();

    const permissions = useRawPermissions();

    const [groupMemberDialogVisible, setGroupMemberDialogVisible] = useState(
        false,
    );

    const [toastApiErrorVisible, setToastApiErrorVisible] = useState(false);

    const ringbackTonesList = useSelector<ReduxState, ApiFile[]>(
        (state) => state.ringgroups.ringbackTonesList || [],
    );

    const loading = useSelector<ReduxState, boolean>(
        (state) => !!state.ringgroups.isFormLoading,
    );

    const isFormSaving = useSelector<ReduxState, boolean>(
        (state) => !!state.ringgroups.isFormSending,
    );

    const apiError = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.ringgroups.apiError,
    );

    const generalApiErrors = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.ringgroups.errors,
    );

    const i_office_type: CustomerOfficeType = useSelector<ReduxState, CustomerOfficeType>(
        (state) => state.generic?.customerOfficeType || CustomerOfficeType.Hq
    );

    const saveClicked = useSelector(
        (state: ReduxState) => state.tabs?.saveForms || false,
    );

    const {initialValues, data} = useEditRingGroupFormData();

    useEffect(() => {
        dispatch(actions.ringGroupDetails.request({i_c_group: id}));
        dispatch(actions.getCallQueues.request());
    }, []);

    useEffect(() => {

        if (generalApiErrors != undefined && tabIndex != 0 && !toastApiErrorVisible) {
            setToastApiErrorVisible(true);
        }
    }, [generalApiErrors, tabIndex]);

    const isApiError = useMemo(() => {
        return apiError?.faultcode === RING_GROUP_NOT_FOUND_BY_ID_ERROR_CODE;
    }, [apiError]);

    const onSubmitForm = useCallback(
        (form: EditRingGroupForm) => {
            dispatch(
                actions.editRingGroup.request({
                    initialValues,
                    changedValues: form,
                    i_c_group: id,
                    redirectTab: tabIndex,
                }),
            );
        },
        [initialValues, id, tabIndex],
    );

    const removeRingGroup = () => {
        dispatch(
            actions.removeRingGroup.request({
                i_c_group: id,
                withRedirectToList: true,
            }),
        );
    };

    const onBackPress = useCallback(() => {
        history.push(Routes.RingGroups);
    }, []);

    const validateBranchOffice = useCallback((value: PermissionType) => {
        if (value === PermissionType.Visible && i_office_type === CustomerOfficeType.Branch) {
            return PermissionType.ReadOnly;
        }
        return value;
    }, [i_office_type]);

    if (isApiError) {
        return (
            <NotFound/>
        );
    }

    if (loading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="add-ring-group-loader"/>
            </div>
        );
    }

    const onSave = (form: EditRingGroupForm) => {
        dispatch(
            actions.editRingGroup.request({
                initialValues,
                changedValues: form,
                i_c_group: id,
                blockRedirection: true,
            }),
        );
    };

    const handleValidationChange = (flag: boolean) => {
        if (flag) {
            setValidationSchema((prev: any) => prev.concat(partialValidation));
        } else {
            setValidationSchema(formValidationSchema)
        }
    }

    const {tabNames, tabs, perm} = generateTabs(
        [
            {
                title: t('common:general'),
                permission:
                Permission.CloudPBX.RingGroups.RingGroupDetails.General
                    .value,
                tab: <GeneralForm
                    ringbackTonesList={ringbackTonesList}
                    tabName={t('common:general')}
                    tabIndex={0}
                    callback={() => dispatch(actions.saveForms({visible: true}))}
                    wrapUpVisible={data.isWrapUpVisible}
                    wrapUpLock={data.wrapUpLock}
                />,
                customizePermission: validateBranchOffice
            },
            {
                title: t('screens:ringGroups.members'),
                permission:
                Permission.CloudPBX.RingGroups.RingGroupDetails.Members
                    .value,
                tab: (
                    <GroupMembersForm
                        groupMemberDialogVisible={groupMemberDialogVisible}
                        toggleMemberDialogVisible={() =>
                            setGroupMemberDialogVisible(
                                !groupMemberDialogVisible,
                            )
                        }
                        masterRingGroupId={data?.details?.id}
                    />
                ),
                customizePermission: validateBranchOffice
            },
            {
                title: t('screens:ringGroups.callQueue'),
                permission:
                Permission.CloudPBX.RingGroups.RingGroupDetails.CallQueue
                    .value,
                tab: <CallQueueForm
                    tabName={t('screens:ringGroups.callQueue')}
                    tabIndex={2}
                    statusChangeCallback={handleValidationChange}
                />,
                customizePermission: validateBranchOffice
            },
        ],
        permissions,
    );

    const top = (
        <div className={classes.extensionHeader}>
            <div className={classes.headerContainer}>
                <IconButton
                    style={{marginRight: 10}}
                    onClick={onBackPress}
                    data-qa={'back-icon'}
                >
                    <ChevronLeft htmlColor={Colors.Text}/>
                </IconButton>
                <div className={classes.headerContainer}>
                    <h2 className={classes.header}>
                        {t('screens:ringGroups.ringGroup')}
                    </h2>
                    <h2 className={classes.subheader}>{data?.details?.id}</h2>
                    <h2 className={classes.ternaryHeader}>
                        {data?.details?.name}
                    </h2>
                </div>
            </div>
            <div className={classes.headerDetails}>
                <div className={classes.column}>
                    <h4 className="header">{t('common:number')}</h4>
                    <span className="value">{data?.details?.id}</span>
                </div>
                <div
                    className={classNames(
                        classes.column,
                        classes.centeredColumn,
                    )}
                >
                    <h4 className="header">
                        {t('screens:ringGroups.ringStrategy')}
                    </h4>
                    <span className="value">
                        {t(
                            `enums:ringStrategy.${data?.details?.hunt_sequence}`,
                        )}
                    </span>
                </div>
            </div>
        </div>
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitForm}
            enableReinitialize
            validateOnChange={false}
        >
            {({submitForm, dirty, values}) => (
                <>
                    <PermissionPlaceholder permission={Permission.CloudPBX.RingGroups.RingGroupDetails.value}>
                        <PermissionProvider
                            permission={Permission.CloudPBX.RingGroups.RingGroupDetails.value}
                            customizePermission={validateBranchOffice}
                        >
                            <DetailsWrapper
                                tabs={tabs}
                                tabsNames={tabNames}
                                top={top}
                                defaultTabIndex={tabIndex}
                                onTabChange={(_, index: number) => {
                                    dispatch(actions.saveForms({visible: false}));
                                    setTabIndex(index);
                                }}
                                onSavePress={() => {
                                    submitForm().then(() => {
                                        dispatch(actions.saveForms({visible: true}));
                                        setToastApiErrorVisible(false)
                                    })
                                }
                                }
                                onBackPress={onBackPress}
                                onDeletePress={() => {
                                    setIsRemoveModalOpen(true);
                                }}
                                saveEnable={forceSaveIsActive || dirty}
                                preventSavePress={() => onSave(values)}
                                preventIsOpen={dirty}
                                deleteText={t('screens:ringGroups.deleteRingGroup')}
                                showActionButtons
                                deleteEnable
                                deletePermission={
                                    Permission.CloudPBX.RingGroups.RingGroupDetails
                                        .DeleteRingGroup.value
                                }
                                customizeDeletePermission={validateBranchOffice}
                                perm={perm}
                            >
                                {isFormSaving && (
                                    <Loader
                                        dataQa="edit-ring-group-sending-loader"
                                        absolutePosition
                                    />
                                )}
                                <AlertDialog
                                    isOpen={isRemoveModalOpen}
                                    dataQa="remove-ringgroup-modal"
                                    contentClass="medium-width-modal-delete"
                                    hideHeader={true}
                                    className={classes.container}
                                    description={t(
                                        'screens:ringGroups.deleteRingGroupDescription',
                                        {name: data?.details?.name},
                                    )}
                                    dialogActionsButtons={[
                                        <DialogButton
                                            key="cancel"
                                            label={t('common:cancel')}
                                            onClick={() => setIsRemoveModalOpen(false)}
                                        />,
                                        <DialogButton
                                            key="delete"
                                            label={t('common:delete')}
                                            className={classes.primaryModalButton}
                                            onClick={removeRingGroup}
                                        />,
                                    ]}
                                />
                                <ErrorNotification tabName={tabNames[tabIndex]} isSubmitting={saveClicked}/>
                                <ErrorNotification tabName={tabNames[tabIndex]} isSubmitting={toastApiErrorVisible}/>
                            </DetailsWrapper>
                        </PermissionProvider>
                    </PermissionPlaceholder>
                </>
            )}
        </Formik>
    );
};

export default RingGroupDetails;
