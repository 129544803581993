import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as actions from '../../actions';
import { toast } from 'react-hot-toast';
import i18n from '../../../services/i18n';
import { AxiosResponse } from 'axios';
import {
    DIDAreaInfo,
    GetDIDAreasListRequest,
    GetDIDAreasListResponse,
    GetDIDCountriesListRequest,
    GetDIDCountriesListResponse,
    GetDIDNumbersListRequest,
    GetDIDNumbersListResponse,
    GetProviderInfoRequest,
    GetProviderInfoResponse,
} from '../../types/DIDProvider';
import { api } from '../../services/axios';
import JSONFormData from '../../../utils/JSONFormData';
import { YesNo } from '../../types/CallScreening';
import { getGlobalCurrencyData } from '../generic/saga';
import { showErrorToast } from '../../../utils/showErrorToast';
import { DIDProvider } from '../../../services/endpoints';
import { assignDIDNumber } from '../didNumbers/saga';

export function* getDIDInventoryDetails() {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const { i_customer } = yield select((state) => state.generic.sessionData);
    const providerPayload: GetProviderInfoRequest = {
        did_provider_info: { i_customer: i_customer },
    };

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams(providerPayload);

    try {
        const providerResponse: AxiosResponse<GetProviderInfoResponse> = yield api.post(
            DIDProvider.GetProviderInfo,
            body,
        );

        yield call(getGlobalCurrencyData);

        const { provider_info } = providerResponse.data;
        if (provider_info) {
            const countryPayload: GetDIDCountriesListRequest = {
                did_provider_info: providerPayload.did_provider_info,
                for_idle_numbers_only: YesNo.No,
            };

            body.setParams(countryPayload);

            const countryResponse: AxiosResponse<GetDIDCountriesListResponse> = yield api.post(
                DIDProvider.GetCountriesList,
                body,
            );

            const payload = {
                provider: providerResponse.data,
                countries: countryResponse.data.countries_list,
                numbers: null,
            };

            yield put(actions.getDIDInventoryDetails.success(payload));
        }
    } catch (error) {
        showErrorToast(error.response?.data?.faultstring);
    }
}

export function* getAreas(
    action: ActionType<typeof actions.getDIDAreas.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        const { i_customer } = yield select(
            (state) => state.generic.sessionData,
        );

        const providerPayload: GetProviderInfoRequest = {
            did_provider_info: { i_customer },
        };

        const areaPayload: GetDIDAreasListRequest = {
            did_provider_info: providerPayload.did_provider_info,
            for_idle_numbers_only: YesNo.No,
        };
        const responses: DIDAreaInfo[] = [];
        const country = action.payload.country;

        areaPayload.req_params = country.req_params;
        body.setParams(areaPayload);
        const res: AxiosResponse<GetDIDAreasListResponse> = yield api.post(
            DIDProvider.GetAreasList,
            body,
        );
        responses.push(...res.data.areas_list);
        yield put(actions.getDIDAreas.success(responses));
    } catch (err) {
        yield put(actions.getDIDAreas.failure());
    }
}

export function* getNumbers(
    action: ActionType<typeof actions.getDidProviderNumbers.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        const { i_customer } = yield select(
            (state) => state.generic.sessionData,
        );

        const numberPayload: GetDIDNumbersListRequest = {
            did_provider_info: { i_customer },
            idle_only: YesNo.No,
            req_params: action.payload.area.req_params,
        };

        body.setParams(numberPayload);

        const res: AxiosResponse<GetDIDNumbersListResponse> = yield api.post(
            DIDProvider.GetNumbersList,
            body,
        );
        if (res.data.numbers_list) {
            yield put(
                actions.getDidProviderNumbers.success(res.data.numbers_list),
            );
        }
    } catch (err) {
        yield put(actions.getDidProviderNumbers.failure());
    }
}

export function* assignProvisionNumber(
    action: ActionType<typeof actions.postProvisionNumber.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const { i_customer } = yield select((state) => state.generic.sessionData);

    const body = new JSONFormData(session_id, csrf_token);
    action.payload.did_provider_info = { i_customer: i_customer };
    body.setParams(action.payload);

    try {
        const response: AxiosResponse<{
            did_order_info: { i_did_number: number };
        }> = yield api.post(DIDProvider.Provision_number, body);

        if ((action.payload.assignTo?.i_master_account ?? 0) > 0) {
            yield assignDIDNumber(
                actions.assignDIDNumberToAccount.request({
                    assignTo: {
                        ...action.payload.assignTo,
                        i_master_account: action.payload.assignTo?.i_master_account ?? 0,
                        i_did_number: response.data.did_order_info.i_did_number,
                    },
                    disableToasts: true,
                }),
            );
        }

        yield put(actions.postProvisionNumber.success());
        toast(i18n.t<string>('screens:didNumbers.didProvisionNumberSuccess'));
    } catch (error: any) {
        showErrorToast(error.response?.data?.faultstring);
        yield put(actions.postProvisionNumber.failure());
    }
}

export const didInventorySaga = [
    takeLatest(actions.getDIDInventoryDetails.request, getDIDInventoryDetails),
    takeLatest(actions.postProvisionNumber.request, assignProvisionNumber),
    takeLatest(actions.getDIDAreas.request, getAreas),
    takeLatest(actions.getDidProviderNumbers.request, getNumbers),
];
