import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../styles/Colors';
import { DefaultAnsweringActionFlag } from '../../../../store/types/ServiceFeature';
import { EmailOption, VoicemailFileFormat, VoicemailGreeting } from '../../../../store/types/Voicemail';
import * as Yup from 'yup';
import i18n from "../../../../services/i18n";

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        backgroundColor: Colors.LightGray,
        padding: '17px 25px 17px 25px',
        marginTop: 20,
        overflow: 'visible',
    },

    customContainer: {
        maxWidth: 320,
        marginRight: 24,
    },
    columnItemsContainer: {
        flexDirection: 'column',
    },

    tabs: {
        '& .MuiAppBar-root': {
            backgroundColor: 'transparent',
            padding: 0,
            height: 46,
            marginTop: 10,
            marginBottom: 18,
        },

        '& .MuiBox-root': {
            padding: 0,
        },
    },
    numberInput: {
        marginRight: 20,

        '& input[type="number"]::-webkit-inner-spin-button': {
            opacity: 1,
        },
        '& input[type="number"]::-webkit-outside-spin-button': {
            opacity: 1,
        },
    },

    inputs: {
        display: 'flex',

        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
        },
    },

    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
    },

    callScreeningBox: {
        height: 'auto',
    },

    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
        fontWeight: 700,
    },
    rowBoxWithoutFlex: {
        padding: 0 + '!important',
    },
    sectionSpace: {
        marginTop: 48,
    },

    minText: {
        position: 'absolute',
        marginTop: 17,
        marginLeft: 58,
        top: 10.5
    },

    separator: {
        height: 1,
        width: 944,
        backgroundColor: Colors.Border,
    },

    defaultInput: {
        maxWidth: 320,
        marginRight: 23,
    },
    sipPhoneInput: {
        width: 170,
        marginRight: 20,

        '& ::placeholder': {
            textOverflow: 'ellipsis !important',
            color: Colors.Text,
            opacity: 1,
        },
    },
    sipProxyInput: {
        width: 290,
    },
    numberLabel: {
        marginLeft: -60,

        '& .Mui-disabled': {
            '& .MuiInputAdornment-root': {
                color: Colors.Gray,
            },
        },
    },

    ringListContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 38,
        marginBottom: 18,
        paddingBottom: 10,
        overflow: 'visible',
        height: 80,
        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& > :nth-child(n)': {
            marginRight: 16,
            flex: '0 0 auto',
        },

        '& > :nth-child(1)': {
            marginRight: 0,
        },

        '& > :nth-child(2)': {
            marginLeft: 69,
        },
    },
    addToGroupButton: {
        '& .MuiButton-label': {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
        },
    },
    sipProxyContainer: {
        display: 'inline-flex',
        flexDirection: 'column',
        width: 290,
        padding: '0 !important',
    },
    sipHint: {
        color: Colors.PlaceHolder,
        lineHeight: '12px',
        fontSize: 12,
        marginTop: 4,
    },
    rowBoxWithSpaceBetween: {
        display: 'flex',
        padding: 0 + '!important',
        marginTop: 40,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconPlaceholder: {
        width: 50,
    },
    ringGroupSlick: {
        width: 800,
    },
    simpleForwardToInput: {
        height: 'unset !important',
        width: 240,
        backgroundColor: 'transparent !important',

        '& .MuiInputBase-root': {
            backgroundColor: Colors.White,
        },

        '& .MuiInputLabel-root': {
            zIndex: 1,
        },

        '& p': {
            '& > *': {
                position: 'unset !important',
            },
        },
    },

    contentContainer: {
        padding: '22px 16px',
        maxWidth: 944,
        display: 'flex',
        flexDirection: 'column',
    },

    faxContentContainer: {
        padding: '20px 16px 20px 16px',
        maxWidth: 944,
        display: 'flex',
        flexDirection: 'column',

        '& div:first-child': {
            height: 'auto',
            marginBottom: 0,
        },
    },

    collapsedContainer: {
        minHeight: 56,
        height: 56,
        padding: '9px 25px',
    },

    row: {
        paddingTop: 16,
        marginBottom: '19px',
        display: 'flex',
        marginLeft: 38,
    },
    
    faxRadioButtonsRow: {
        display: 'flex',
        marginLeft: 38,
        marginTop: 16,
        width: 'fit-content'
    },

    singleRow: {
        display: 'flex',
    },
    
    emailAddressIncoming: {
        marginRight: 16,
    },

    emailOption: {
    },

    faxFormat: {
        marginLeft: 16,
    },
    
    greetingDetailsText: {
        fontWeight: 400,
        fontSize: 12,
        color: Colors.Gray5,
        maxWidth: 410,
        marginTop: 5,
        padding: '0 15px',
        lineHeight: '13px',
        marginBottom: 15,
    },
    
    pinInput: {
        maxWidth: 214,
        marginLeft: 68,
        marginTop: 16,

        '& #pin-helper-text > span':{
            marginBottom:'-10px'
        }
    },
    
    bottomSeparator: {
        marginTop: 18,
        marginBottom: 16,
    },

    radioContainer: {
        marginLeft: 7
    },

    greetingContainer: {
        width: '348px !important',
        minWidth: '348px !important',
        marginRight: 16
    },
    
    greetingFileContainer: {
        marginTop: '0!important',
        padding: '0!important',
        '& .MuiAutocomplete-root': {
            width: 440,
        },
    },
    
    greetingOutsideContainer: {
        padding: '0 !important',
        width: '252px !important',
        maxWidth: '252px !important',
    },

    greetingRecordTooltip: {
        marginBottom: 17
    },

    rowCheckBox: {
        height: 22,
        marginLeft: 38,
        paddingTop: 16,
        display: 'flex'
    }
}));

export type IncomingFormProps = {
    accountId?: number;
    handleDirtyChange?: (funcName: string, isDirty: boolean) => void;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
};

export const incomingFormDefaultValues: IncomingFormType = {
    defaultAnsweringMode: DefaultAnsweringActionFlag.RingForwardVoicemail,
    defaultAnsweringTimeout: 15,
    //@ts-ignore
    timeout: '',
    emailAddressIncoming: '',
    emailOption: EmailOption.None,
    faxFormat: '',
    faxMailboxLocked: false,
    
    unifiedMessaging: false,
    faxMailboxSwitcher: false,
    greetingType: VoicemailGreeting.Standard,
    requirePinStatus: false,
    autoPlayStatus: false,
    announceDateStatus: false,
    pin: '',
    unifiedMessagingEmailAddress: '',
    fileFormat: VoicemailFileFormat.MP3,
    greetingFileName: '',
    greetingFile: null
};

export enum FaxFormat {
    OnePNGFilePerPage = 'multi_png',
    OneTIFFFilePerPage = 'multi_tiff',
    MultipageTIFFFile = 'tiff',
    MultipagePDFFile = 'pdf',
}

export const mappedValues = {
    emailAddressIncoming: 'ext_email',
    emailOption: 'ext_email_action',
    faxFormat: 'fax_file',
    DISASwitcher: 'enable_disa',
    DISAPassword: 'disa_password',
};

export type MappedValuesType = {
    ext_email: string;
    ext_email_action: string;
    fax_file: string;
    enable_disa: string;
    disa_password: string;
};

export type IncomingFormType = {
    defaultAnsweringMode: DefaultAnsweringActionFlag;
    defaultAnsweringTimeout: number;
    emailAddressIncoming: string;

    unifiedMessagingLocked: boolean;
    unifiedMessaging: boolean;
    faxMailboxSwitcher: boolean;

    emailOption: EmailOption;
    faxFormat: string;

    requirePinStatus: boolean;
    autoPlayStatus: boolean;
    announceDateStatus: boolean;
    pin: string;
    unifiedMessagingEmailAddress: string;
    fileFormat: VoicemailFileFormat;
    greetingType: VoicemailGreeting;
    greetingFileName?: string;
    greetingFile?: File | null;
};

const pinOnlyDigits = i18n.t('errors:extensions.pinOnlyDigits');
const minPinLength = i18n.t('errors:extensions.pin');
const requiredField = i18n.t('errors:common.emptyInput');

export const incomingFormValidationSchema = Yup.object().shape({
    emailAddressIncoming: Yup.string().email().notRequired(),
    pin: Yup.string().when('requirePinStatus', {
        is: true,
        then: Yup.string()
            .matches(/^[0-9]*$/, pinOnlyDigits)
            .min(3, minPinLength)
            .required(requiredField),
        otherwise: Yup.string().notRequired(),
    }),
});
