import React, {useEffect, useRef, useState} from 'react';
import {ReduxState} from '../../../store/types';
import Loader from '../../../components/Loader/Loader';
import {actions} from '../../../store';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../../components/AlertDialog/DialogContainer';
import {EditExtensionForm, useStyles} from './utils';
import {useDispatch, useSelector} from 'react-redux';
import history from '../../../history';
import {Routes} from '../../../routes/routes';
import {useTranslation} from 'react-i18next';
import {useExtensionDetailsForm} from '../../../hooks/useExtensionDetailsForm';
import {getTabNumber} from '../../../utils/getTabNumber';
import PlanTab from './PlanTab';
import {MainProductType} from '../../../components/Extensions/MainProductSelectFiled/utils';
import DeviceTab from './DeviceTab';
import ExtensionTab from './ExtensionTab';
import ExtensionDetailsHeader from './ExtensionDetailsHeader';
import CallSettingsTab from './CallSettingsTab';
import usePageTitle from '../../../hooks/usePageTitle';
import CallHistoryForm from '../../../components/Forms/Extensions/CallHistory/CallHistoryForm';
import CallForwardingTab from '../../../components/Tabs/CallForwardingTab';
import CallScreeningTab from '../../../components/Tabs/CallScreeningTab';
import {TabInterface} from '../../../components/Tabs/Tabs.utils';
import DetailsWrapper from '../../../components/DetailsWraper/DetailsWrapper';
import Config from '../../../config.json';
import {Permission} from '../../../store/types/Permission';
import PermissionProvider from '../../../components/PermissionProvider/PermissionProvider';
import {generateTabs} from '../../../utils/generateTabs';
import {useRawPermissions} from '../../../hooks/usePermissions';
import {convertBool} from "../../../utils/parseBool";
import {EXTENSION_NOT_FOUND_BY_ID_API_ERROR} from '../../../store/sagas/extensions/extensions/saga';
import NotFound from '../../NotFound/NotFound';
import {indexOf} from "lodash";
import ErrorNotification from "../../../components/TabErrors/ErrorNotification";

export type DetailsProps = {
    id: string;
    actionButtonVisible?: boolean;
};

interface ExtensionDetailsPayload {
    init: Partial<EditExtensionForm>;
    updated: Partial<EditExtensionForm>;
}

const ExtensionDetails: React.VFC<DetailsProps> = (
    {
        id,
        actionButtonVisible,
    }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    usePageTitle();

    const {
        i_account,
        extension_id,
        i_c_ext,
        i_customer,
        account_id,
        um_domain,
        country,
    } = useExtensionDetailsForm();

    const saveClicked = useSelector(
        (state: ReduxState) => state.tabs?.saveForms || false,
    );

    const [tabIndex, setTabIndex] = useState(
        getTabNumber([0, 1, 2, 3, 4, 5, 6, 7]),
    );

    const formData = useRef<ExtensionDetailsPayload>();
    const permissions = useRawPermissions();

    const formsSubmitFuncRef = useRef<
        { [key in string]: (() => void) | undefined }
    >({
        details: undefined,
        callSettings: undefined,
        callForwarding: undefined,
        plan: undefined,
        device: undefined,
        callScreening: undefined,
    });

    const formsErrorsValidationFuncRef = useRef<
        { [key in string]: (() => boolean) | undefined }
    >({
        details: undefined,
        callSettings: undefined,
        callForwarding: undefined,
        plan: undefined,
        device: undefined,
        callScreening: undefined,
    });

    const formsIsDirtyFuncRef = useRef<{ [key in string]: boolean }>({
        details: false,
        callSettings: false,
        callForwarding: false,
        plan: false,
        device: false,
        callScreening: false,
    });

    const setSubmitFunc = (
        funcName: keyof typeof formsSubmitFuncRef.current,
        func: () => void,
    ) => {
        formsSubmitFuncRef.current[funcName] = func;
    };

    const setFormIsValidFunc = (
        funcName: keyof typeof formsErrorsValidationFuncRef.current,
        func: () => boolean,
    ) => {
        formsErrorsValidationFuncRef.current[funcName] = func;
    };

    const [allTabsLoaded, setAllTabsLoaded] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [removeModal, setRemoveModal] = useState<{
        isOpen: boolean;
        name?: string;
        id?: string;
        i_customer?: number;
        i_c_ext?: number;
    }>();

    const extensionDetails = useSelector(
        (state: ReduxState) => state.extensions,
    );

    const {
        isFormSending,
        isFormDataLoading,
        isExtensionDeleted,
    } = useSelector((state: ReduxState) => state.extensions);

    useEffect(() => {
        if (!isFormDataLoading) {
            dispatch(
                actions.getExtensionBasicDetailsData.request({
                    id,
                }),
            );
        }
    }, [id]);

    useEffect(() => {
        if (i_account && i_c_ext && i_customer) {
            dispatch(
                actions.getExtensionTabData.request({
                    i_account,
                    i_c_ext,
                    i_customer,
                }),
            );
            dispatch(
                actions.getCallSettingsTabData.request({
                    id: account_id || '',
                    domain: um_domain || '',
                    i_account,
                }),
            );
            dispatch(actions.getPlanTabData.request({i_account}));
            dispatch(actions.getDeviceTabData.request({i_account}));
            dispatch(actions.getCallForwardingTabData.request({i_account}));
        }

        if (i_account && i_customer) {
            dispatch(
                actions.getCallScreeningTabData.request({
                    i_account,
                    i_customer,
                }),
            );
        }
    }, [i_account, i_c_ext, i_customer, country, account_id, um_domain]);

    useEffect(() => {
        let loadingInProgress = false;

        if (isFormDataLoading) {
            loadingInProgress = true;
        } else if (extensionDetails) {
            Object.keys(extensionDetails).forEach((atr: string) => {
                if (
                    // @ts-ignore
                    extensionDetails[atr] &&
                    // @ts-ignore
                    extensionDetails[atr]?.isLoading === true
                ) {
                    loadingInProgress = true;
                }
            });

            if (!loadingInProgress && !isFormDataLoading) {
                setAllTabsLoaded(true);
            }
        }
    }, [extensionDetails, isFormDataLoading]);

    useEffect(() => {
        (allTabsLoaded && isDirty) || actionButtonVisible
            ? setSaveEnabled(true)
            : setSaveEnabled(false);
    }, [allTabsLoaded, isDirty]);

    useEffect(() => {
        if (isExtensionDeleted) {
            setTimeout(() => {
                history.push(Routes.Extensions);
            }, 1000);
        }
    }, [isExtensionDeleted]);

    const clearDetails = () => {
        history.push(Routes.Extensions);
    };

    const handleDataFormUpdate = (form: Partial<EditExtensionForm>) => {
        formData.current = {
            init: {...formData.current?.init},
            updated: {...formData.current?.updated, ...form},
        };
    };

    const handleDataInitFormUpdate = (form: Partial<EditExtensionForm>) => {
        formData.current = {
            init: {...formData.current?.init, ...form},
            updated: {...formData.current?.updated},
        };
    };

    const showRemoveExtensionModal = () => {
        if (i_c_ext && extension_id && i_customer) {
            setRemoveModal({
                isOpen: true,
                id: extension_id,
                name: extension_id,
                i_customer: i_customer,
                i_c_ext: i_c_ext,
            });
        }
    };

    const handleDeleteExtension = () => {
        if (i_c_ext && extension_id && i_customer) {
            dispatch(
                actions.deleteCustomerExtensionDetailsView.request({
                    i_customer: i_customer,
                    id: extension_id,
                    i_c_ext: i_c_ext,
                }),
            );
            setRemoveModal({...removeModal, isOpen: false});
            setIsDirty(false);
        }
    };

    const submitAllForms = () => {
        Object.keys(formsSubmitFuncRef.current).forEach((key) => {
            formsSubmitFuncRef.current?.[key]?.();
        });
    };

    const markTabAsHaveError = (tabName: string, tabIndex: number, setTabError: boolean) => {
        dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
    }

    const areAllFormsValid = () => {
        let formsAreValid = true;
        Object.keys(formsErrorsValidationFuncRef.current).forEach((key) => {
            let setTabError = false;
            if (
                formsErrorsValidationFuncRef.current[key] !== undefined &&
                !formsErrorsValidationFuncRef.current[key]?.()
            ) {
                formsAreValid = false;
                setTabError = true;
            } else {
                setTabError = false
            }
            // @ts-ignore
            markTabAsHaveError(tabsNameResolver[key], tabNames.indexOf(tabsNameResolver[key]), setTabError);

        });

        return formsAreValid;
    };

    const onSubmitExtensionDetails = (blockRedirection?: boolean) => {
        submitAllForms();

        let formsAreValid = true;
        setTimeout(() => {
            formsAreValid = areAllFormsValid();
        }, 0);

        setTimeout(() => {
            if (formsAreValid) {
                setTimeout(() => {
                    if (formData.current) {
                        const {init, updated} = formData.current;
                        if (i_c_ext && i_account && account_id) {
                            dispatch(
                                actions.editExtension.request({
                                    accountId: i_account,
                                    extensionId: i_c_ext,
                                    // @ts-ignore
                                    initialValues: init,
                                    // @ts-ignore
                                    changedValues: updated,
                                    redirectTab: tabIndex,
                                    id: account_id,
                                    domain: um_domain || '',
                                    blockRedirection: blockRedirection,
                                }),
                            );
                        }
                    }
                    dispatch(actions.saveForms({visible: false}))
                }, 0);
            } else {
                dispatch(actions.saveForms({visible: true}))
            }
        }, 1000);
    };

    const checkAllDirtyState = () => {
        let isAnyFormDirty = false;

        Object.keys(formsIsDirtyFuncRef.current).forEach((key) => {
            if (formsIsDirtyFuncRef.current[key]) {
                isAnyFormDirty = true;
            }
        });

        isAnyFormDirty ? setIsDirty(true) : setIsDirty(false);
    };

    const setIsDirtyFunc = (
        funcName: keyof typeof formsSubmitFuncRef.current,
        isDirty: boolean,
    ) => {
        formsIsDirtyFuncRef.current[funcName] = isDirty;
        checkAllDirtyState();
    };

    const tabProps = {
        handleSubmitForm: handleDataFormUpdate,
        handleInitDataChange: handleDataInitFormUpdate,
        handleDirtyChange: setIsDirtyFunc,
        handleSetSubmitFunc: setSubmitFunc,
        handleSetIsValidFunc: setFormIsValidFunc,
    } as TabInterface;


    const tabsNameResolver = {
        details: t('screens:extensions.extension'),
        callSettings: t('screens:extensions.callSettings'),
        callForwarding: t('screens:extensions.callForwarding'),
        plan: t('screens:extensions.plan'),
        device: t('screens:devices.device'),
        callScreening: t('screens:extensions.callScreening'),
    }

    const {tabNames, tabs,perm} = generateTabs(
        [
            {
                title: t('screens:extensions.extension'),
                permission:
                Permission.CloudPBX.Extensions.ExtensionDetails.Extension
                    .value,
                tab: (
                    <ExtensionTab
                        {...tabProps}
                        key={'tab1'}
                        prefixEnabled={convertBool(Config.PREFIX_DID_ENABLED) || false}
                        isActive={tabIndex == 0}
                        tabName={t('screens:extensions.extension')}
                        callback={() => {
                            markTabAsHaveError(t('screens:extensions.extension'), indexOf(tabNames, t('screens:extensions.extension')), true);
                            dispatch(actions.saveForms({visible: true}));
                        }}
                    />
                ),
            },
            {
                title: t('screens:extensions.callSettings'),
                permission:
                Permission.CloudPBX.Extensions.ExtensionDetails.CallSettings
                    .value,
                tab: <CallSettingsTab
                    {...tabProps}
                    key={'tab2'}
                    isActive={tabIndex == 1}
                    tabName={t('screens:extensions.callSettings')}

                />,
            },
            {
                title: t('screens:extensions.callScreening'),
                permission:
                Permission.CloudPBX.Extensions.ExtensionDetails
                    .CallScreening.value,
                tab: (
                    <CallScreeningTab
                        {...tabProps}
                        extensionNumber={extension_id}
                        key={'tab3'}
                        isActive={tabIndex == 2}
                        tabName={t('screens:extensions.callScreening')}
                    />
                ),
            },
            {
                title: t('screens:extensions.callForwarding'),
                permission:
                Permission.CloudPBX.Extensions.ExtensionDetails
                    .CallForwarding.value,
                tab: <CallForwardingTab
                    {...tabProps} key={'tab4'}
                    isActive={tabIndex == 3}
                    tabName={t('screens:extensions.callForwarding')}
                />,
            },
            {
                title: t('screens:extensions.plan'),
                permission:
                Permission.CloudPBX.Extensions.ExtensionDetails.Plan.value,
                tab: (
                    <PlanTab
                        {...tabProps}
                        productType={MainProductType.Extensions}
                        key={'tab5'}
                        isActive={tabIndex == 4}
                        tabName={t('screens:extensions.plan')}
                    />
                ),
            },
            {
                title: t('screens:devices.device'),
                permission:
                Permission.CloudPBX.Extensions.ExtensionDetails.Device
                    .value,
                tab: (
                    <DeviceTab
                        {...tabProps}
                        key={'tab6'}
                        assignPermission={
                            Permission.CloudPBX.Extensions.ExtensionDetails
                                .Device.AssignDevice.value
                        }
                        releasePermission={
                            Permission.CloudPBX.Extensions.ExtensionDetails
                                .Device.ReleaseDevice.value
                        }
                        changePermission={
                            Permission.CloudPBX.Extensions.ExtensionDetails
                                .Device.ChangeDevice.value
                        }
                        isActive={tabIndex == 5}
                        tabName={t('screens:devices.device')}

                    />
                ),
            },
            {
                title: t('screens:calls.callHistory'),
                permission:
                Permission.CloudPBX.Extensions.ExtensionDetails.CallHistory
                    .value,
                tab: (
                    <CallHistoryForm
                        accountId={i_account}
                        key={'tab7'}
                        deletePermission={
                            Permission.CloudPBX.Extensions.ExtensionDetails
                                .CallHistory.CallDetailRecord
                                .DeleteCallRecording.value
                        }
                        downloadPermission={
                            Permission.CloudPBX.Extensions.ExtensionDetails
                                .CallHistory.CallDetailRecord
                                .DownloadCallRecording.value
                        }
                        recordPermission={
                            Permission.CloudPBX.Extensions.ExtensionDetails
                                .CallHistory.CallDetailRecord.value
                        }
                    />
                ),
            }
        ],
        permissions
    );

    if (extensionDetails?.extensionTabReducerApiError?.faultcode === EXTENSION_NOT_FOUND_BY_ID_API_ERROR) {
        return (
            <NotFound/>
        );
    }

    if (isFormDataLoading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="extension-list-loader"/>
            </div>
        );
    }

    const onTabChange = (_: React.ChangeEvent<{}>, index: any) => {
        areAllFormsValid();
        dispatch(actions.saveForms({visible: false}))
        setTabIndex(index);
    }

    return (
        <>
            <DetailsWrapper
                showActionButtons
                top={
                    <PermissionProvider
                        permission={
                            Permission.CloudPBX.Extensions.ExtensionDetails
                                .value
                        }
                    >
                        <ExtensionDetailsHeader/>
                    </PermissionProvider>
                }
                tabs={tabs}
                saveEnable={saveEnabled}
                preventIsOpen={isDirty}
                preventSavePress={() => onSubmitExtensionDetails(true)}
                onTabChange={onTabChange}
                tabsNames={tabNames}
                defaultTabIndex={tabIndex}
                onSavePress={() => onSubmitExtensionDetails(false)}
                onDeletePress={showRemoveExtensionModal}
                deleteText={t('screens:extensions.deleteExtension')}
                onBackPress={clearDetails}
                deleteEnable
                deletePermission={
                    Permission.CloudPBX.Extensions.ExtensionDetails
                        .DeleteExtension.value
                }
                perm={perm}
            >
                {isFormSending && (
                    <Loader
                        dataQa="extension-form-data-sending-loader"
                        absolutePosition
                        dataTestId={'send-data-loader'}
                    />
                )}
                <AlertDialog
                    isOpen={!!removeModal?.isOpen}
                    dataQa={'delete-extension-modal'}
                    contentClass="medium-width-modal-delete"
                    hideHeader={true}
                    description={t(
                        'screens:extensions.deleteExtensionDescription',
                        {name: removeModal?.name},
                    )}
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={() =>
                                setRemoveModal({
                                    ...removeModal,
                                    isOpen: false,
                                })
                            }
                        />,
                        <DialogButton
                            key="delete"
                            label={t('common:delete')}
                            className={classes.primaryModalButton}
                            onClick={handleDeleteExtension}
                        />,
                    ]}
                />
                <ErrorNotification tabName={tabNames[tabIndex]} isSubmitting={saveClicked} />
            </DetailsWrapper>
        </>
    );
};

export default ExtensionDetails;
