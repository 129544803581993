import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../Button/Button';
import { Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { actions } from '../../store';
import Loader from '../Loader/Loader';
import Sip from './Sip';
import AssignDeviceDialog from '../Devices/AssignDeviceDialog';
import { toast } from 'react-hot-toast';
import AlertDialog from '../AlertDialog/AlertDialog';
import { DialogButton } from '../AlertDialog/DialogContainer';
import { DeviceProps, generateColumns, useStyles } from './Device.utils';
import classNames from 'classnames';
import PermissionProvider from '../PermissionProvider/PermissionProvider';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import DataGrid from '../DataGrid/DataGrid';
import { AssignedPortInfo } from '../../store/types/Devices';

const Device: React.FC<DeviceProps> = ({
    iAccount,
    register,
    i_c_ext,
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
    extensionNumber,
    assignPermission,
    releasePermission,
    changePermission,
    sharedLine
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const assignedDevices = useSelector((state: ReduxState) => state.extensions.device?.items || []);
    const isLoading = useSelector((state: ReduxState) => state.extensions.device?.isLoading || false);
    const typesList = useSelector((state: ReduxState) => state.devices.ua_type_list || []);
    const apiErrors = useSelector((state:ReduxState) => state.extensions.errors);

    const [isOpenDeviceDialog, setIsOpenDialog] = useState(false);
    const [isOpenReleaseDialog, setIsOpenReleaseDialog] = useState<{
        isOpen: boolean,
        object?: AssignedPortInfo
    }>({
        isOpen: false,
        object: undefined
    });
    const [isOpenChangeDialog, setIsOpenChangeDialog] = useState<{
        isOpen: boolean,
        object?: AssignedPortInfo
    }>({
        isOpen: false,
        object: undefined
    });

    const refreshDeviceList = useCallback(() => {
        dispatch(
            actions.fetchDeviceByIAccount.request({
                i_account: iAccount,
                get_total: 0,
                limit: 1000,
                offset: 0,
                withDeviceDetails: true
            }),
        );
    }, [iAccount]);

    useEffect(() => {
        if (iAccount) {
            refreshDeviceList();
        }
    }, [iAccount]);

    const handleIsOpenDialogChange = () => {
        setIsOpenDialog((prevState) => !prevState);
    };

    const showAssignButton = useMemo(() => {
        return !isLoading && (
            sharedLine
            || (!sharedLine && assignedDevices.length < 1)
        )
    }, [isLoading, assignedDevices]);

    const handleSubmitReleaseClick = useCallback(() => {
        const id = isOpenReleaseDialog.object?.i_ua_link;
        if (id) {
            dispatch(
                actions.releaseDevice.request({
                    i_ua_link: id,
                    onSuccess: () => {
                        setIsOpenReleaseDialog({
                            isOpen: false,
                            object: undefined
                        });
                        toast(t<string>('screens:devices.deviceReleased'));
                        refreshDeviceList();
                    },
                }),
            );
        }
    }, [isOpenReleaseDialog]);

    const actionReleaseDialogButtons = [
        <DialogButton
            key="cancel"
            label={t('common:cancel')}
            onClick={() => {
                setIsOpenReleaseDialog({
                    isOpen: false,
                    object: undefined
                });
            }}
        />,
        <DialogButton
            key="save"
            label={t('common:release')}
            onClick={handleSubmitReleaseClick}
        />,
    ];

    const columns = generateColumns(
        t,
        classes,
        typesList,
        releasePermission,
        changePermission,
        (device: AssignedPortInfo) => {
            setIsOpenChangeDialog({
                isOpen: true,
                object: device
            });
        },
        (object: AssignedPortInfo) => {
            setIsOpenReleaseDialog({
                isOpen: true,
                object: object
            });
        },
        !sharedLine && assignedDevices.length > 1
    );

    return (
        <>
            {isLoading ? (
                <Loader dataQa={'fetch-device-loader'} absolutePosition />
            ) : (
                <div className={classNames(
                    showAssignButton && classes.assignDeviceButtonContainer)}
                >
                    <Grid className={classes.itemsContainer} style={{paddingBottom: 0}}>
                        <div className={classes.titleContainer}>
                            <h5>{t('screens:extensions.assignedDevice')}</h5>
                            <div className={classes.iconContainer}>
                                <IconWithTooltip
                                    dataQa="assigned-devices-tooltip"
                                    tooltipText={t(
                                        'tooltips:extensions.assignedDevice',
                                    )}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid className={classes.itemsContainer} style={{paddingTop: 0}}>
                        <DataGrid<AssignedPortInfo>
                            classes={{
                                tableContainer: classes.tableContainer,
                            }}
                            columns={columns}
                            data={assignedDevices}
                            rowCount={assignedDevices?.length || 0}
                            pageSizeOptions={[assignedDevices?.length || 0]}
                            initialPageSize={Number.MAX_VALUE}
                            centeredRows
                            hideFooter
                            getItemId={(r, i) => (r.i_ua || 0) + i}
                            dataQa={'assigned-ports-list'}
                            internalHeader
                        />
                    </Grid>
                    {showAssignButton && (
                        <PermissionProvider permission={assignPermission || []}>
                            <Button
                                className={classes.assignButton}
                                primary
                                accent
                                icon={<Add />}
                                onClick={handleIsOpenDialogChange}
                            >
                                {t('screens:devices.assign')}
                            </Button>
                        </PermissionProvider>
                    )}
                    <AlertDialog
                        isOpen={isOpenReleaseDialog.isOpen}
                        hideHeader={true}
                        description={t('screens:extensions.releaseExtension', {
                            port: isOpenReleaseDialog?.object?.port,
                            name: isOpenReleaseDialog?.object?.device_name,
                            mac: isOpenReleaseDialog?.object?.mac
                        })}
                        dialogActionsButtons={actionReleaseDialogButtons}
                        className={classes.releaseContainer}
                    />
                    <Grid
                        className={classNames(
                            classes.itemsContainer,
                            classes.marginTop,
                        )}
                    >
                        <div className={classes.titleContainer}>
                            <h5>{t('screens:extensions.sipInfo')}</h5>
                        </div>
                        <Sip
                            register={register}
                            handleDirtyChange={handleDirtyChange}
                            handleSetSubmitFunc={handleSetSubmitFunc}
                            handleSetIsValidFunc={handleSetIsValidFunc}
                            apiErrors={apiErrors}
                        />
                    </Grid>
                    <PermissionProvider permission={assignPermission || []}>
                        <AssignDeviceDialog
                            id={extensionNumber}
                            i_c_ext={i_c_ext}
                            i_account={iAccount}
                            isOpen={isOpenDeviceDialog}
                            selectedDevice={undefined}
                            onClose={handleIsOpenDialogChange}
                            callBackOnSave={() => {
                                handleIsOpenDialogChange();
                                const msg = t('screens:devices.deviceAssigned');
                                toast(msg);
                                refreshDeviceList();
                            }}
                        />
                    </PermissionProvider>
                    <PermissionProvider permission={changePermission || []}>
                        <AssignDeviceDialog
                            id={extensionNumber}
                            i_c_ext={i_c_ext}
                            i_account={iAccount}
                            isOpen={isOpenChangeDialog.isOpen}
                            onClose={() => {
                                setIsOpenChangeDialog({
                                    isOpen: false,
                                    object: undefined
                                });
                            }}
                            selectedDevice={isOpenChangeDialog.object}
                            callBackOnSave={() => {
                                setIsOpenChangeDialog({
                                    isOpen: false,
                                    object: undefined
                                });
                                const msg = t('screens:devices.deviceChanged');
                                toast(msg);
                                refreshDeviceList();
                            }}
                        />
                    </PermissionProvider>
                </div>
            )}
        </>
    );
};

export default Device;
