import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import DialogContainer, {DialogButton} from '../AlertDialog/DialogContainer';
import Loader from '../Loader/Loader';
import {TFunction, useTranslation} from 'react-i18next';
import SelectField from '../SelectField/SelectField';
import {ReduxState} from '../../store/types';
import {useDispatch, useSelector} from 'react-redux';
import {FormikErrors, useFormik} from 'formik';
import * as actions from '../../store/actions';
import {
    DIDAreaInfo,
    DIDCostsInfoEnum,
    DIDNumberListInfo,
    DIDPricingTypeEnum,
    GetDIDNumbersListRequest,
    GetDIDNumbersListResponse,
} from '../../store/types/DIDProvider';
import {roundUp} from '../../utils/roundUp';
import classNames from 'classnames';
import {
    useStyles,
    didProviderValidationSchema,
    InventoryFormProps,
    DIDInventoryDialogProps,
} from './DIDInventoryDialog.utils';
import AsyncSelectField from '../AsyncSelectField/AsyncSelectField';
import {AxiosResponse} from 'axios';
import {
    AccountListRequest,
    AccountListResponse,
} from '../../store/types/Account';
import JSONFormData from '../../utils/JSONFormData';
import {Account, DIDProvider} from '../../services/endpoints';
import {ExtensionType} from '../../store/types/Extension';
import {mapToName} from '../../views/DidNumbers/helpers';
import {api} from '../../store/services/axios';
import {YesNo} from '../../store/types/CallScreening';
import PermissionProvider from "../PermissionProvider/PermissionProvider";
import {Permission} from "../../store/types/Permission";

const defaultDecimalDigits = 2;

const DIDInventoryDialog: React.FC<DIDInventoryDialogProps> = ({
                                                                   isOpen,
                                                                   clickOnClose,
                                                               }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {session_id, csrf_token} = useSelector((state: ReduxState) => state.auth);
    const [inputValue, onInputChangeRaw] = useState<string>('');
    const [didInputValue, onDidInputChangeRaw] = useState<string>('');
    const [iAccount, setIAccount] = useState(0);

    const {isLoading, country, provider, assigned} = useSelector(
        (state: ReduxState) => state.didProvider,
    );

    const {area, isAreaLoading} = useSelector(
        (state: ReduxState) => state.didProvider,
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {numbers, areNumbersLoading} = useSelector(
        (state: ReduxState) => state.didProvider,
    );

    const i_customer = useSelector(
        (state: ReduxState) => state.generic.sessionData?.i_customer,
    );

    const globalCurrency = useSelector(
        (state: ReduxState) => state.generic.globalCurrency,
    );

    const addLabel = (item: ExtensionType): ExtensionType => {
        return {...item, label: mapToName(item, t), value: item.i_account};
    };

    const initialValues: InventoryFormProps = useMemo(
        () => ({
            number: null,
            country: null,
            area: null,
            is_numbers_list_available: provider?.is_numbers_list_available || 0,
            assignedTo: null,
        }),
        [],
    );

    const {
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
        errors,
        setFieldError,
        dirty,
    } = useFormik<InventoryFormProps>({
        initialValues,
        onSubmit: (form) => {
            const {number, country, area} = form;
            dispatch(
                actions.postProvisionNumber.request({
                    req_params: {
                        area_name: area?.name || null,
                        country_iso: country?.req_params?.country_iso || null,
                        i_did_provider: area?.req_params.i_did_provider || 0,
                        country_prefix: country?.prefix || '',
                        country_name: country?.name || '',
                        city_id: area?.id || 0,
                        city_prefix: area?.prefix || '',
                        number: number?.number || '',
                        country_id: country?.id,
                        ...area?.req_params,
                    },
                    assignTo: {
                        i_master_account: iAccount,
                    },
                }),
            );
        },
        validationSchema: didProviderValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
    });

    useEffect(() => {
        if (assigned) {
            setTimeout(() => {
                location.reload();
            }, 1500);
        }
    }, [assigned]);

    useEffect(() => {
        if (isOpen && !values.country) {
            dispatch(actions.getDIDInventoryDetails.request());
        }
    }, [isOpen]);

    useEffect(() => {
        setFieldValue(
            'is_numbers_list_available',
            provider?.is_numbers_list_available || 0,
        );
    }, [country]);

    useEffect(() => {
        if (values.country) {
            dispatch(actions.getDIDAreas.request({country: values.country}));
        }
    }, [values.country]);

    const renderSelectDidNumber = () => {
        if (
            (!provider?.is_numbers_list_available || (0 && area)) &&
            values.area
        ) {
            return (
                <span
                    className={classes.didNumberToAdd}
                    data-qa="did-no-numbers-to-add"
                >
                    {t('screens:didNumbers.didNoNumbersToAdd')}
                </span>
            );
        }
        if (!!provider?.is_numbers_list_available) {
            return (
                <>
                    <AsyncSelectField
                        id="number"
                        title={t('screens:didNumbers.editDIDNumberText')}
                        loadOptions={loadDidOptions}
                        onInputChange={onDidInputChangeRaw}
                        value={values.number}
                        onChange={(value) => {
                            setFieldValue('number', value);
                        }}
                        cacheUniqs={[
                            didInputValue.length,
                            values.area,
                            values.country,
                        ]}
                        maxMenuHeight={240}
                        dataQa={'did-number-select-field'}
                        disabled={!values.area || areNumbersLoading}
                        helperText={
                            errors.number && errors.number.length > 0
                                ? errors.number
                                : ''
                        }
                        setFieldError={setFieldError}
                        required
                        className={classes.numberSelect}
                        defaultOptions={!!values.area}
                    />
                </>
            );
        }
    };

    const onInputChange = (newInputValue: string) => {
        onInputChangeRaw(newInputValue);
    };

    const loadOptions = async (search: string, prevOptions: unknown[]) => {
        const limit = 30;

        const params: Partial<AccountListRequest> = {
            id: search ? `%${search}%` : undefined,
            free_of_extensions: 0,
            get_not_closed_accounts: 1,
            get_only_real_accounts: 1,
            get_total: 1,
            limit: 30,
            offset: prevOptions.length,
        };
        const body = new JSONFormData(session_id || '', csrf_token || '');
        body.setParams(params);

        const res: AxiosResponse<AccountListResponse> = await api.post(
            Account.GetAccountList,
            body,
        );
        const items = res.data.account_list.map(addLabel);

        return {
            options: items,
            hasMore: res.data.total > prevOptions.length + limit,
        };
    };

    const loadDidOptions = async (search: string, prevOptions: unknown[]) => {
        const limit = 30;

        const params: Partial<GetDIDNumbersListRequest> = {
            did_provider_info: {i_customer},
            idle_only: YesNo.No,
            req_params: {
                ...values.area?.req_params,
                number: search ? `%${search}%` : undefined,
            },
            limit,
            offset: prevOptions.length,
        };
        const body = new JSONFormData(session_id || '', csrf_token || '');
        body.setParams(params);

        const res: AxiosResponse<GetDIDNumbersListResponse> = await api.post(
            DIDProvider.GetNumbersList,
            body,
        );
        const items = res.data.numbers_list?.map((v) => ({
            ...v,
            value: v.number,
            label: v.number,
        }));

        if (items?.length === 1 && prevOptions.length === 0) {
            setFieldValue('number', items[0]);
        }

        return {
            options: items,
            hasMore: res.data.numbers_list?.length === limit,
        };
    };

    const disabledAssign =
        (!!provider?.is_numbers_list_available && !values.number) ||
        (!provider?.is_numbers_list_available && !values.area);
    return (
        <PermissionProvider permission={Permission.Inventory.DIDNumbers.DIDDetails.value}>
            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'did-numbers-edit-modal'}
                header={t('screens:didNumbers.didProviderheader')}
                headerClass={classes.header}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            resetForm();
                            setIAccount(0);
                            clickOnClose?.();
                        }}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        disabled={
                            !dirty ||
                            isLoading ||
                            !values.area ||
                            !values.country ||
                            (!!provider?.is_numbers_list_available &&
                                !values.number)
                        }
                        onClick={handleSubmit}
                        dataQa={'assign-did-save-button'}
                        primary
                    />,
                ]}
            >
                {isOpen && isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Loader dataQa={'wait-for-data'}/>
                    </div>
                ) : (
                    <form
                        className={classes.form}
                        autoComplete="off"
                        data-testid="did-inventory-form"
                    >
                        <Grid className={classes.itemsContainer}>
                            <Box className={classes.boxRow}>
                                <SelectField
                                    id="country"
                                    label={t(
                                        'screens:didNumbers.editDIDNumberCountryText',
                                    )}
                                    getOptionLabel={(option) => {
                                        return option.name;
                                    }}
                                    onChange={(_, option) => {
                                        setFieldValue('country', option);
                                        setFieldValue('area', null);
                                        setFieldValue('number', null);
                                        onDidInputChangeRaw('');
                                    }}
                                    items={country ?? []}
                                    value={values.country}
                                    helperText={
                                        errors.country && errors.country.length > 0
                                            ? errors.country
                                            : ''
                                    }
                                    classes={{helperText: classes.error}}
                                    setFieldError={setFieldError}
                                    required
                                    dataQa={'country-select-filed'}
                                />
                            </Box>
                            <Box className={classes.boxRow}>
                                <SelectField
                                    id="area"
                                    label={t(
                                        'screens:didNumbers.editDIDNumberAreaText',
                                    )}
                                    getOptionLabel={(option) => option.name}
                                    items={area ?? []}
                                    onChange={(_, option) => {
                                        setFieldValue('area', option);
                                        setFieldValue('number', null);
                                    }}
                                    classes={{helperText: classes.error}}
                                    value={values.area}
                                    helperText={makeAreaHelperText(
                                        provider?.is_numbers_list_available || 0,
                                        values.area,
                                        errors,
                                    )}
                                    required
                                    disabled={!values.country || isAreaLoading}
                                    setFieldError={setFieldError}
                                    dataQa={'area-select-filed'}
                                />
                            </Box>
                            <Box className={classes.boxRow}>
                                {renderSelectDidNumber()}
                            </Box>
                        </Grid>

                        <Grid
                            className={classNames(
                                classes.itemsContainer,
                                classes.paddingTop,
                            )}
                        >
                            <Box
                                className={classNames(
                                    classes.boxRow,
                                    classes.border,
                                    classes.paddingBottom,
                                )}
                            >
                            <span
                                className={classes.pricing}
                                data-qa={'activation-fee'}
                            >
                                {t('screens:didNumbers.didActivationFee')}:{' '}
                                <b>
                                    {fetchCosts(
                                        DIDPricingTypeEnum.Customer,
                                        DIDCostsInfoEnum.Activation,
                                        globalCurrency?.decimal_digits ??
                                        defaultDecimalDigits,
                                        values.number,
                                        values.area,
                                        t,
                                    )}
                                </b>
                            </span>
                            </Box>
                            <Box
                                className={classNames(
                                    classes.boxRow,
                                    classes.padding8Top,
                                )}
                            >
                            <span
                                className={classes.pricing}
                                data-qa={'monthly-fee'}
                            >
                                {t('screens:didNumbers.didMonthlyFee')}:{' '}
                                <b>
                                    {fetchCosts(
                                        DIDPricingTypeEnum.Customer,
                                        DIDCostsInfoEnum.Periodic,
                                        globalCurrency?.decimal_digits ??
                                        defaultDecimalDigits,
                                        values.number,
                                        values.area,
                                        t,
                                    )}
                                </b>
                            </span>
                            </Box>
                            <Box className={classes.boxRow}>
                                <div className={classes.assignedTo}>
                                    <AsyncSelectField
                                        title={t('screens:didNumbers.assignTo')}
                                        loadOptions={loadOptions}
                                        onInputChange={onInputChange}
                                        value={values.assignedTo}
                                        onChange={(value) => {
                                            setFieldValue('assignedTo', value);
                                            if (value?.i_account) {
                                                setIAccount(value.i_account);
                                            } else {
                                                setIAccount(0);
                                            }
                                        }}
                                        cacheUniqs={[inputValue]}
                                        maxMenuHeight={140}
                                        dataQa={'select-extension-to-assing'}
                                        disabled={disabledAssign}
                                        defaultValue={
                                            !disabledAssign
                                                ? {
                                                    label: t(
                                                        'screens:didNumbers.noneUnassigned',
                                                    ),
                                                    value: null,
                                                }
                                                : undefined
                                        }
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </form>
                )}
            </DialogContainer>
        </PermissionProvider>
    );
};

function makeAreaHelperText(
    numberListAvailable: number,
    area?: DIDAreaInfo | null,
    errors?: FormikErrors<InventoryFormProps>,
) {
    let output = '';

    if (errors && errors?.area && errors.area.length > 0) {
        output += ' ' + errors.area;
    }

    return output;
}

export function fetchCosts(
    type: DIDPricingTypeEnum,
    payType: DIDCostsInfoEnum,
    decimalDigits: number,
    number?: DIDNumberListInfo | null,
    area?: DIDAreaInfo | null,
    t?: TFunction<string>,
): string {
    let price: number | undefined = undefined;
    let iso: string | undefined = '';
    if (number !== null && number !== undefined) {
        price =
            number.pricing && number.pricing[type]
                ? number.pricing[type]?.[payType]
                : 0;
        iso =
            number.pricing && number.pricing[type]
                ? number.pricing[type]?.iso_4217
                : '';
    } else if (area) {
        price =
            area.pricing && area.pricing[type]
                ? area.pricing[type]?.[payType]
                : 0;
        iso =
            area.pricing && area.pricing[type]
                ? area.pricing[type]?.iso_4217
                : '';
    }
    if (price === 0 && t && number) {
        return t('common:free');
    }
    if (price !== undefined && iso) {
        return roundUp(price, decimalDigits).toFixed(decimalDigits) + ' ' + iso;
    }

    return '';
}

export default DIDInventoryDialog;
