import React from "react";
import OverflowTooltip from "../OverflowTooltip/OverflowTooltip";
import {makeStyles} from '@material-ui/core';
import {Colors} from "../../styles/Colors";
import {usePermissionContext, usePermissions} from "../../hooks/usePermissions";
import {PermissionType} from "../../store/types/Permission";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
    url: {
        color: Colors.Secondary1,
        cursor: 'pointer',
        fontSize: 16,
        fontWeight: 400,
        display:'flex',
        alignItems:'center',

        '& > span':{
            marginLeft:8
        }
    },

    disabled: {
        color: Colors.Text,
        fontSize: 16,
        fontWeight: 400,
    }
}));

interface PermissionLinkCellInterface {
    text: string,
    onClick: () => void,
    permissions?: string[],
    className?: string
    customizePermission?: (permissionValue: PermissionType) => PermissionType;
    icon?:any
}

const PermissionLinkCell: React.VFC<PermissionLinkCellInterface> = ({
    text, 
    onClick, 
    permissions,
    className,
    customizePermission,
    icon
}) => {
    const classes = useStyles();

    let permission;
    if(!!permissions){
        permission = usePermissions(...permissions);
    } else {
        permission = usePermissionContext();
    }

    if(customizePermission) {
        permission = customizePermission(permission);
    }

    const linkActive = permission !== PermissionType.Hidden;

    return <OverflowTooltip
        tooltip={text}
        text={
            <span className={classNames(className, 
                linkActive ? classes.url : classes.disabled)} 
                onClick={linkActive ? onClick : () => null}
            >
                {text}
                {icon && <span>{icon}</span>}
            </span>
        }
        copy={false}
    />
}

export default PermissionLinkCell;