import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        '& .MuiButton-label': {
            color: Colors.White,
        },
    },

    customTab: {
        '& #wrapped-tabpanel-0': {
            '& .MuiBox-root': {
                height: 'calc(100vh - 290px)',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                padding: 0
            },
            '& #read-only-warning-component': {
                marginTop: 24,
                marginLeft: 96
            }
        },
    },
    
    customTabOnSubTabMode: {
        minHeight: 'calc(100vh - 408px)',
        height: 'fit-content',
        '& #wrapped-tabpanel-0': {
            '& .MuiBox-root': {
                height: 'fit-content !important',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
            },
        },
    },

    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        minHeight: 'fit-content',
        position: 'relative',
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.SmokeBackground,
        paddingBottom: 70,
        height: 'fit-content',
        maxHeight: '100vh'
    },
    scrollableMinHeight: {
        paddingBottom: 0,
        height: 'fit-content'
    },

    tabs: {
        '& header': {
            paddingLeft: 110,
        },
    },

    subTubs: {
        marginTop: 8
    },

    headerContainer: {
        padding: '46px 96px 36px 90px',
        backgroundColor: Colors.White,
    },

    hidden: {
        display: 'none'
    },

    '@media (max-width: 1276px)': {
        tabs: {
            '& header': {
                paddingLeft: 24,
            },
        },

        headerContainer: {
            paddingLeft: 24,
            paddingRight: 24,
        },
    },

    '@media (min-width:1277px) and (max-width: 1440px)': {
        tabs: {
            '& header': {
                paddingLeft: 90,
            },
        },

        headerContainer: {
            paddingLeft: 90,
            paddingRight: 90,
        },
    },

    '@media (min-width: 1441px)': {
        tabs: {
            '& header': {
                paddingLeft: 96,
            },
        },
        headerContainer: {
            paddingLeft: 96,
            paddingRight: 96,
        },
    },
}));
