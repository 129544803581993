import { createAction, createAsyncAction } from 'typesafe-actions';
import * as payloads from './payloads';
import { RecordingsPlayingState } from '../../reducers/calls/reducer';
import { APIErrorInterface } from '../../types';

export const getExtensionCallHistory = createAsyncAction(
    'GET_EXTENSION_CALL_HISTORY',
    'GET_EXTENSION_CALL_HISTORY_SUCCESS',
    'GET_EXTENSION_CALL_HISTORY_FAILED',
)<
    payloads.GetExtensionCallHistoryRequestPayload,
    payloads.GetCallHistoryResponsePayload,
    undefined
>();


export const getTransferCallData = createAsyncAction(
    'GET_TRANSFER_CALL_DATA',
    'GET_TRANSFER_CALL_DATA_SUCCESS',
    'GET_TRANSFER_CALL_DATA_FAILED',
)<undefined,undefined,undefined>();

export const getExtensionCallHistoryFile = createAsyncAction(
    'GET_EXTENSION_CALL_HISTORY_FILE',
    'GET_EXTENSION_CALL_HISTORY_FILE_SUCCESS',
    'GET_EXTENSION_CALL_HISTORY_FILE_FAILED',
)<payloads.GetExtensionCallHistoryFileRequestPayload, undefined, undefined>();

export const setCustomerCurrencyForCallHistory = createAction(
    'SET_CUSTOMER_CURRENCY_FOR_CALL_HISTORY',
)<payloads.SetCustomerCurrencyForCallHistoryPayload>();

export const getCallHistory = createAsyncAction(
    'GET_CALL_HISTORY',
    'GET_CALL_HISTORY_SUCCESS',
    'GET_CALL_HISTORY_FAILED',
)<
    payloads.GetCallHistoryRequestPayload,
    payloads.GetCallHistoryResponsePayload,
    undefined
>();

export const removeCallRecordings = createAsyncAction(
    'REMOVE_CALL_RECORDING',
    'REMOVE_CALL_RECORDING_SUCCESS',
    'REMOVE_CALL_RECORDING_FAILED',
)<payloads.RemoveCallRecoringsRequestPayload, undefined, undefined>();

export const getActiveCalls = createAsyncAction(
    'GET_ACTIVE_CALLS',
    'GET_ACTIVE_CALLS_SUCCESS',
    'GET_ACTIVE_CALLS_FAILED',
)<{serviceType?:number} | undefined, payloads.GetActiveCallsResponsePayload, undefined>();

export const getCallHistoryBasicData = createAsyncAction(
    'GET_CALL_HISTORY_BASIC_DATA',
    'GET_CALL_HISTORY_BASIC_DATA_SUCCESS',
    'GET_CALL_HISTORY_BASIC_DATA_FAILED',
)<undefined, payloads.GetCallHistoryBasicDataResponsePayload, undefined>();

export const getCallHistoryFileAsBlob = createAsyncAction(
    'GET_CALL_HISTORY_FILE_AS_BLOB',
    'GET_CALL_HISTORY_FILE_AS_BLOB_SUCCESS',
    'GET_CALL_HISTORY_FILE_AS_BLOB_FAILED',
)<payloads.GetCallHistoryFileAsBlobRequestPayload, undefined, undefined>();

export const updateRecordingsPlayingState = createAction(
    'UPDATE_CALL_HISTORY_RECORDING_STATE',
)<RecordingsPlayingState>();

export const getCallHistoryDataForStatistics = createAsyncAction(
    'GET_CALL_HISTORY_DATA_FOR_STATISTICS',
    'GET_CALL_HISTORY_DATA_FOR_STATISTICS_SUCCESS',
    'GET_CALL_HISTORY_DATA_FOR_STATISTICS_FAILED',
)<payloads.GetCallHistoryDataForStatisticsPayload, payloads.CallHistoryDataForStatisticsResponse, APIErrorInterface>();