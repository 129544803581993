import React from "react";
import PermissionLinkCell from "../DataTable/PermissionLinkCell";
import {ReactComponent as LinkIcon} from '../../assets/link.svg';
import {makeStyles} from "@material-ui/styles";
import history from "../../history";
import {Colors} from "../../styles/Colors";

const useStyles = makeStyles(() => ({
    link: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px'
    },

    normal: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px',
        color:Colors.Text3
    }
}))

const RingGroupCell: React.VFC<{ title: string, link: string }> = ({title, link}) => {

    const classes = useStyles();

    const linkComponent = (
        <PermissionLinkCell
            text={title}
            onClick={() => history.push(link)}
            icon={<LinkIcon/>}
            className={classes.link}
        />
    );

    return link ? linkComponent : <div className={classes.normal}>{title}</div>
}

export default RingGroupCell;