
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import i18n from '../../services/i18n';
import { MfaConfig } from "../../store/actions/payloads";
import { Colors } from '../../styles/Colors';

export const AndroidAppUrl = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
export const IOsAppUrl = 'https://apps.apple.com/app/google-authenticator/id388497605';

export type Enable2FADialogProps = {
    isOpen: boolean;
    mfaConfig?: MfaConfig | null;
    showActionButtons?: boolean;
    onClose?: (success: boolean) => void;
};

const requiredError = i18n.t<string>('screens:twoFa.incorrectCode');
export const mfaValidationSchema = Yup.object().shape({
    one_time_password: Yup.string()
        .required(requiredError)
});

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.White,
            height: 547,
            width: 400,
            minWidth: 'unset !important',
            padding: '26px 57px 33px 57px !important',
            margin: 0,
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: 'unset',
            maxHeight: 'unset'
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },

        '& .MuiPaper-root': {
            width: 516,
        },
        
        '& .MuiFormHelperText-root': {
            right: 0,
            fontSize: '0.75rem',
            fontWeight: 400
        },
    },

    smallerDialog: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            paddingBottom: '8px !important',
            minHeight: 320,
            maxHeight: 370,
            height:'auto',
            width: 400,
            minWidth: 'unset !important',
        },
    },

    header1: {
        fontWeight: 400,
        fontSize: 22,
        textAlign: 'center',
        color: Colors.Gray5,
        width: '100%'
    },

    header2: {
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'left',
        color: Colors.Gray5,
        width: '100%',
        letterSpacing: 0.15,
        lineHeight: '20px',
        maxWidth: 400
    },
    
    header3: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'left',
        color: Colors.Gray5,
        letterSpacing: 0.15,
        lineHeight: '16px'
    },

    itemsContainer: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        }
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: 0,
        margin: 0,
        overflow: 'hidden',
        paddingBottom: 24
    },
    
    boxRow: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',

        '& .Mui-error': {
            fontWeight: 400,
        },
    },

    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    
    tfCode: {
        width: 239,
        height: 56,
        
        '& svg > path': {
            fill: Colors.Secondary,
            cursor: 'pointer'
        },
    },
    
    oneTimePassword: {
        width: 401,
        height: 56
    },

    mainContainer: {
        display: 'flex',
        width: '100%'
    },

    qrContainer: {
        width: 127.5,
        height: 127.5,
        padding: 0,
        margin: 0
    },

    rightContainer: {
        marginLeft: 34.5,
        width: 239
    },

    iconContainer: {
        cursor: 'pointer',
        paddingTop: 18
    },

    submitButton: {
        width: '100%',
        height: 56,
        color: 'white'
    },

    buttonContainer: {
        marginTop: 32,
        width: '100%',

        '& > :first-child': {
            width: '100%',
        },
    },
    
    disabledButton: {
        backgroundColor: Colors.Gray8,
        '& .MuiButton-label': {
            color: `${Colors.Gray10} !important`,
        },
    },

    primaryButton: {
        background: Colors.Primary,

        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: `${Colors.White} !important`,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary + ' !important',
        },
    },
}));