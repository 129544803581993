import React, {useEffect} from 'react';
import {useTabStyles} from '../../Extensions/ExtensionDetails/utils';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import Loader from '../../../components/Loader/Loader';
import {Formik} from 'formik';
import {useExtensionAAFromTab} from '../../../hooks/useExtensionAAFormTab';
import ExtensionForm from '../../../components/Forms/AutoAttendants/ExtensionForm';
import {TabInterface} from '../../../components/Tabs/Tabs.utils';
import {
    ExtensionFormType,
    extensionFormValidationSchema,
} from '../../../components/Forms/AutoAttendants/ExtensionForm.utils';
import RestrictedMessageProvider from "./RestrictedMessageProvider";
import TabErrors from "../../../components/TabErrors/TabErrors";

interface AutoAttendantTab extends TabInterface {
    accountId?: number;
}

const AutoAttendantExtensionTab: React.VFC<AutoAttendantTab> = (
    {
        handleSubmitForm,
        handleInitDataChange,
        handleDirtyChange,
        handleSetSubmitFunc,
        accountId,
        handleSetIsValidFunc,
        callback

    }) => {
    const classes = useTabStyles();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.autoAttendants.autoAttendantDetails?.extensionTab
                ?.isLoading || false,
    );

    const {initValues, data} = useExtensionAAFromTab();

    useEffect(() => {
        if (!isDataLoading && initValues) {
            handleInitDataChange?.(initValues);
            handleSubmitForm?.(initValues);
        }
    }, [isDataLoading, initValues]);

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="extension-details-loader"/>
        </div>
    ) : (
        <RestrictedMessageProvider>
            <Formik<ExtensionFormType>
                initialValues={initValues}
                enableReinitialize={true}
                validateOnChange={false}
                onSubmit={handleSubmitForm!}
                validationSchema={extensionFormValidationSchema}
                initialErrors={data.errors}
            >

                {() => {

                    return (
                        <>
                            <ExtensionForm
                                accountId={accountId}
                                handleSetSubmitFunc={handleSetSubmitFunc}
                                handleDirtyChange={handleDirtyChange}
                                handleSetIsValidFunc={handleSetIsValidFunc}
                                callback={callback}
                            />
                        </>
                    )
                }

                }

            </Formik>
        </RestrictedMessageProvider>
    )

};

export default AutoAttendantExtensionTab;
