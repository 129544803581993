import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { actions } from '../../store';
import { useTranslation } from 'react-i18next';
import Header from '../../components/ListViewHeader/Header';
import { useStyles } from './CallHistory.utils';
import usePageTitle from '../../hooks/usePageTitle';
import { RecentCalls } from './RecentCalls/RecentCalls';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import Loader from '../../components/Loader/Loader';
import { ActiveCalls } from './ActiveCalls/ActiveCalls';
import CallsTodayInfo from '../../components/Calls/CallsTodayInfo';
import {generateTabs} from "../../utils/generateTabs";
import {Permission} from "../../store/types/Permission";
import {useRawPermissions} from "../../hooks/usePermissions";
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import HeaderStat from "../../components/Calls/CallStatistics";
import {ActiveCall} from "../../store/types/CallHistory";
import { CallStatistics } from './CallStatistics/CallStatistics';

export const CallHistoryComponent: React.VFC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const permissions = useRawPermissions();

    usePageTitle();

    const isLoading = useSelector<ReduxState, boolean | undefined>(
        (state) => state.calls.areCallsLoading,
    );

    useEffect(() => {
        dispatch(actions.getCallHistoryBasicData.request());
    }, []);

    const { tabNames, tabs, perm } = generateTabs(
        [
            {
                title:  t('screens:calls.currentCalls'),
                permission:
                Permission.Calls.Activity.CurrentCalls.value,
                tab: <ActiveCalls />,
            },
            {
                title:  t('screens:calls.recentCalls'),
                permission:
                Permission.Calls.Activity.RecentCalls.value,
                tab: (
                    <RecentCalls />
                ),
            },
            {
                title:  t('screens:calls.callStatistics'),
                permission:
                Permission.Calls.Activity.CallStatistics.value,
                tab: (
                    <CallStatistics />
                ),
            }
        ],
        permissions,
    );

    const calls = useSelector<ReduxState, {
        total: number;
        items: ActiveCall[];
        isLoading: boolean;
        refreshTime: string;
    } | undefined>(
        (state) => state.calls.activeCalls,
    );

    const top = (
        <div className={classes.header}>
            <Header title={t('screens:calls.callActivity')} hideTotal/>
            <div className={classes.rightSection}>
                <HeaderStat calls={calls?.items || []}/>
                <CallsTodayInfo />
            </div>
        </div>
    );

    if (isLoading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="call-history-loader" />
            </div>
        );
    }

    return (
        <PermissionPlaceholder
            permission={Permission.Calls.Activity.value}
        >
            <DetailsWrapper
                tabs={tabs}
                tabsNames={tabNames}
                top={top}
                defaultTabIndex={0}
                deleteText={t('screens:ringGroups.deleteRingGroup')}
                perm={perm}
            />
        </PermissionPlaceholder>

    );
};
