import React, {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {Box, Grid} from '@material-ui/core';
import TextField from '../../TextField/TextField';
import SelectField from '../../SelectField/SelectField';
import classNames from 'classnames';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import {CallerID, RingStrategy} from '../../../store/types/RingGroup';
import Radio from '../../Radio/Radio';
import {useDispatch, useSelector} from 'react-redux';
import {APIErrorInterface, ReduxState} from '../../../store/types';
import SwitchWithLabel from '../../SwitchWithLabel/SwitchWithLabel';
import UploadFile from '../../UploadFile/UploadFile';
import Loader from '../../Loader/Loader';
import {RingGroupGeneralFormProps, RingGroupGeneralFormType, useStyles,} from './generalFormUtils';
import {actions} from '../../../store';
import {isAnyKEyFromSearchExistInSource} from "../../../utils/isAnyKEyFromSearchExistInSource";
import WrapUpForm from "./WrapUpForm";

export const ringGroupGeneralFormDefaultValues: RingGroupGeneralFormType = {
    name: '',
    number: '',
    callerId: CallerID.KeepOriginal,
    ringbackTone: null,
    callPickupAllowed: true,
    ringStrategy: RingStrategy.Order,
};

const ringGroupGeneralFormKeys = [
    'name',
    'number',
    'callerId',
    'ringbackTone',
    'callPickupAllowed',
    'ringStrategy'
]

const GeneralForm: React.FC<RingGroupGeneralFormProps> = (
    {
        ringbackTonesList = [],
        tabName,
        tabIndex,
        callback,
        wrapUpVisible,wrapUpLock
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const dispatch = useDispatch();

    const {
        values,
        handleChange,
        errors,
        setFieldValue,
        setFieldError,
    } = useFormikContext<RingGroupGeneralFormType>();

    const apiErrors = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.ringgroups.errors,
    );

    const isLoading = useSelector<ReduxState, boolean>(
        (state) => state.ringgroups.isLoading,
    );

    const callerIds = Object.values(CallerID).map((v) => ({
        name: t(`enums:callerId.${v}`),
        value: v,
    }));

    const ringbackTonesSelectItems = [
        {name: t('common:uploadNewFile'), value: -1},
        {name: t('screens:ringGroups.standardTone'), value: null},
        ...ringbackTonesList.map((v) => ({name: v.name, value: v.id})),
    ];

    const onSave = (file: File, name: string) => {
        dispatch(
            actions.uploadRingbackTone.request({
                file: file,
                name: name,
                callback: () => setIsModalOpen(false),
            }),
        );
    };

    const markTabAsHaveError = (setTabError: boolean) => {
        if (tabName && tabIndex !== undefined) {
            dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
        }
    }

    useEffect(() => {
        let setTabError = false;
        if (apiErrors?.faultcode === 'Server.Customer.HG_name_already_in_use') {
            setFieldError('name', t('errors:ringGroups.nameInUse'));
            setTabError = true;
        }
        if (
            apiErrors?.faultcode ===
            'Server.Customer.Ext_HG_number_already_in_use' ||
            apiErrors?.faultcode ===
            'Client.Customer.Ext_HG_number_already_in_use'
        ) {
            setFieldError('number', t('errors:faxMailboxes.numberInUse'));
            setTabError = true;

        } else if (
            apiErrors?.faultcode === 'Server.Customer.duplicate_id' ||
            apiErrors?.faultcode === 'Client.Customer.duplicate_id'
        ) {
            setFieldError('number', t('errors:faxMailboxes.alreadyExists'));
            setTabError = true;

        }

        markTabAsHaveError(setTabError)
        if (setTabError && callback) {
            callback();
        }

    }, [apiErrors]);

    useEffect(() => {
        const errorsKeys = Object.keys(errors);
        if (isAnyKEyFromSearchExistInSource(errorsKeys, ringGroupGeneralFormKeys)) {
            markTabAsHaveError(true)
        } else {
            markTabAsHaveError(false)
        }

    }, [errors, values]);

    return (
        <div className={classes.inputs}>
            <Grid
                item
                className={classNames(classes.itemsContainer, classes.border)}
            >
                <TextField
                    id="name"
                    label={t('common:name')}
                    onChange={handleChange}
                    value={values.name}
                    icon={
                        <IconWithTooltip
                            dataQa="ring-group-name-tooltip"
                            tooltipText={t('tooltips:ringGroups.name')}
                        />
                    }
                    iconPosition="end"
                    dataQa="ring-group-name-input"
                    helperText={errors.name}
                    required
                    setFieldError={setFieldError}
                    maxLength={32}
                />

                <TextField
                    id="number"
                    label={t('common:number')}
                    onChange={handleChange}
                    value={values.number}
                    icon={
                        <IconWithTooltip
                            dataQa="ring-group-number-tooltip"
                            tooltipText={t('tooltips:ringGroups.number')}
                        />
                    }
                    iconPosition="end"
                    dataQa="ring-group-number-input"
                    helperText={errors.number}
                    required
                    setFieldError={setFieldError}
                    maxLength={5}
                />
            </Grid>

            <Grid
                item
                className={classNames(classes.itemsContainer, classes.spaceB)}
            >
                <SelectField
                    label={t('screens:ringGroups.callerID')}
                    onChange={(e, value) =>
                        value?.value && setFieldValue('callerId', value.value)
                    }
                    items={callerIds}
                    value={callerIds.find((v) => v.value === values.callerId)}
                    icon={
                        <IconWithTooltip
                            dataQa="ring-group-callerid-tooltip"
                            tooltipText={t('tooltips:ringGroups.callerId')}
                        />
                    }
                    required
                    dataQa="ring-group-callerid-select"
                    getOptionLabel={(v: { name: string; value: string }) =>
                        v.name
                    }
                    getOptionSelected={(v: { name: string; value: string }) =>
                        v.value === values.callerId
                    }
                />
                <SelectField
                    label={t('screens:ringGroups.ringbackTone')}
                    items={ringbackTonesSelectItems}
                    value={ringbackTonesSelectItems.find(
                        (v) => v.value === values.ringbackTone,
                    )}
                    dataQa="ring-group-ringback-tone-file-input"
                    onChange={(_, value) => {
                        if (value?.name === t('common:uploadNewFile')) {
                            setIsModalOpen(true);
                        } else {
                            setFieldValue('ringbackTone', value.value);
                        }
                    }}
                    getOptionLabel={(v: { name: string }) => v.name}
                    getOptionSelected={(v: { name: string; value: number }) =>
                        v.value === values.ringbackTone
                    }
                    disableClearable
                    icon={
                        <IconWithTooltip
                            dataQa="ring-group-callerid-tooltip"
                            tooltipText={t('tooltips:ringGroups.ringbackTone')}
                        />
                    }
                />
            </Grid>

            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.border,
                    classes.column,
                    classes.strategyContainer,
                )}
            >
                <Box className={classes.rowBox} width={350}>
                    <span className={classes.rowBoxHeader}>
                        {t('screens:ringGroups.ringStrategy')}
                    </span>

                    <IconWithTooltip
                        dataQa="extension-emergency-location-tooltip"
                        tooltipText={t('tooltips:ringGroups.ringStrategy')}
                    />
                </Box>
                <Box className={classes.rowBox}>
                    <Box
                        className={classNames(
                            classes.itemsContainer,
                            classes.radioContainer,
                            values.ringStrategy === RingStrategy.Order &&
                            classes.selectedRadio,
                        )}
                        flex={1}
                    >
                        <Grid>
                            <Grid>
                                <Radio
                                    dataQa="ring-group-order-strategy-radio"
                                    checked={
                                        values.ringStrategy ===
                                        RingStrategy.Order
                                    }
                                    name="ringStrategy"
                                    value={values.ringStrategy}
                                    onChange={() =>
                                        setFieldValue(
                                            'ringStrategy',
                                            RingStrategy.Order,
                                        )
                                    }
                                    label={t(
                                        `enums:ringStrategy.${RingStrategy.Order}`,
                                    )}
                                />
                            </Grid>
                            <p className={classes.radioDescription}>
                                {t(
                                    'screens:ringGroups.orderStrategyDescription',
                                )}
                            </p>
                        </Grid>
                    </Box>

                    <Box
                        className={classNames(
                            classes.itemsContainer,
                            classes.radioContainer,
                            values.ringStrategy === RingStrategy.Random &&
                            classes.selectedRadio,
                        )}
                        flex={1}
                    >
                        <Grid>
                            <Grid>
                                <Radio
                                    dataQa="ring-group-random-strategy-radio"
                                    checked={
                                        values.ringStrategy ===
                                        RingStrategy.Random
                                    }
                                    name="ringStrategy"
                                    value={values.ringStrategy}
                                    onChange={() =>
                                        setFieldValue(
                                            'ringStrategy',
                                            RingStrategy.Random,
                                        )
                                    }
                                    label={t(
                                        `enums:ringStrategy.${RingStrategy.Random}`,
                                    )}
                                />
                            </Grid>
                            <p className={classes.radioDescription}>
                                {t(
                                    'screens:ringGroups.randomStrategyDescription',
                                )}
                            </p>
                        </Grid>
                    </Box>

                    <Box
                        className={classNames(
                            classes.itemsContainer,
                            classes.radioContainer,
                            values.ringStrategy === RingStrategy.Simultaneous &&
                            classes.selectedRadio,
                        )}
                        flex={1}
                    >
                        <Grid>
                            <Grid>
                                <Radio
                                    dataQa="ring-group-simultaneous-strategy-radio"
                                    checked={
                                        values.ringStrategy ===
                                        RingStrategy.Simultaneous
                                    }
                                    name="ringStrategy"
                                    value={values.ringStrategy}
                                    onChange={() =>
                                        setFieldValue(
                                            'ringStrategy',
                                            RingStrategy.Simultaneous,
                                        )
                                    }
                                    label={t(
                                        `enums:ringStrategy.${RingStrategy.Simultaneous}`,
                                    )}
                                />
                            </Grid>
                            <p className={classes.radioDescription}>
                                {t(
                                    'screens:ringGroups.simultaneousStrategyDescription',
                                )}
                            </p>
                        </Grid>
                    </Box>

                    <Box
                        className={classNames(
                            classes.itemsContainer,
                            classes.radioContainer,
                            values.ringStrategy === RingStrategy.LeastUsed &&
                            classes.selectedRadio,
                        )}
                        flex={1}
                    >
                        <Grid>
                            <Grid>
                                <Radio
                                    dataQa="ring-group-least-used-strategy-radio"
                                    checked={
                                        values.ringStrategy ===
                                        RingStrategy.LeastUsed
                                    }
                                    name="ringStrategy"
                                    value={values.ringStrategy}
                                    onChange={() =>
                                        setFieldValue(
                                            'ringStrategy',
                                            RingStrategy.LeastUsed,
                                        )
                                    }
                                    label={t(
                                        `enums:ringStrategy.${RingStrategy.LeastUsed}`,
                                    )}
                                />
                            </Grid>
                            <p className={classes.radioDescription}>
                                {t(
                                    'screens:ringGroups.leastUsedStrategyDescription',
                                )}
                            </p>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.callPickupContainer,
                )}
            >
                <SwitchWithLabel
                    label={t('screens:ringGroups.callPickupAllowed')}
                    className={classes.switch}
                    setValue={setFieldValue}
                    value={values.callPickupAllowed}
                    field={'callPickupAllowed'}
                    id={'callPickupAllowed'}
                    icon={
                        <IconWithTooltip
                            dataQa="ring-group-ringback-tone-tooltip"
                            tooltipText={t(
                                'tooltips:ringGroups.callPickupAllowed',
                            )}
                        />
                    }
                />
            </Grid>

            {wrapUpVisible && (
                <>
                    <div className={classes.separator} />
                    <WrapUpForm disabled={wrapUpLock}/>
                </>
            )}


            {isModalOpen && (
                <UploadFile
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    onSave={onSave}
                    items={ringbackTonesSelectItems}
                />
            )}

            {isLoading && (
                <Loader dataQa="create-new-extension-loader" absolutePosition/>
            )}
        </div>
    );
};

export default GeneralForm;
