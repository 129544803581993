import React, {useMemo, useRef, useState} from 'react';
import { CallQueuePromptActionType, addDialogClosedListener, useStyles } from './callQueueFormUtils';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import classNames from 'classnames';
import SelectField from '../../SelectField/SelectField';
import { DropDownDictionaryItem } from '../../../views/CallHistory/ActiveCalls/ActiveCalls.utils';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import CheckboxWithIcon from '../../Checkbox/CheckboxWithIcon';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store/types';
import { ApiFile } from '../../../store/types/ApiFile';
import { ExtensionsListItem } from '../../../store/reducers/extensions/extensions/reducer';
import { MultiLevelSelectField, MultiLevelSelectItem } from '../../MultiLevelSelectField/MultiLevelSelectField';
import { CallQueue } from '../../../store/types/CallQueue';

export type CallQueuePromptActionProps = {
    header: string,
    noActionTitle: string,
    data: CallQueuePromptActionType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    takeActionToolTip: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: any;
    queueId: number;
};

const CallQueuePromptAction: React.FC<CallQueuePromptActionProps> = ({
    queueId,
    header,
    noActionTitle,
    data,
    setFieldValue,
    takeActionToolTip,
    errors
}) => {
    
    const classes = useStyles();
    const {t} = useTranslation();
    const fileRef = useRef<HTMLInputElement>(null);
    
    const [isFileSelectOpen, setIsFileSelectOpen] = useState(false);
    
    const ringbackTonesList = useSelector<ReduxState, ApiFile[]>(
        (state) => state.ringgroups.onHoldList || [],
    );

    const extensions = useSelector<ReduxState, ExtensionsListItem[]>(
        (state) => state.ringgroups?.groupMembersDetails?.extensions ?? []
    );
    
    const extensionsWithVoicemail = useSelector<ReduxState, ExtensionsListItem[]>(
        (state) => state.ringgroups?.groupMembersDetails?.extensionsWithVoicemail ?? []
    );

    const queues = useSelector<ReduxState, CallQueue[]>(
        (state) => state.callQueues?.callQueues ?? []
    );

    const extensionsList = useMemo(() => {
        return extensions
            .filter(e => e.account_id)
            .map(e => {
                return {
                    name: e.id + (e.name ? ' - ' + e.name : ''),
                    value: e.i_c_ext ?? 0
                } as DropDownDictionaryItem<number>;
            });
        
    }, [extensions]);

    const extensionsWithVoicemailList = useMemo(() => {
        return extensionsWithVoicemail
            .filter(e => e.account_id)
            .map(e => {
                return {
                    name: e.id + (e.name ? ' - ' + e.name : ''),
                    value: e.i_c_ext ?? 0
                } as DropDownDictionaryItem<number>;
            });
        
    }, [extensionsWithVoicemail]);

    const actionPrompts = useMemo(() => {
        const values = Object.values(Array.from(Array(5).keys())).map(
            (v) => ({
                name: t(`enums:callQueueActionPromptEnum.${v+1}`),
                value: v+1,
            } as DropDownDictionaryItem<number>));
        if(values.length) {
            values[0].name = noActionTitle;
        }
        return values;
    }, [noActionTitle]);

    const items = useMemo(() => {
        const result: MultiLevelSelectItem[] = [];
        for(const itm of actionPrompts) {
            const obj: MultiLevelSelectItem = {
                value: itm.value ?? 1,
                label: itm.name,
                disabled: false
            };
            if(itm.value === 4) {
                obj.nestedItems = [];
                for(const q of queues) {
                    if(q.i_c_queue === queueId) {
                        continue;
                    }
                    const sub: MultiLevelSelectItem = {
                        value: q.i_c_queue ?? 1,
                        label: q.group_name ?? '',
                        disabled: false
                    };
                    obj.nestedItems.push(sub);
                }
            }
            result.push(obj);
        }
        return result;
    }, [actionPrompts, queues]);

    const selectedLabel = useMemo(() => {
        const d = actionPrompts.find(e => e.value === data.action);
        if(d?.value === 4) {
            const queue = queues.find(e => e.i_c_queue === data.redirectToEntity);
            if(queue) {
                return (d?.name ?? '') + ' / ' + (queue.group_name ?? '');
            }
        }
        return d?.name ?? '';
    }, [actionPrompts, data, queues]);

    const fileSelectItems = useMemo(() => {
        const retArray: DropDownDictionaryItem<number>[] = [];
        if(data.promptName) {
            retArray.push({name: data.promptName, value: data.prompt});
        }
        retArray.push({name: t('common:uploadNewFile'), value: 1});
        retArray.push({name: t('common:default'), value: 0});
        return retArray;
    }, [data, ringbackTonesList]);

    const selectedExtension = useMemo(() => {
        if(!data.redirectToEntity) {
            return null;
        }
        if(data.action === 2) {
            return extensionsList.find(
                (v) => v.value === data.redirectToEntity,
            ) ?? null;
        } else if (data.action === 3) {
            return extensionsWithVoicemailList.find(
                (v) => v.value === data.redirectToEntity,
            ) ?? null;
        }
        return null;
    }, [data, extensionsList, extensionsWithVoicemailList]);

    const selectExtsList = useMemo(() => {
        return data.action === 2 ? extensionsList : extensionsWithVoicemailList;
    }, [data, extensionsList, extensionsWithVoicemailList]);

    return (
        <Grid className={classNames(classes.itemsContainer, classes.actionPromptContainer)}>

                <div className={classes.actionPromptHeader}>
                    {header}
                </div>
                
                <SelectField
                    label={t('screens:ringGroups.playPrompt')}
                    items={fileSelectItems}
                    value={
                        (isFileSelectOpen ? fileSelectItems.find((v) => v.value === 1) : null)
                        ?? fileSelectItems.find((v) => v.name === data.promptName)
                        ?? fileSelectItems.find((v) => v.value === 0)
                    }
                    dataQa="play-prompt-input"
                    onChange={(_, value: DropDownDictionaryItem<number>) => {
                        if (value?.value === 1) {
                            addDialogClosedListener(fileRef?.current, function() {
                                setTimeout(() => {
                                    setIsFileSelectOpen(false);
                                }, 200);
                            });
                            setIsFileSelectOpen(true);
                            fileRef.current?.click();
                        } else if ((value?.value ?? 0) >= 2) {
                            setFieldValue('promptName', value.name);
                            setFieldValue('playPromptFile', true);
                        } else if((value?.value ?? 0) === 0) {
                            setFieldValue('promptName', '');
                            setFieldValue('promptFile', '');
                            setFieldValue('playPromptFile', false);
                        }
                    }}
                    getOptionLabel={(v: DropDownDictionaryItem<number>) => v.name}
                    getOptionSelected={(v: DropDownDictionaryItem<number>) =>
                        v.value === data.promptName
                    }
                    disableClearable
                    className={classes.playPrompt}
                    helperText={
                        errors && (errors.promptName || errors.promptFile)
                            ? errors.promptName ?? errors.promptFile
                            : ''
                    }
                />
                
                <MultiLevelSelectField
                    label={t('screens:ringGroups.takeAction')}
                    items={items}
                    value={selectedLabel}
                    onChange={(value, subValue) => {
                        if(value === 1) {
                            setFieldValue('waitConfirmation', false);
                        } else if(value === 2 || value === 3) {
                            setFieldValue('redirectToEntity', undefined);
                        }
                        if(subValue) {
                            setFieldValue('redirectToEntity', subValue);
                        } else if(data.action !== value) {
                            setFieldValue('redirectToEntity', undefined);
                        }
                        setFieldValue('action', value);
                    }}
                    className={classes.promptSelect}
                    icon={
                        <IconWithTooltip
                            dataQa="play-prompt-input-tooltip"
                            tooltipText={takeActionToolTip}
                        />
                    }
                />
                
                {(data.action === 2 || data.action === 3) && (
                    <SelectField
                        dataQa="redirect-to-action-select"
                        label={t('screens:ringGroups.extension')}
                        items={selectExtsList}
                        value={selectedExtension}
                        getOptionLabel={(v: DropDownDictionaryItem<number>) => v?.name}
                        onChange={(_, value) => {
                            setFieldValue('redirectToEntity', value.value);
                        }}
                        getOptionSelected={(v: DropDownDictionaryItem<number>) =>
                            v.value === data.redirectToEntity
                        }
                        className={classes.promptSelect}
                        disableClearable
                        required
                        helperText={
                            errors && errors.redirectToEntity
                                ? errors.redirectToEntity
                                : ''
                        }
                    />
                )}
                
                <CheckboxWithIcon
                    dataQa="wait-user-confirmation"
                    checked={data.waitConfirmation}
                    onChange={(e) => setFieldValue(
                        'waitConfirmation',
                        e.target.checked,
                        false
                    )}
                    label={t(
                        'screens:ringGroups.waitForUserConfirmation'
                    )}
                    className={classes.waitConfirmationCheckBox}
                    containerStyle={classes.waitConfirmationCheckBox}
                    tooltipText={t(
                        'tooltips:ringGroups.waitForUserConfirmation'
                    )}
                    disabled={data.action === 1}
                />
                
                <input
                    type="file"
                    id="promptFile"
                    accept={'audio/*'}
                    ref={fileRef}
                    data-testid="prompt-file-upload"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                        event?.stopPropagation();
                        event?.preventDefault();
                        const file = event?.target?.files?.[0];
                        if (file) {
                            setFieldValue(
                                'promptName',
                                file.name,
                            );
                            setFieldValue(
                                'promptFile',
                                file,
                            );
                            setFieldValue('playPromptFile', true);
                        }
                    }}
                />
        </Grid>);
};

export default CallQueuePromptAction;
