import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions/calls';
import { ActiveCall, CallHistory } from '../../types/CallHistory';
import { WidgetCallHistory } from '../../types/Wallboard';

export type PlayingFile = {
    i_xdr: number;
    call_recording_id: string;
};

export enum RecordingsPlayingStatus {
    notDefined = 0,
    downloading = 1,
    downloadedNotStartedPlaying = 2,
    playing = 3,
    paused = 4,
    finished = 5,
    dowloadingRestricted = 6
};

export type RecordingsPlayingState = {
    filesQueue: PlayingFile[][];
    status: number;
    currentFile: PlayingFile | null;
    currentBlob: string | null;
    volume: number;
};

export interface CallsStateType {
    customerCurrency?: string;
    extensionCallHistory: {
        total: number;
        items: CallHistory[];
        isLoading: boolean;
    };
    callHistory: {
        total: number;
        items: CallHistory[];
        isLoading: boolean;
        allTotal: number;
        isRemovingGoingOn?: boolean;
    };
    activeCalls: {
        total: number;
        items: ActiveCall[];
        isLoading: boolean;
        refreshTime: string;
    };
    areCallsLoading?: boolean;
    todayCalls: number;
    yesterdayCalls: number;
    recordingsPlayer: RecordingsPlayingState;
    isStatisticLoading?: boolean;
    callsStatistic?: WidgetCallHistory[];
    callsStatisticUpdateDateFrom?: string;
    callsStatisticUpdateDateTo?: string;
}

export const initialState: CallsStateType = {
    extensionCallHistory: {
        total: 0,
        items: [],
        isLoading: false,
    },
    callHistory: {
        total: 0,
        items: [],
        isLoading: false,
        isRemovingGoingOn: false,
        allTotal: 0,
    },
    activeCalls: {
        total: 0,
        items: [],
        isLoading: false,
        refreshTime: '',
    },
    todayCalls: 0,
    yesterdayCalls: 0,
    recordingsPlayer: {
        filesQueue: [],
        status: RecordingsPlayingStatus.notDefined,
        currentFile: null,
        currentBlob: null,
        volume: 1
    }
};

export type CallsActionsType = ActionType<typeof actions>;

const callsReducer = createReducer<CallsStateType, CallsActionsType>(
    initialState,
)
    .handleAction(actions.getExtensionCallHistory.request, (state) => ({
        ...state,
        extensionCallHistory: {
            ...state.extensionCallHistory,
            items: [],
            total: 0,
            isLoading: true,
        },
    }))
    .handleAction(actions.getExtensionCallHistory.success, (state, action) => ({
        ...state,
        extensionCallHistory: {
            total: action.payload.total,
            items: action.payload.xdr_list,
            isLoading: false,
        },
    }))
    .handleAction(actions.getExtensionCallHistory.failure, (state) => ({
        ...state,
        extensionCallHistory: {
            ...state.extensionCallHistory,
            isLoading: false,
        },
    }))
    .handleAction(
        actions.setCustomerCurrencyForCallHistory,
        (state, action) => ({
            ...state,
            customerCurrency: action.payload.currency,
        }),
    )
    .handleAction(actions.getCallHistory.request, (state) => ({
        ...state,
        callHistory: {
            ...state.callHistory,
            items: [],
            allTotal: 0,
            isLoading: true,
        },
    }))
    .handleAction(actions.getCallHistory.success, (state, action) => ({
        ...state,
        callHistory: {
            total: action.payload.total,
            items: action.payload.xdr_list,
            isLoading: false,
            allTotal: state.callHistory.allTotal || action.payload.total,
        },
    }))
    .handleAction(actions.getCallHistory.failure, (state) => ({
        ...state,
        callHistory: {
            ...state.callHistory,
            isLoading: false,
        },
    }))
    .handleAction(actions.removeCallRecordings.request, (state) => ({
        ...state,
        extensionCallHistory: {
            ...state.extensionCallHistory,
            isLoading: true,
        },
        callHistory: {
            ...state.callHistory,
            isRemovingGoingOn: true,
        },
    }))
    .handleAction(actions.removeCallRecordings.success, (state) => ({
        ...state,
        callHistory: {
            ...state.callHistory,
            isRemovingGoingOn: false,
        },
    }))
    .handleAction(actions.removeCallRecordings.failure, (state) => ({
        ...state,
        callHistory: {
            ...state.callHistory,
            isRemovingGoingOn: false,
        },
    }))
    .handleAction(actions.getCallHistoryBasicData.request, (state) => ({
        ...state,
        areCallsLoading: true,
    }))
    .handleAction(actions.getCallHistoryBasicData.success, (state, action) => ({
        ...state,
        areCallsLoading: false,
        ...action.payload,
    }))
    .handleAction(actions.getCallHistoryBasicData.failure, (state) => ({
        ...state,
        areCallsLoading: false,
    }))
    .handleAction(actions.getActiveCalls.request, (state) => ({
        ...state,
        activeCalls: {
            ...state.activeCalls,
            isLoading: true,
        },
    }))
    .handleAction(actions.getActiveCalls.success, (state, action) => ({
        ...state,
        activeCalls: {
            ...state.activeCalls,
            isLoading: false,
            items: action.payload.calls,
            total: action.payload.total,
            refreshTime: action.payload.refreshTime,
        },
    }))
    .handleAction(actions.getActiveCalls.failure, (state) => ({
        ...state,
        activeCalls: {
            ...state.activeCalls,
            isLoading: false,
        },
    }))
    .handleAction(actions.updateRecordingsPlayingState, (state, action) => ({
        ...state,
        recordingsPlayer: action.payload,
    }))
    .handleAction(actions.getCallHistoryDataForStatistics.request, (state) => ({
        ...state,
        isStatisticLoading: true,
        callsStatisticUpdateDateFrom: undefined,
        callsStatisticUpdateDateTo: undefined,
    }))
    .handleAction(actions.getCallHistoryDataForStatistics.success, (state, action) => ({
        ...state,
        isStatisticLoading: false,
        callsStatistic: action.payload.calls,
        callsStatisticUpdateDateFrom: action.payload.lastDateFrom,
        callsStatisticUpdateDateTo: action.payload.lastDateTo,
    }))
    .handleAction(actions.getCallHistoryDataForStatistics.failure, (state) => ({
        ...state,
        isStatisticLoading: false,
        callsStatistic: undefined,
    }));

export default callsReducer;
