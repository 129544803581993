import React from 'react';
import {makeStyles} from '@material-ui/core';
import {TFunction} from 'react-i18next';
import {Column} from 'react-table';
import DateTimeCell from '../../../components/Calls/DateTimeCell';
import {Colors} from '../../../styles/Colors';
import TwoLineCell from '../../../components/Calls/TwoLineCell';
import {
    CalleeOrCaller,
    SipCall,
    SipCallGroup,
    SipCallState,
    SipCallType
} from '../../../store/actions/ringgroups/payloads';
import {ReactComponent as IncomingCallIcon} from '../../../assets/incomingCallList.svg';
import {ReactComponent as OutgoingCallIcon} from '../../../assets/outgoingCallList.svg';
import CustomizedTooltip from '../../../components/Tooltip/Tooltip';
import {ExtensionType} from '../../../store/types/Extension';
import {RingGroupType} from '../../../store/types/RingGroup';
import Transfer from "../../../components/CallControls/Transfer";
import Disconnect from "../../../components/CallControls/Disconnect";
import PutOnHold from "../../../components/CallControls/PutOnHold";
import AddAnotherExtensionToCall from "../../../components/CallControls/AddAnotherExtensionToCall";
import Resume from "../../../components/CallControls/Resume";
import {PermissionType} from "../../../store/types/Permission";
import RingGroupCell from "../../../components/Calls/RingGroupCell";
import {getRoute, Routes} from "../../../routes/routes";
import i18next from "i18next";
import {ExtensionsListItem} from "../../../store/reducers/extensions/extensions/reducer";


export type ActiveCallsFiltersProps = {
    state?: SipCallState;
    type?: SipCallType;
    ringGroup?: string;
    extension?: ExtensionsListItem,

};

export type DropDownDictionaryItem<T> = {
    name: string;
    value: T | undefined;
};

export type GroupedDropDownDictionaryItem<T> = {
    name: string;
    value: T | undefined;
    category: string;
};

export const useStyles = makeStyles(() => ({
    extensionField:{
      width:200
    },
    tableContainer: {
        maxWidth: 1040,

        '& tr td.MuiTableCell-root.MuiTableCell-body:nth-child(4) span': {
            marginLeft: -7,
        },

        '& th:nth-child(2)': {
            padding: 0,
            width: '24px !important'
        },

        '& tr td:nth-child(2)': {
            padding: '0 !important',
            width: '24px !important',
            justifyContent: 'end'
        },
    },
    connectingText: {
        fontSize: 14,
        fontStyle: 'italic',
        color: Colors.Gray5,
        fontFamily: 'Roboto',
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 32,
        width: "auto"
    },
    itemsContainer: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'flex-start',
    },
    inputsContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        width:'auto',

        '& > *': {
            marginRight: 16,
        },
    },
    button: {
        '& .MuiButton-label': {
            color: Colors.White,
        },
        width: 96,
        marginTop: 8
    },
    durationHeader: {
        paddingLeft: 15,
        width: 'fit-content'
    },
    actionHeader: {
        textAlign: "center",
        width: '100%'
    },
    duration: {
        fontSize: 17,
    },
    actions: {
        display: "flex",
        '& svg': {
            marginRight: 10
        },

        '& svg:last-child': {
            marginRight: 0
        }

    }
}));

export const initialValues: ActiveCallsFiltersProps = {
    state: undefined,
    type: undefined,
    ringGroup: undefined,
    extension:undefined
};

export const displayCallerText = (accounts: ExtensionType[], obj?: CalleeOrCaller) => {
    if (!obj?.extension_id) {
        return '';
    }

    const filtered = accounts.filter(e => e.extension_id?.length
        && obj.extension_id == e.extension_id
        && obj.centrex_id + '' == e.i_customer + '');

    if (!filtered.length) {
        return '';
    }

    return obj.extension_id + (filtered[0].extension_name ? (" - " + filtered[0].extension_name) : '');
}

export const fetchRingGroupNameByQueue = (call: SipCall, huntGroupList?: RingGroupType[]): RingGroupType | undefined => {
    return huntGroupList?.find(e => e?.assigned_callqueue?.i_c_queue === call.queue_info?.i_c_queue);
}

export const fetchRingGroupNameByHuntId = (huntId: string, huntGroupList?: RingGroupType[]): RingGroupType | undefined => {
    return huntGroupList?.find(e => e?.id === huntId);
}


export const generateColumns = (
    accounts: ExtensionType[],
    huntGroupList: RingGroupType[] | undefined,
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    timezoneOffset: number,
    actionsPermission: PermissionType,
    userDateTimeFormat?: string,
    handleOnClick?: () => void,
): Column<SipCall>[] | Column<SipCallGroup>[] | undefined => {

    const actions = actionsPermission != PermissionType.Hidden ?
        {
            accessor: 'id',
            Header: (<div className={classes.actionHeader}>{t<string>('screens:calls.actions')}</div>),
            width: 2,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                return (
                    <span className={classes.actions}>
                        <Transfer activeCallEntity={params.row.original}
                                  i_customer={params.row.original.i_customer ?? ''}
                                  callbackAfterOnClick={handleOnClick}
                                  isReadOnly={actionsPermission == PermissionType.ReadOnly}
                                  accounts={accounts}
                        />
                        <PutOnHold activeCallEntity={params.row.original} callbackAfterOnClick={handleOnClick}
                                   isReadOnly={actionsPermission == PermissionType.ReadOnly}
                        />
                        <Resume activeCallEntity={params.row.original} callbackAfterOnClick={handleOnClick}
                                isReadOnly={actionsPermission == PermissionType.ReadOnly}
                        />
                        <AddAnotherExtensionToCall activeCallEntity={params.row.original}
                                                   i_customer={params.row.original.i_customer ?? ''}
                                                   callbackAfterOnClick={handleOnClick}
                                                   isReadOnly={actionsPermission == PermissionType.ReadOnly}
                                                   accounts={accounts}
                        />
                        <Disconnect activeCallEntity={params.row.original} callbackAfterOnClick={handleOnClick}
                                    isReadOnly={actionsPermission == PermissionType.ReadOnly}
                                    accounts={accounts}

                        />
                    </span>
                );
            }
        } : undefined;

    const main = [
        {
            accessor: 'connect_time',
            Header: t<string>('screens:calls.startTime'),
            width: 1,
            Cell: function Cell(params: any) {


                if (params.row.original.groupName) {
                    return (
                        <div id={'group_row'}>
                            <RingGroupCell
                                title={params.row.original.groupName}
                                link={params.row.original.link}
                            />
                        </div>
                    )
                }

                const time = params.row.original.start_time || params.row.original.connect_time;
                return (
                    <DateTimeCell
                        timezoneOffset={timezoneOffset}
                        //@ts-ignore
                        time={time}
                        customDateFormat={userDateTimeFormat}
                        hideDateWhenIsToday
                    />
                );
            },
        },
        {
            accessor: 'type',
            Header: '',
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                const icon = params.row.original.type === SipCallType.Incoming
                    ? (<IncomingCallIcon/>)
                    : (<OutgoingCallIcon/>);
                const callTypeTooltip = t(
                    `enums:sipCallType.${params.row.original.type}`,
                );
                return (
                    <CustomizedTooltip title={callTypeTooltip} copy={false}>
                        {icon}
                    </CustomizedTooltip>
                );
            },
            minWidth: 24,
            maxWidth: 24
        },
        {
            accessor: 'caller',
            Header: t<string>('screens:calls.caller'),
            width: 1.5,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                const headerText = params.row.original.caller?.id == 'call_supervision' ?
                    t('screens:calls.conference') : params.row.original.caller?.id;

                return (
                    <TwoLineCell
                        headerText={headerText}
                        descriptionText={displayCallerText(accounts, params.row.original.caller)}
                    />
                );
            },
        },
        {
            accessor: 'callee',
            Header: t<string>('screens:calls.destination'),
            width: 1.5,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                const headerText = params.row.original.callee?.id == 'call_supervision' ?
                    t('screens:calls.conference') : params.row.original.callee?.id;

                return (
                    <TwoLineCell
                        headerText={headerText}
                        descriptionText={
                            displayCallerText(accounts, params.row.original.callee)
                        }
                    />
                );
            },
        },
        {
            accessor: 'state',
            Header: t<string>('screens:calls.callState'),
            width: 2,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                const call = params.row.original;

                let secondRowText = '';
                if (call.state === SipCallState.Queued) {
                    secondRowText = t<string>('screens:calls.queuedAndBelow', {
                        position: call.queue_info?.position || 0,
                    });
                }

                return (
                    <TwoLineCell
                        headerText={t(
                            `enums:sipCallState.${params.row.original.state}`,
                        ) + (params.row.original.state === SipCallState.Ringing || params.row.original.state === SipCallState.Trying || params.row.original.state === SipCallState.Early
                            ? '...' : '')
                        }
                        headerClassName={
                            params.row.original.state === SipCallState.Ringing || params.row.original.state === SipCallState.Trying || params.row.original.state === SipCallState.Early
                                ? classes.connectingText
                                : undefined
                        }
                        descriptionText={secondRowText}
                    />
                );
            },
        },
        {
            accessor: 'update_time',
            Header: (<div className={classes.durationHeader}>{t<string>('screens:calls.duration')}</div>),
            width: 1,
            Cell: function Cell(params: any) {

                if (params.row.original.groupName) {
                    return <></>
                }

                return (
                    <span className={classes.duration}>
                        {params.row.original.duration}
                    </span>
                );
            }
        },

    ];

    if (actions) {
        // @ts-ignore
        return [...main, actions];
    } else {
        // @ts-ignore
        return main
    }
};

export const prepareData = (data: SipCall[], huntGroupList: RingGroupType[] | undefined) => {

    let output: any[] = [{
        id: i18next.t<string>('screens:calls.otherCalls'),
        groupName: i18next.t<string>('screens:calls.otherCalls'),
        i_c_group: undefined,
        link: undefined,
        isGroup: true,
        items: []
    }];

    huntGroupList?.forEach(item => !output.find(v => v.groupName == item.name) ? output.push({
        id: item.name,
        groupName: item.name,
        i_c_group: item.i_c_group,
        link: getRoute(Routes.RingGroupsDetails, {id: item.i_c_group}),
        isGroup: true,
        items: []
    }) : null);

    data?.forEach(item => {

        const call = item;
        const huntId = call.callee.huntgroup_id || call.caller.huntgroup_id || '';

        let noc: RingGroupType | undefined = undefined;
        if (call.state === SipCallState.Queued || call.state === SipCallState.Dequeued) {
            noc = fetchRingGroupNameByQueue(call, huntGroupList);
        } else if (huntId) {
            noc = fetchRingGroupNameByHuntId(huntId, huntGroupList);
        }

        const index = output.findIndex(item => item.groupName == noc?.name);

        if (index != -1) {
            output[index].items.push(item)
        } else {
            output[0].items.push(item)
        }
    })


    output = output.filter(v => v.items.length);

    if (output.length) {
        output.reverse();
        output.forEach(v => v.items = v.items.sort((a: SipCall, b: SipCall) => {
            return (a?.start_time || a?.connect_time || 0) < (b?.start_time || b?.connect_time || 0)
        }))
    }

    const newOutput: Column<SipCall>[] | Column<SipCallGroup>[] | undefined = [];

    output.forEach(v => {
        const {items, ...group} = v;
        newOutput.push(group);
        newOutput.push(...items)
    })

    return newOutput;
}

export const fakeData: SipCall[] = [
    {
        "tracking_id": "50f23a40-70c9-4641-ada1-9e1e90c6fbf8",
        "caller": {
            "account_id": "ph7031x1001",
            "centrex_id": "7031",
            "display_id": "ph7031x1001",
            "forwarder_list": [],
            "id": "ph7031x1001",
            "extension_id": "1001"
        },
        "callee": {
            "id": "380445550011",
            "forwarder_list": [],
            "extension_id": "11",
            "centrex_id": "4367",
            "account_id": "380445550011",
            "display_id": "380445550011"
        },
        "state": "holding",
        "update_time": "2023-08-31 09:01:57",
        "call": {
            "tag": "43853e7c",
            "id": "NGFlOTY0NjI2NGM0ZWZkNDFjNTA0MzAwNDNhOWI0YzE."
        },
        "type": "outgoing",
        "connect_time": "2023-08-31 09:01:57",
        "duration": "00:04:12",
        "durationFormatSec": "4 min 12 sec"
    },
    {
        "type": "outgoing",
        "call": {
            "id": "_QVzYes7kFcxDTsLBforiA..",
            "tag": "f1371e11"
        },
        "update_time": "2023-08-31 09:03:01",
        "connect_time": "2023-08-31 09:03:01",
        "tracking_id": "d83751e9-c00c-4f65-81f6-c7a60fb6012c",
        "callee": {
            "extension_id": "11",
            "forwarder_list": [],
            "id": "380445550011",
            "centrex_id": "4367",
            "account_id": "380445550011",
            "display_id": "380445550011"
        },
        "caller": {
            "extension_id": "1000",
            "forwarder_list": [],
            "id": "ph7031x1000",
            "huntgroup_id": "2000",
            "display_id": "ph7031x1000",
            "centrex_id": "7031",
            "account_id": "ph7031x1000"
        },
        "state": "connected",
        "duration": "00:03:08",
        "durationFormatSec": "3 min 8 sec"
    },
    {
        "connect_time": "2023-08-31 09:03:20",
        "update_time": "2023-08-31 09:03:20",
        "call": {
            "id": "ibprzxsbjgjvnpl@mitchell",
            "tag": "buyxc"
        },
        "type": "outgoing",
        "state": "connected",
        "tracking_id": "db38d114-9cc1-4202-a66e-60036a678f99",
        "caller": {
            "display_id": "ph7031x1002",
            "account_id": "ph7031x1002",
            "extension_id": "1002",
            "id": "ph7031x1002",
            "huntgroup_id": "876",
            "centrex_id": "7031",
            "display_name": "ph7031x1002",
            "forwarder_list": []
        },
        "callee": {
            "extension_id": "555",
            "forwarder_list": [
                {
                    "centrex_id": "7031",
                    "display_id": "27100000003",
                    "extension_id": "555",
                    "id": "27100000003"
                }
            ],
            "id": "ph7031aa2",
            "display_id": "ph7031aa2",
            "centrex_id": "7031",
            "account_id": "ph7031aa2"
        },
        "duration": "00:02:49",
        "durationFormatSec": "2 min 49 sec"
    },
    {
        "update_time": "2023-08-31 09:03:20",
        "call": {
            "id": "ibprzxsbjgjvnpl@mitchell",
            "tag": "100116"
        },
        "type": "incoming",
        "queue_info": {
            "position": 0,
            "operators": 1,
            "i_c_queue": 272
        },
        "tracking_id": "db38d114-9cc1-4202-a66e-60036a678f99",
        "caller": {
            "centrex_id": "7031",
            "display_name": "ph7031x1002",
            "huntgroup_id": "876",
            "forwarder_list": [],
            "account_id": "ph7031x1002",
            "display_id": "ph7031x1002",
            "id": "ph7031x1002",
            "extension_id": "1002"
        },
        "callee": {
            "id": "ph7031aa2",
            "forwarder_list": [
                {
                    "display_id": "27100000003",
                    "centrex_id": "7031",
                    "id": "27100000003",
                    "extension_id": "555"
                }
            ],
            "extension_id": "555",
            "display_id": "ph7031aa2",
            "account_id": "ph7031aa2",
            "centrex_id": "7031"
        },
        "state": "queued",
        "duration": "00:02:49",
        "durationFormatSec": "2 min 49 sec"
    },
    {
        "queue_info": {
            "operators": 1,
            "position": 1,
            "i_c_queue": 272
        },
        "update_time": "2023-08-31 09:05:53",
        "type": "incoming",
        "call": {
            "tag": "100117",
            "id": "EPlfPyhCYXr7oybtBrEEDOj9"
        },
        "state": "queued",
        "tracking_id": "009a6328-bf61-49a0-bd3d-75a2620ebc89",
        "caller": {
            "extension_id": "11",
            "id": "380661111111",
            "forwarder_list": [],
            "display_id": "380445550011",
            "centrex_id": "4367",
            "display_name": "John",
            "account_id": "380445550011"
        },
        "callee": {
            "display_id": "ph7031aa2",
            "account_id": "ph7031aa2",
            "centrex_id": "7031",
            "extension_id": "555",
            "forwarder_list": [
                {
                    "extension_id": "555",
                    "id": "27100000003",
                    "centrex_id": "7031",
                    "display_id": "27100000003"
                }
            ],
            "id": "ph7031aa2"
        },
        "duration": "00:00:16",
        "durationFormatSec": "16 sec"
    },
    {
        "state": "ringing",
        "start_time": "2023-08-31 09:06:00",
        "callee": {
            "display_id": "+380445550011",
            "centrex_id": "4367",
            "account_id": "+380445550011",
            "id": "+380445550011",
            "forwarder_list": []
        },
        "caller": {
            "centrex_id": "7031",
            "display_name": "Mitchell",
            "huntgroup_id": "2000",
            "forwarder_list": [],
            "account_id": "ph7031x1000",
            "display_id": "ph7031x1000",
            "extension_id": "1000",
            "id": "ph7031x1000"
        },
        "tracking_id": "8af2b603-bb18-4264-b82b-2d11477ab5c8",
        "call": {
            "id": "mPBso6L2yyMiSkMrc5onDf96",
            "tag": "3s9cpk0sd93xe"
        },
        "type": "outgoing",
        "update_time": "2023-08-31 09:06:00",
        "duration": "00:00:09",
        "durationFormatSec": "9 sec"
    }
]
