import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../../styles/Colors";
import {GetCallProcessingPolicyListEntity, OperationMode} from "../../../../store/actions/extensions/payloads";
import {TFunction} from "react-i18next";
import {Column} from "react-table";
import {translateBitMak} from "../../../../utils/extensions/translateBitMak";
import StickyCell from "./StickyCell";
import classNames from "classnames";
import {Permission} from "../../../../store/types/Permission";
import CustomizedIconButton from "../../../IconButton/IconButton";
import {Delete, Edit} from "@material-ui/icons";
import React from "react";

export type PolicyModesTabProps = {
    onDelete: (mode: OperationMode) => void
};

export const useStyles = makeStyles(() => ({
    tabsContainer: {
        backgroundColor: Colors.White,
        border: 'none',
        width: 'calc(100% - 64px)',
        paddingLeft:24,
        overflowX: 'hidden',
        
        '& table': {
            width: 760,
            marginLeft:8,
            '& .MuiTableBody-root .MuiTableCell-root': {
                height: 57
            },
        }
    },
    defaultValue: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        color: Colors.Gray6,
        cursor: 'default'
    },
    valueSpan: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        color: Colors.Text
    },
    stickyIcon: {
        color: Colors.Secondary1,
        border: `1px solid ${Colors.Secondary1}`,
        padding: '3px 8px',
        marginLeft: 16
    },
    addModeButton: {
        marginTop: 16,
        marginLeft: 32,
        marginBottom: 22
    },
    cropLongValues: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}));

const isModeInUse = (mode: OperationMode, policies:GetCallProcessingPolicyListEntity[],modes: OperationMode[],) => {
    if (!policies?.length) return false;

    for (const policy of policies) {
        if (!policy?.rule_list?.length) continue;
        for (const rule of policy.rule_list) {
            const cpId = rule.operation_mode || 0;
            const usedModes = translateBitMak(cpId, modes || [])?.modes;

            if (usedModes.includes(mode.name)) {
                return true;
            }
        }
    }

    return false;
};

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    policies: GetCallProcessingPolicyListEntity[],
    modes: OperationMode[],
    onEdit?: (object: OperationMode, inUsage?:boolean) => void,
    onDelete?: (object: OperationMode) => void,
): Column<OperationMode>[] => {

    return [
        {
            Header: t<string>('common:name'),
            accessor: 'name',
            width: 2,
            Cell: function Cell(params) {
                const name = params.row.original.name;
                const isDefault = (params.row.original.short_code || 0) === 0;
                const isSticky = params.row.original.sticky === 'Y';
                return (
                    <StickyCell
                        text={isDefault ? t('enums:callScreeningMode.Default') : name}
                        stickyText={t('screens:callSettings.sticky')}
                        isSticky={isSticky}
                        customClasses={{
                            valueText: classNames(isDefault ? classes.defaultValue : undefined, classes.cropLongValues),
                            stickyText: classes.stickyIcon
                        }}
                        onClick={() => {
                            !isDefault && onEdit?.(params.row.original);
                        }}
                        permissions={Permission.Calls.Settings.CallScreening.value}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:callSettings.dialToSwitch'),
            accessor: 'short_code',
            width: 1.5,
            Cell: function Cell(params) {
                const value = params.row.original.short_code || 0;
                const isDefault = (params.row.original.short_code || 0) === 0;
                return (
                    <span className={classNames(isDefault ? classes.defaultValue : classes.valueSpan)}>{value}</span>);
            },
        },
        {
            Header: t<string>('screens:callSettings.expiresAfter'),
            accessor: 'default_timeout',
            width: 1.5,
            Cell: function Cell(params) {
                const value = params.row.original.default_timeout || 0;
                const isDefault = (params.row.original.short_code || 0) === 0;
                return (
                    <span className={classNames(isDefault ? classes.defaultValue : classes.valueSpan)}>
                        {value === 0 ? '-' : value.toString()}
                    </span>
                );
            },
        },
        {
            Header: '',
            accessor: 'i_operation_mode',
            width: 1,
            Cell: function Cell(params) {
                const original = params.row.original;
                const isDefault = (params.row.original.short_code || 0) === 0;

                return isDefault ? (<></>) : (<>
                    <CustomizedIconButton
                        onClick={() => {
                            onEdit?.(original, params.row.original.is_used == 1);
                        }}
                        dataTestId="edit-mode-item-button"
                        dataQa="edit-mode-item-button"
                        tooltipText={t<string>('common:edit')}
                    >
                        <Edit htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>

                    {!params.row.original.is_used && (
                        <CustomizedIconButton
                            onClick={() => {
                                onDelete?.(original);
                            }}
                            dataTestId="remove-mode-item-button"
                            dataQa="remove-mode-item-button"
                            tooltipText={t<string>('common:delete')}
                        >
                            <Delete htmlColor={Colors.ListIconColor}/>
                        </CustomizedIconButton>)
                    }
                </>);
            },
        }
    ];
}