import React, {useCallback, useEffect, useRef, useState} from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {Colors} from '../../styles/Colors';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import CustomizedIconButton from '../IconButton/IconButton';
import {Timer} from '@material-ui/icons';
import {ClickAwayListener, Popper} from '@material-ui/core';
import SelectField from '../SelectField/SelectField';
import CustomizedCheckbox from '../Checkbox/Checkbox';
import {
    checkIsWholeDay,
    default12Input,
    default12TimeFrom,
    default12TimeTo,
    default24Input,
    default24TimeFrom,
    default24TimeTo,
    defaultMax12TimeTo,
    defaultMax24TimeTo,
    defaultMin12TimeFrom,
    defaultMin24TimeFrom,
    generateInputValueOnChange,
    generateTimeRangeUsingMatrix,
    hour12Mask,
    hour12Placeholder,
    hour24Mask,
    hour24Placeholder,
    hours12,
    hours24,
    hourTypes,
    minutes,
    time12Matrix,
    time24Matrix,
    TimeRangePickerProps,
    useStyles,
    WHOLE_DAY_VALUE,
    wholeDayMask,
} from './TimeRangePicker.utils';
import {useTranslation} from 'react-i18next';
import {usePermissionContext} from "../../hooks/usePermissions";
import {PermissionType} from "../../store/types/Permission";

const TimeMask = (props: any) => {
    const { inputRef, ampm, ...other } = props;
    const { t } = useTranslation();
    const translatedWholeDayInitChar = t('screens:ringSchedule.selectWholeDay')[0].toUpperCase();

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={
                other.value[0] === translatedWholeDayInitChar
                    ? wholeDayMask
                    : ampm
                    ? hour12Mask
                    : hour24Mask
            }
            placeholderChar={'_'}
            placeholder={
                other.value[0] === translatedWholeDayInitChar
                    ? ''
                    : ampm
                    ? hour12Placeholder
                    : hour24Placeholder
            }
            keepCharPositions
        />
    );
};

const TimeRangePicker: React.VFC<TimeRangePickerProps> = ({
    name,
    label,
    id = 'time-range-picker',
    className,
    placeholder,
    InputProps,
    inputProps,
    InputLabelProps,
    ampm,
    defaultValue,
    onChange,
    required,
    helperText,
    customClasses,
}) => {
    const classes = useStyles();
    const inputRef = useRef<any>();
    const { t } = useTranslation();
    const permission = usePermissionContext();

    const [anchorEl, setAnchorEl] = useState(null);
    const [inputValue, setInputValue] = useState(
        defaultValue || (ampm ? default12Input : default24Input),
    );

    const [timeFrom, setTimeFrom] = useState(
        ampm ? default12TimeFrom : default24TimeFrom,
    );
    const [timeTo, setTimeTo] = useState(
        ampm ? default12TimeTo : default24TimeTo,
    );

    const wholeDayValue = checkIsWholeDay(timeFrom, timeTo, ampm);

    useEffect(() => {
        if (inputValue === WHOLE_DAY_VALUE) {
            return;
        }

        const valuesMatrix = ampm ? time12Matrix : time24Matrix;
        const timeFrom = generateTimeRangeUsingMatrix(
            inputValue,
            valuesMatrix.timeFrom,
        );

        const timeTo = generateTimeRangeUsingMatrix(
            inputValue,
            valuesMatrix.timeTo,
        );

        setTimeFrom(timeFrom);
        setTimeTo(timeTo);

        const newWholeDay = checkIsWholeDay(timeFrom, timeTo, ampm);

        if (defaultValue && newWholeDay) {
            setInputValue(WHOLE_DAY_VALUE);
        }

        if (defaultValue !== inputValue) {
            onChange?.(inputValue);
        }
    }, [inputValue, ampm, defaultValue]);

    const onChangeTime = useCallback(
        (timeFrom, timeTo) => {
            setTimeFrom(timeFrom);
            setTimeTo(timeTo);

            const wholeDayValue = checkIsWholeDay(timeFrom, timeTo, ampm);

            const value = generateInputValueOnChange(
                timeFrom,
                timeTo,
                wholeDayValue,
                ampm,
            );

            if (value !== inputValue) {
                setInputValue(value);
                if (wholeDayValue) {
                    const fullLengthValue = generateInputValueOnChange(
                        timeFrom,
                        timeTo,
                        false,
                        ampm,
                    )
                    onChange?.(fullLengthValue);
                }
            }
        },
        [timeFrom, timeTo, wholeDayValue, inputValue, ampm],
    );

    const handleClick = (event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);

        if (inputValue === '') {
            onChangeTime(
                ampm ? default12TimeFrom : default24TimeFrom,
                ampm ? default12TimeTo : default24TimeTo,
            );
        }
    };

    const onKeyDown = useCallback(
        (e: any) => {
            if (e.key === 'W') {
                onChangeTime(
                    ampm ? defaultMin12TimeFrom : defaultMin24TimeFrom,
                    ampm ? defaultMax12TimeTo : defaultMax24TimeTo,
                );
            }
            if (e.key === 'Backspace' && inputValue === WHOLE_DAY_VALUE) {
                setInputValue('');
            }
        },
        [ampm],
    );

    const setWholeDay = useCallback(() => {
        if (wholeDayValue) {
            onChangeTime(
                ampm ? default12TimeFrom : default24TimeFrom,
                ampm ? default12TimeTo : default24TimeTo,
            );
        } else {
            onChangeTime(
                ampm ? defaultMin12TimeFrom : defaultMin24TimeFrom,
                ampm ? defaultMax12TimeTo : defaultMax24TimeTo,
            );
        }
    }, [wholeDayValue, ampm]);

    const timeMask = useCallback(
        (props) => <TimeMask {...props} ampm={ampm} />,
        [ampm],
    );

    const open = Boolean(anchorEl);
    const ids = open ? 'simple-popper' : undefined;

    return (
        <div
            ref={inputRef}
            className={classNames(classes.textField, className)}
        >
            <FormControl className={classNames(!!helperText && classes.error)}>
                {label && (
                    <InputLabel htmlFor={id} {...InputLabelProps}>
                        {label}
                        {required && (
                            <span className={classes.requiredSign}> *</span>
                        )}
                    </InputLabel>
                )}

                <span className={classes.testValue} data-testid="input-value">
                    {inputValue}
                </span>

                <Input
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                    name={name}
                    id={id}
                    inputComponent={timeMask}
                    placeholder={placeholder}
                    {...InputProps}
                    inputProps={inputProps}
                    onKeyDown={onKeyDown}
                    required={required}
                    readOnly={permission !== PermissionType.Visible}
                    disabled={permission !== PermissionType.Visible}
                    classes={{disabled:permission !== PermissionType.Visible ? classes.readOnly: undefined}}
                />

                <CustomizedIconButton
                    className={classes.iconButton}
                    onClick={handleClick}
                    dataTestId="time-range-picker-popper"
                >
                    <Timer htmlColor={Colors.Gray5} aria-describedby={ids} />
                </CustomizedIconButton>

                <Popper
                    open={open}
                    anchorEl={inputRef.current}
                    container={document.body}
                    style={{ zIndex: 1000 }}
                    popperOptions={{
                        modifiers: {
                            offset: {
                                enabled: true,
                                offset: '0,8px',
                            },
                        },
                        positionFixed: true,
                    }}
                    placement="bottom-start"
                >
                    {({ TransitionProps }) => (
                        <ClickAwayListener onClickAway={handleClick}>
                            <div
                                className={classNames(classes.popperContainer, customClasses?.popperContainer)}
                                {...TransitionProps}
                            >
                                <div className={classes.popperRow}>
                                    <span className={classes.timeLabel}>
                                        {t('common:from')}
                                    </span>
                                    <SelectField
                                        label={t('common:hour')}
                                        dataTestId="hour-from"
                                        items={ampm ? hours12 : hours24}
                                        value={timeFrom.hours}
                                        disableClearable
                                        onChange={(_, value) => {
                                            onChangeTime(
                                                {
                                                    ...timeFrom,
                                                    hours: value,
                                                },
                                                timeTo,
                                            );
                                        }}
                                        classes={{
                                            container: classes.selectContainer,
                                        }}
                                    />
                                    <SelectField
                                        label={t('common:minutes')}
                                        items={minutes}
                                        value={timeFrom.minutes}
                                        disableClearable
                                        onChange={(_, value) => {
                                            onChangeTime(
                                                {
                                                    ...timeFrom,
                                                    minutes: value,
                                                },
                                                timeTo,
                                            );
                                        }}
                                        classes={{
                                            container: classes.selectContainer,
                                            popper: classes.popupIndicator,
                                        }}
                                        filterOptions={(options) => options}
                                        onTextChange={(v) => {
                                            if (
                                                minutes.includes(v.target.value)
                                            ) {
                                                onChangeTime(
                                                    {
                                                        ...timeFrom,
                                                        minutes: v.target.value,
                                                    },
                                                    timeTo,
                                                );
                                            }
                                        }}
                                    />
                                    {ampm && (
                                        <SelectField
                                            label={' '}
                                            items={hourTypes}
                                            value={timeFrom.hourType}
                                            disableClearable
                                            onChange={(_, value) => {
                                                onChangeTime(
                                                    {
                                                        ...timeFrom,
                                                        hourType: value,
                                                    },
                                                    timeTo,
                                                );
                                            }}
                                            classes={{
                                                container:
                                                    classes.selectContainer,
                                            }}
                                            dataTestId="ampm-select"
                                        />
                                    )}
                                </div>
                                <div className={classes.popperRow}>
                                    <span className={classes.timeLabel}>
                                        {t('common:to')}
                                    </span>
                                    <SelectField
                                        label={t('common:hour')}
                                        items={ampm ? hours12 : hours24}
                                        value={timeTo.hours}
                                        disableClearable
                                        onChange={(_, value) => {
                                            onChangeTime(timeFrom, {
                                                ...timeTo,
                                                hours: value,
                                            });
                                        }}
                                        classes={{
                                            container: classes.selectContainer,
                                        }}
                                    />
                                    <SelectField
                                        label={t('common:minutes')}
                                        items={minutes}
                                        value={timeTo.minutes}
                                        disableClearable
                                        onChange={(_, value) => {
                                            onChangeTime(timeFrom, {
                                                ...timeTo,
                                                minutes: value,
                                            });
                                        }}
                                        classes={{
                                            container: classes.selectContainer,
                                            popper: classes.popupIndicator,
                                        }}
                                        filterOptions={(options) => options}
                                        onTextChange={(v) => {
                                            if (
                                                minutes.includes(v.target.value)
                                            ) {
                                                onChangeTime(timeFrom, {
                                                    ...timeTo,
                                                    minutes: v.target.value,
                                                });
                                            }
                                        }}
                                    />
                                    {ampm && (
                                        <SelectField
                                            label={' '}
                                            items={hourTypes}
                                            value={timeTo.hourType}
                                            disableClearable
                                            onChange={(_, value) => {
                                                onChangeTime(timeFrom, {
                                                    ...timeTo,
                                                    hourType: value,
                                                });
                                            }}
                                            classes={{
                                                container:
                                                    classes.selectContainer,
                                            }}
                                            dataTestId="ampm-select"
                                        />
                                    )}
                                </div>

                                <CustomizedCheckbox
                                    label={WHOLE_DAY_VALUE}
                                    checked={wholeDayValue}
                                    onChange={setWholeDay}
                                    dataQa="whole-day-checkbox"
                                    className={classes.wholeDayCheckbox}
                                />
                            </div>
                        </ClickAwayListener>
                    )}
                </Popper>
            </FormControl>

            {!!helperText && (
                <span className={classes.helperText}>{helperText}</span>
            )}
        </div>
    );
};

export default TimeRangePicker;
