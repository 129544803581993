import React, {useState} from "react";
import {useSelector} from "react-redux";
import {OperationMode} from "../../../../store/actions/extensions/payloads";
import {ReduxState} from "../../../../store/types";
import DataGrid from "../../../DataGrid/DataGrid";
import {useTranslation} from 'react-i18next';
import CreationButton from "../../../CreationButton/CreationButton";
import NewOperationModeDialog from "./NewOperationModeDialog";
import {NewOperationModeDialogProps} from "./NewOperationModeDialog.utils";
import {generateColumns, PolicyModesTabProps, useStyles} from "./PolicyModesTab.utils";

const PolicyModesTab: React.FC<PolicyModesTabProps> = ({
    onDelete
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    const policies = useSelector(
        (state: ReduxState) => state.extensions?.callProcessingPolicyList || [],
    );
    
    const callProcessingOperationModeList = useSelector(
        (state: ReduxState) => state.extensions?.callProcessingOperationModeList || [],
    );
    
    const [showAddEditModeDialog, setShowAddEditModeDialog] = useState<NewOperationModeDialogProps>({
        isOpen: false
    });
    
    const columns = generateColumns(
        t,
        classes,
        policies,
        callProcessingOperationModeList,
        (object: OperationMode, inUsage) => {
            setShowAddEditModeDialog({
                isOpen: true,
                editObject: object,
                inUsage,
                clickOnClose: () => {
                    setShowAddEditModeDialog({
                        isOpen: false,
                        editObject: undefined,
                    });
                }
            });
        },
        onDelete
    );

    return (
        <>
            <DataGrid<OperationMode>
                classes={{
                    tableContainer: classes.tabsContainer,
                }}
                columns={columns}
                data={callProcessingOperationModeList}
                rowCount={callProcessingOperationModeList?.length || 0}
                pageSizeOptions={[callProcessingOperationModeList?.length || 0]}
                initialPageSize={Number.MAX_VALUE}
                centeredRows
                hideFooter
                getItemId={(r) => r.i_operation_mode}
            />
            <CreationButton
                data-qa="add-mode-button"
                onClick={() => {
                    setShowAddEditModeDialog({
                        isOpen: true,
                        editObject: undefined,
                        clickOnClose: () => {
                            setShowAddEditModeDialog({
                                isOpen: false,
                                editObject: undefined,
                            });
                        }
                    });
                }}
                className={classes.addModeButton}
                title={t('screens:callSettings.addMode')}
            />
            <NewOperationModeDialog
                {...showAddEditModeDialog}
            />
        </>
    )
}

export default PolicyModesTab;