import {ActionType, createReducer} from 'typesafe-actions';
import {actions} from '../../index';
import {StatusFilterType} from "../../sagas/extensions/extensions/saga";
import {SipTrunksListItem} from "../extensions/extensions/reducer";
import {FetchDataStatus} from "../autoAttendants/reducer";
import {APIErrorInterface} from "../../types";
import {CustomerInfoDetails} from "../../types/CustomerInfo";
import {DynamicListEntity} from "../../../components/Forms/SipTrunks/SipContact/SipContactForm.utils";

export interface SipTrunksFilters {
    trunkIdFilter?: string;
    didNumberFilter?: string;
    statusFilter?: StatusFilterType | undefined;
}

export type SipTrunksState = {
    sipTrunksList: {
        total: number;
        items: SipTrunksListItem[];
        filters: SipTrunksFilters;
        allTotal?: number;
        registeredTotal?: number
    };
    createNewSipTrunkErrors?: APIErrorInterface;
    createSipTrunkDataIsLoading?: boolean;
    isCreatingNewSipTrunk?: boolean;
    isLoading: boolean,
    isSipTrunkDeleted: boolean,
    isSipTrunkDetailsLoaded: boolean,
    sipTrunkDetails?: SipTrunkDetailsDetailsType;
    dynamicList?: DynamicListEntity[]
};

type SipTrunkDetailsDetailsType = {
    isFormSending?: boolean;
    isFormDataLoading?: boolean;
    sipTrunk?: SipTrunksListItem & {
        delete_enable?: boolean;
        customerInfo?: CustomerInfoDetails;
        product_visible_name?: string;
    };
    sipTrunkTab?: {
        isLoading?: boolean;
    };
    callScreeningTab?: {
        isLoading?: boolean;
    };
    callSettingsTab?: {
        isLoading?: boolean;
    };
    planTab?: {
        isLoading?: boolean;
    };

    callForwardingTab?: {
        isLoading?: boolean,
    },

    apiErrors?: APIErrorInterface,

};

export const initialState: SipTrunksState = {
    sipTrunksList: {
        total: 0,
        items: [],
        filters: {}
    },
    isLoading: false,
    isSipTrunkDetailsLoaded: true,
    isSipTrunkDeleted: false
};

export type SipTrunksActions = ActionType<typeof actions>;


export const sipTrunksReducer = createReducer<SipTrunksState, SipTrunksActions>(initialState)
    .handleAction(actions.getSipTrunksList.request, (state) => ({
        ...initialState,
        isLoading: true
    }))
    .handleAction(actions.getSipTrunksList.success, (state, action) => ({
        ...state,
        isLoading: false,
        sipTrunksList: {
            ...action.payload
        }
    }))
    .handleAction(actions.refreshSipTrunkStatus.request, (state, action) => {
        const sipTrunksList = [...state.sipTrunksList.items];
        const elementToChange = sipTrunksList.find(
            (v) => v.i_account === action.payload.i_account,
        );

        if (elementToChange) {
            elementToChange.fetchSipStatus = FetchDataStatus.InProgress;
        }

        return {
            ...state,
            extensionList: {...state.sipTrunksList, items: sipTrunksList},
        };
    })
    .handleAction(actions.refreshSipTrunkStatus.success, (state, action) => {
        const sipTrunksList = [...state.sipTrunksList.items];
        const elementToChange = sipTrunksList.find(
            (v) => v.i_account === action.payload.i_account,
        );

        if (elementToChange) {
            elementToChange.account_info = action.payload.account_info;
            elementToChange.fetchSipStatus = FetchDataStatus.Done;
        }

        return {
            ...state,
            extensionList: {...state.sipTrunksList, items: sipTrunksList},
        };
    })
    .handleAction(actions.clearErrorsCreateNewSipTrunkForm, (state) => {
        return {
            ...state,
            createNewSipTrunkErrors: undefined,
        };
    })
    .handleAction(actions.createSipTrunkDetailsData.request, (state) => ({
        ...state,
        createSipTrunkDataIsLoading: true,
    }))
    .handleAction(actions.createSipTrunkDetailsData.success, (state) => ({
        ...state,
        createSipTrunkDataIsLoading: false,
    }))
    .handleAction(actions.createNewSipTrunk.request, (state) => ({
        ...state,
        isCreatingNewSipTrunk: true,
    }))
    .handleAction(actions.createNewSipTrunk.success, (state) => ({
        ...state,
        isCreatingNewSipTrunk: false,
    }))
    .handleAction(actions.createNewSipTrunk.failure, (state, action) => ({
        ...state,
        isCreatingNewSipTrunk: false,
        createNewSipTrunkErrors: action.payload,
    }))
    .handleAction(actions.getSipTrunkBasicDetailsData.request, (state) => {
        return {
            ...state,
            sipTrunkDetails: {
                sipTrunkTabReducerApiError: undefined,
                sipTrunk: undefined,
                sipTrunkTab: {
                    isLoading: true
                },
                callScreeningTab: {
                    isLoading: true
                },
                callSettingsTab: {
                    isLoading: true
                },
                planTab: {
                    isLoading: true
                },
                callForwardingTab: {
                    isLoading: true
                }
            }
        };
    })
    .handleAction(actions.getSipTrunkBasicDetailsData.failure, (state, action) => ({
        ...state,
        isSipTrunkDetailsLoaded: false,
        sipTrunkDetails: {
            apiErrors: action.payload,
            sipTrunk: undefined,
            sipTrunkTab: {
                isLoading: false
            },
            callScreeningTab: {
                isLoading: false
            },
            callSettingsTab: {
                isLoading: false
            },
            planTab: {
                isLoading: false
            },
            callForwardingTab: {
                isLoading: false
            }
        }
    }))
    .handleAction(
        actions.getSipTrunkBasicDetailsData.success,
        (state, action) => {
            return {
                ...state,
                isSipTrunkDetailsLoaded: false,
                sipTrunkDetails: {
                    ...state.sipTrunkDetails,
                    sipTrunk: action.payload,
                }

            };
        },
    )
    // @ts-ignore
    .handleAction(actions.setSipTrunkStatus.success, (state, action) => {
        const newData = {
            ...state.sipTrunkDetails?.sipTrunk,
            account_info: {
                ...state.sipTrunkDetails?.sipTrunk?.account_info,
                ...action.payload.account_info,
            },
        };

        return {
            ...state,
            isLoading: false,
            sipTrunkDetails: {
                ...state.sipTrunkDetails,
                sipTrunk: newData
            }
        };
    })
    .handleAction(actions.getSipTrunkTabData.success, (state) => {

        return {
            ...state,
            sipTrunkDetails: {
                ...state.sipTrunkDetails,
                sipTrunkTab: {
                    isLoading: false,
                },
            }
        }

    })
    .handleAction(actions.getSipTrunkCallSettingsTabData.success, (state) => {

        return {
            ...state,
            sipTrunkDetails: {
                ...state.sipTrunkDetails,
                callSettingsTab: {
                    isLoading: false,
                },
            }
        }

    }).handleAction(actions.getSipTrunkPlanTabData.success, (state) => {

        return {
            ...state,
            sipTrunkDetails: {
                ...state.sipTrunkDetails,
                planTab: {
                    isLoading: false,
                },
            }
        }

    }).handleAction(actions.getSipTrunkCallForwardingTabData.success, (state) => {

        return {
            ...state,
            sipTrunkDetails: {
                ...state.sipTrunkDetails,
                callForwardingTab: {
                    isLoading: false,
                },
            }
        }

    }).handleAction(actions.getSipTrunkCallScreeningTabData.success, (state) => {

        return {
            ...state,
            sipTrunkDetails: {
                ...state.sipTrunkDetails,
                callScreeningTab: {
                    isLoading: false,
                },
            }
        }

    })
    .handleAction(actions.editSipTrunk.request, (state) => {
        return {
            ...state,
            sipTrunkDetails: {
                ...state.sipTrunkDetails,
                isFormSending: true
            }
        };
    }).handleAction(actions.editSipTrunk.success, (state) => {
        return {
            ...state,
            sipTrunkDetails: {
                ...state.sipTrunkDetails,
                isFormSending: false
            }
        };
    })
    .handleAction(actions.editSipTrunk.failure, (state, action) => {
        return {
            ...state,
            sipTrunkDetails: {
                ...state.sipTrunkDetails,
                isFormSending: false,
                apiErrors: action.payload
            }
        };
    })
    .handleAction(actions.getSipTrunkDynamicAddresses.request, (state) => {
        return {
            ...state,
            dynamicList: undefined
        };
    })
    .handleAction(actions.getSipTrunkDynamicAddresses.success, (state, action) => {
        return {
            ...state,
            dynamicList: action.payload.account_list
        };
    })
;


export default sipTrunksReducer;