import React from "react";
import {ReactComponent as OrangeIcon} from '../../assets/call_control_icons/orange.svg';
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";
import {useTranslation} from "react-i18next";
import CustomizedTooltip from "../Tooltip/Tooltip";
import {CallControlsProps} from "./callControls.utils";
import {useDispatch} from "react-redux";
import {actions} from "../../store";
import classNames from "classnames";
import {SipCallState} from "../../store/actions/ringgroups/payloads";


const useStyles = makeStyles(() => ({
    root: {
        '& path, & rect': {transition: 'fill .4s ease'},
        cursor: 'pointer',

    },

    icon: {
        '& > rect': {
            fill: Colors.White
        },

        '&:hover > rect': {
            fill: Colors.Primary2
        },
        '&:hover  path': {
            fill: Colors.White
        },
    },

    disabled: {
        '& > rect': {
            fill: 'transparent'
        },

        '& path': {
            fill: Colors.Gray6
        },
    }


}))


const PutOnHold: React.VFC<CallControlsProps> = ({activeCallEntity, callbackAfterOnClick,isReadOnly}) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const enableState = [SipCallState.Connected]
    const disabled = isReadOnly || enableState.find((item) => item == activeCallEntity.state) == undefined;


    const onClick = () => {

        dispatch(actions.holdCall.request({
            id: activeCallEntity.call.id,
            tag: activeCallEntity.call.tag,
            callback: callbackAfterOnClick ? ()=>{setTimeout(()=>{callbackAfterOnClick()},500)} : undefined
        }))
    }

    return (
        activeCallEntity.state == SipCallState.Holding ? <></> :
            <CustomizedTooltip
                title={t('screens:calls.onHold')}
                copy={false}
                disableHoverListener={disabled}

            >
                <OrangeIcon
                    className={classNames(classes.root, disabled ? classes.disabled : classes.icon)}
                    onClick={!disabled ? onClick : undefined}
                />
            </CustomizedTooltip>
    )
}

export default PutOnHold;