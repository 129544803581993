import React, {useMemo} from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';
import classNames from 'classnames';
import AnimatedNumberText from '../../../components/AnimatedNumber/AnimatedNumber';

export type CallStatisticTileProps = {
    value: string,
    textLine1: string,
    textLine2: string,
    isLoading: boolean,
    valueClass?: string,
    dataTestId?: string;
    padValue?: boolean;
};

export const useStyles = makeStyles(() => ({
    container: {
        width: 212,
        height: 82,
        padding: '32px 0px',
        display: 'flex',
        flexDirection: 'column'
    },
    valueLabelTopDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    valueLabel: {
        width: '100%',
        height: 46,
        fontWeight: 300,
        fontSize: 28,
        lineHeight: '38.28px',

        "& hr": {
            marginBlockStart: 'unset',
            marginBlockEnd: 'unset',
            marginInlineStart: 'unset',
            marginInlineEnd: 'unset',
            height: 2,
            marginTop: 5
        },

        '& span': {
            fontWeight: 300,
            fontSize: 28,
            height: 46,
            margin: 0,
            padding: 0
        }
    },
    valueLabelBottomDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: 46,
        
        '& span': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        }
    },
    containerLoading: {
        marginTop: -18
    },
    valueLabelPercentage: {
        fontSize: '21px !important',
        marginTop: 3
    },
    textLabel1: {
        width: '100%',
        height: 16,
        verticalAlign: 'middle',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px',
        color: Colors.Black,
        marginTop: 2,
        opacity: 0.6
    },
    textLabel2: {
        width: '100%',
        height: 16,
        verticalAlign: 'middle',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '14px',
        color: Colors.Black,
        marginTop: 2,
        opacity: 0.5
    }
}));

export const CallStatisticTile: React.VFC<CallStatisticTileProps> = ({
    value,
    textLine1,
    textLine2,
    isLoading,
    valueClass,
    dataTestId,
    padValue = true
}) => {
    const classes = useStyles();

    const isDecimalPercentValue = useMemo(() => {
        return (value || '').split(/,|\.|:/).length >= 2 || value.endsWith('%');
    }, [value]);

    return (<div className={classNames(classes.container)} data-testid={dataTestId}>
        <div className={classNames(classes.valueLabel, valueClass)}>
            {
                isDecimalPercentValue 
                    ? (<div className={classes.valueLabelTopDiv}>{
                        (value || '').split(/,|\.|:/).filter(e => e && e.length).map((v, indx) => {
                            if(v.endsWith('%')) {
                                return (<div key={'splitted_index_' + indx} className={classNames(classes.valueLabelPercentage, 
                                    valueClass, 
                                    isLoading && classes.containerLoading,
                                    classes.valueLabelBottomDiv)}
                                >
                                    {isLoading
                                        ? '' 
                                        : v.startsWith('0')
                                            ? v.substring(1)
                                            : ('.' + v)
                                    }
                                </div>);
                            } else {
                                return (<div key={'splitted_index_container_'+indx} className={classes.valueLabelTopDiv}>
                                    {(value.indexOf(':') !== -1) && indx > 0 && (
                                        <div key={'splitted_index_sub2_' + indx} className={classes.valueLabelBottomDiv} style={{marginTop: -2}}>
                                            :
                                        </div>
                                    )}
                                    <AnimatedNumberText
                                        className={classNames(valueClass, isLoading && classes.containerLoading, classes.valueLabelBottomDiv)}
                                        hideThousands
                                        value={parseFloat(v)}
                                        dataTestId="animated-number-value"
                                        dataQa="animated-number-value"
                                        showLongDash={isLoading}
                                        formatValue={(v) => padValue ? v.toFixed(0).padStart(2, '0') : v.toFixed(0)}
                                        key={'splitted_index_sub3_' + indx}
                                    />
                                </div>);
                            }
                        })
                    }</div>)
                    : (<div className={classNames(classes.valueLabel, classes.valueLabelTopDiv, valueClass)}>
                        <AnimatedNumberText
                            className={classNames(
                                isLoading && classes.containerLoading,
                                classes.valueLabelBottomDiv,
                                !isLoading && valueClass
                            )}
                            hideThousands
                            value={parseFloat(value)}
                            dataTestId="animated-number-value"
                            dataQa="animated-number-value"
                            showLongDash={isLoading}
                        />
                    </div>)
            }
        </div>
        <div className={classes.textLabel1}>
            {textLine1}
        </div>
        <div className={classes.textLabel2}>
            {textLine2}
        </div>
    </div>);
};