import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import {Colors} from '../../styles/Colors';
import Button from '../Button/Button';
import classNames from 'classnames';
import {usePermissionContext} from '../../hooks/usePermissions';
import {PermissionType} from '../../store/types/Permission';
import PermissionProvider from '../PermissionProvider/PermissionProvider';

type FormActionsProps = {
    isSaveActive?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
    onDelete?: () => void;
    deleteTitle?: string;
    className?: string;
    deleteColor?: string;
    hideCancel?: boolean;
    hideDelete?: boolean;
    deleteButtonDisabled?: boolean;
    deletePermission?: string[];
    editPermission?: string[];
    customizeDeletePermission?: (permissionValue: PermissionType) => PermissionType;
};

const useStyles = makeStyles(() => ({
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: Colors.White,
        margin: '0 !important',
        padding: '0 24px',
        height: 68,
        alignItems: 'center',
        maxWidth: 990,
        position: 'absolute',
        top: 'calc(100vh - 86px)',
        left: 90,
        zIndex: 10,
        right: 90,
        borderRadius: 8,
        boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.25)`,
    },

    '@media (max-width: 1276px)': {
        buttonsContainer: {
            left: 24,
            right: 24,
        },
    },

    '@media (min-width:1277px) and (max-width: 1440px)': {
        buttonsContainer: {
            left: 90,
            right: 90,
        },
    },

    '@media (min-width: 1441px)': {
        buttonsContainer: {
            left: 96,
            right: 96,
        },
    },

    insideButtons: {
        display: 'flex',
    },
    saveButton: {
        width: 96,
        color: Colors.White
    },
    cancelButton: {
        marginRight: 32,
        width: 96,
        '& .MuiButton-label': {
            color: Colors.Primary + '!important',
        },
        '&:hover': {
            backgroundColor: Colors.Primary1 + '!important',
        },
    },
}));

const FormActions: React.FC<FormActionsProps> = (
    {
        isSaveActive = true,
        onSave,
        onCancel,
        onDelete,
        deleteTitle,
        className,
        hideCancel,
        deleteButtonDisabled,
        deletePermission,
        editPermission,
        customizeDeletePermission,
        hideDelete = false
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const permission = usePermissionContext();

    if (permission == PermissionType.Hidden) {
        return null;
    }

    return (
        <div className={classNames(classes.buttonsContainer, className)}>
            {!!deleteTitle && !hideDelete ? (
                <PermissionProvider permission={deletePermission || []} customizePermission={customizeDeletePermission}>
                    <Button
                        dataQa="extension-delete-button"
                        onClick={onDelete}
                        ternary
                        icon={<Delete color="error" htmlColor={Colors.Gray5}/>}
                        disabled={deleteButtonDisabled}
                    >
                        {deleteTitle}
                    </Button>
                </PermissionProvider>
            ) : (
                <div/>
            )}
            <div
                className={classes.insideButtons}
                data-testid={'extension-action-buttons'}
            >
                {!hideCancel && (
                    <Button
                        dataQa="extension-cancel-button"
                        onClick={onCancel}
                        className={classes.cancelButton}
                    >
                        {t('common:cancel')}
                    </Button>
                )}
                <PermissionProvider permission={editPermission || []}>
                    <Button
                        dataQa="extension-save-button"
                        onClick={onSave}
                        primary
                        className={classes.saveButton}
                        disabled={!isSaveActive}
                        dataTestId={'form-save-button'}
                    >
                        {t('common:save')}
                    </Button>
                </PermissionProvider>

            </div>
        </div>
    );
};

export default FormActions;
