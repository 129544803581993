import axios, { AxiosResponse } from 'axios';
import { select, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { actions } from '../../..';
import { Voicemail } from '../../../../services/endpoints';
import JSONFormData from '../../../../utils/JSONFormData';
import {
    defaultGreetingsFilesNames,
    VoicemailGreeting,
    VoicemailSettings,
} from '../../../types/Voicemail';
import Config from '../../../../config.json';
import { EditExtensionForm } from '../../../../views/Extensions/ExtensionDetails/utils';
import {
    CallWaitingFlag,
    MusicOnHoldFlag,
    ServiceFeature,
    ServiceFeatureName,
} from '../../../types/ServiceFeature';
import { editServiceFeatures } from '../serviceFeatures/saga';
import { YesNo } from '../../../types/CallScreening';
import { MohInfo } from '../../../types/AccountMoh';
import { ReduxState } from '../../../types';
import { CallRecordingFormType } from '../../../../components/Forms/Extensions/CallRecording/CallRecordingForm.utils';

export function* getExtensionVoicemailSettings(
    action: ActionType<typeof actions.getExtensionVoicemailSettings.request>,
) {
    try {
        const { access_token, session_id, csrf_token } = yield select(
            (state) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);
        body.delete('auth_info');
        body.append(
            'auth_info',
            JSON.stringify({
                login: action.payload.accountId,
                domain: action.payload.domain,
            }),
        );
        const res: AxiosResponse<{
            vm_settings: VoicemailSettings;
        }> = yield axios.post(Voicemail.GetVmSettings, body, {
            baseURL: Config.SIP_API_URL,
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });

        yield put(
            actions.getExtensionVoicemailSettings.success(res.data.vm_settings),
        );
    } catch (err) {
        yield put(actions.getExtensionVoicemailSettings.failure());
    }
}

export function* getGreetingsFilesNames(
    action: ActionType<typeof actions.getGreetingsFilesNames.request>,
) {
    try {
        const { access_token, session_id, csrf_token } = yield select(
            (state) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);
        body.delete('auth_info');
        body.append(
            'auth_info',
            JSON.stringify({
                login: action.payload.accountId,
                domain: action.payload.domain,
            }),
        );
        const greetings: { [key in VoicemailGreeting]?: string } = {};
        for (const greetingName of Object.values(VoicemailGreeting)) {
            body.setParams({ greeting_type: greetingName });
            const greetingFileResponse: AxiosResponse<{
                filename: string;
            }> = yield axios.post(Voicemail.GetVmGreeting, body, {
                baseURL: Config.SIP_API_URL,
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            greetings[greetingName] = greetingFileResponse.data.filename
                ? ''
                : defaultGreetingsFilesNames[greetingName];
        }

        yield put(actions.getGreetingsFilesNames.success(greetings));
    } catch (err) {
        yield put(actions.getGreetingsFilesNames.failure());
    }
}

export function* editCallRecording(
    data: CallRecordingFormType,
    action:
        | ActionType<typeof actions.editExtension.request>
        | ActionType<typeof actions.editAutoAttendant.request>,
) {
    const serviceFeaturesToEdit: ServiceFeature[] = [];

    const callRecordingParams = [
        'callRecordingStatus',
        'outgoingStatus',
        'incomingStatus',
        'redirectedStatus',
        'playCallRecordingAnnouncementToAllStatus',
        'sendTheCallRecordingViaEmailStatus',
        'allowToStartStopRecordingManually',
    ];

    const getCallRecordingValue = (paramName: keyof CallRecordingFormType) => {
        // @ts-ignore
        return data[paramName] ?? action.payload.initialValues[paramName];
    };

    if (
        !callRecordingParams.every(
            (v) => data[v as keyof CallRecordingFormType] === undefined,
        )
    ) {
        const flagValue = getCallRecordingValue('callRecordingStatus')
            ? YesNo.Yes
            : YesNo.No;

        serviceFeaturesToEdit.push({
            name: ServiceFeatureName.CallRecording,
            effective_flag_value: flagValue,
            flag_value: flagValue,
            attributes: [
                {
                    name: 'call_recording_out',
                    values: [
                        getCallRecordingValue('outgoingStatus') ? '1' : '0',
                    ],
                    effective_values: [],
                },
                {
                    name: 'call_recording_in',
                    values: [
                        getCallRecordingValue('incomingStatus') ? '2' : '0',
                    ],
                    effective_values: [],
                },
                {
                    name: 'call_recording_redirect',
                    values: [
                        getCallRecordingValue('redirectedStatus') ? '4' : '0',
                    ],
                    effective_values: [],
                },
                {
                    name: 'audible_notification',
                    values: [
                        getCallRecordingValue(
                            'playCallRecordingAnnouncementToAllStatus',
                        )
                            ? YesNo.Yes
                            : YesNo.No,
                    ],
                    effective_values: [],
                },
                {
                    name: 'mail_recording',
                    values: [
                        getCallRecordingValue(
                            'sendTheCallRecordingViaEmailStatus',
                        )
                            ? YesNo.Yes
                            : YesNo.No,
                    ],
                    effective_values: [],
                },
                {
                    name: 'on_demand',
                    values: [
                        getCallRecordingValue(
                            'allowToStartStopRecordingManually',
                        )
                            ? YesNo.Yes
                            : YesNo.No,
                    ],
                    effective_values: [],
                },
            ],
        });
    }

    if (serviceFeaturesToEdit.length > 0) {
        yield editServiceFeatures(
            action.payload.accountId,
            serviceFeaturesToEdit,
        );
    }
}

export function* editCallSettingsServiceFeatures(
    data: Partial<EditExtensionForm>,
    action: ActionType<typeof actions.editExtension.request>,
) {
    yield editCallRecording(data as CallRecordingFormType, action);

    const serviceFeaturesToEdit: ServiceFeature[] = [];

    if (
        data.defaultAnsweringMode !== undefined ||
        data.defaultAnsweringTimeout !== undefined
    ) {
        const flagValue =
            data.defaultAnsweringMode ??
            action.payload.initialValues.defaultAnsweringMode;

        const timeoutValue =
            data.defaultAnsweringTimeout ??
            action.payload.initialValues.defaultAnsweringTimeout;

        serviceFeaturesToEdit.push({
            name: ServiceFeatureName.DefaultAction,
            effective_flag_value: flagValue,
            flag_value: flagValue,
            attributes: [
                {
                    name: 'timeout',
                    effective_values: [timeoutValue],
                    values: [timeoutValue],
                },
            ],
        });
    }

    if (data.unifiedMessagingStatus !== undefined) {
        const flagValue = data.unifiedMessagingStatus ? YesNo.Yes : YesNo.No;

        serviceFeaturesToEdit.push({
            name: ServiceFeatureName.UnifiedMessaging,
            effective_flag_value: flagValue,
            flag_value: flagValue,
            attributes: [],
        });
    }

    if (data.callWaitingStatus !== undefined) {
        const flagValue = data.callWaitingStatus ? YesNo.Yes : YesNo.No;

        serviceFeaturesToEdit.push({
            name: ServiceFeatureName.CallWaiting,
            effective_flag_value: flagValue,
            flag_value: flagValue,
            attributes: [],
        });
    }
    
    if (data.pinProtectionStatus !== undefined || data.serviceUnblockCode) {
        const flagValue = data.pinProtectionStatus
            ? YesNo.Yes
            : (data.serviceUnblockCode?.length
                ? YesNo.Yes
                : YesNo.No);

        serviceFeaturesToEdit.push({
            name: ServiceFeatureName.VoiceAuthentication,
            effective_flag_value: flagValue,
            flag_value: flagValue,
            attributes: [
                {
                    effective_values: [flagValue === YesNo.Yes ? data.serviceUnblockCode : null],
                    values: [flagValue === YesNo.Yes ? data.serviceUnblockCode : null],
                    name: 'pin'
                }
            ],
        });
    }

    if (data.extToExtCallDisctintiveRingStatus !== undefined) {
        const flagValue = data.extToExtCallDisctintiveRingStatus
            ? YesNo.Yes
            : YesNo.No;

        serviceFeaturesToEdit.push({
            name: ServiceFeatureName.DistinctiveRingVPN,
            effective_flag_value: flagValue,
            flag_value: flagValue,
            attributes: [],
        });
    }

    if (data.onHoldMusicStatus !== undefined || data.onHoldMusicName) {
        const onHoldMusicStatus =
            data.onHoldMusicStatus ??
            action.payload.initialValues.onHoldMusicStatus;

        const selectedMusicName =
            data.onHoldMusicName ||
            action.payload.initialValues.onHoldMusicName;

        const mohItems: MohInfo[] | undefined = yield select(
            (state: ReduxState) => state.extensions.mohDetails?.items,
        );

        const selectedMusicIndex =
            mohItems
                ?.find((v) => v.name === selectedMusicName)
                ?.i_moh?.toString() || '0';

        serviceFeaturesToEdit.push({
            name: ServiceFeatureName.MusicOnHold,
            flag_value: onHoldMusicStatus
                ? MusicOnHoldFlag.Enabled
                : MusicOnHoldFlag.Disabled,
            effective_flag_value: onHoldMusicStatus
                ? MusicOnHoldFlag.Enabled
                : MusicOnHoldFlag.Disabled,
            attributes: [
                {
                    effective_values: [selectedMusicIndex],
                    name: 'i_moh',
                    values: [selectedMusicIndex],
                },
            ],
        });
    }

    if (serviceFeaturesToEdit.length > 0) {
        yield editServiceFeatures(
            action.payload.accountId,
            serviceFeaturesToEdit,
        );
    }
}

export function* editVoicemailSettings(
    data: Partial<EditExtensionForm>,
    action: ActionType<typeof actions.editExtension.request>,
) {
    const { session_id, access_token, csrf_token } = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    if (data.unifiedMessagingStatus !== undefined) {
        const flagValue = data.unifiedMessagingStatus
            ? CallWaitingFlag.Enabled
            : CallWaitingFlag.Disabled;

        yield editServiceFeatures(action.payload.accountId, [
            {
                name: ServiceFeatureName.UnifiedMessaging,
                effective_flag_value: flagValue,
                flag_value: flagValue,
                attributes: [],
            },
        ]);

        return;
    }

    const valuesToSave: Partial<VoicemailSettings> = {};

    if (data.autoPlayStatus !== undefined) {
        valuesToSave.auto_play = data.autoPlayStatus ? 'yes' : 'no';
    }
    if (data.announceDateStatus !== undefined) {
        valuesToSave.announce_dt = data.announceDateStatus ? 'yes' : 'no';
    }
    if (data.requirePinStatus !== undefined) {
        valuesToSave.password_ask = data.requirePinStatus ? 'yes' : 'no';
    }
    if (data.pin !== undefined) {
        valuesToSave.password = data.pin;
    }
    if (data.unifiedMessagingEmailAddress !== undefined) {
        valuesToSave.ext_email = data.unifiedMessagingEmailAddress;
    }
    if (data.fileFormat) {
        valuesToSave.ext_email_vm_fmt = data.fileFormat;
    }
    if (data.emailOption) {
        valuesToSave.ext_email_action = data.emailOption;
    }

    if (data.greetingType) {
        valuesToSave.greetings = data.greetingType;
    }

    body.delete('auth_info');

    //@ts-ignore
    const extensionNumber = data?.extensionNumber || data?.sipTrunk;
    //@ts-ignore
    if(data?.sipTrunk) {
        //@ts-ignore
        action.payload.id = data?.sipTrunk;
    }
    body.append(
        'auth_info',
        JSON.stringify({
            login: (extensionNumber && action.payload.id && action.payload.id.indexOf('x') >= 0)
                ? action.payload.id.substring(0, action.payload.id.indexOf('x') + 1) + extensionNumber 
                : action.payload.id,
            domain: action.payload.domain,
        }),
    );

    if (Object.keys(valuesToSave).length) {
        body.setParams({
            vm_settings: valuesToSave,
        });

        yield axios.post(Voicemail.SetVmSettings, body, {
            baseURL: Config.SIP_API_URL,
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
    }

    if (data.greetingFile) {
        const greetingType =
            data.greetingType || action.payload.initialValues.greetingType;

        body.setParams({
            greeting_info: {
                greeting_type: greetingType,
                filename: data.greetingFileName,
            },
        });
        body.append('upload_file', data.greetingFile);

        yield axios.post('/Voicemail/set_vm_greeting', body, {
            baseURL: Config.SIP_API_URL,
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
    }
}
