import React, {useCallback, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import SelectField from '../SelectField/SelectField';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import * as actions from '../../store/actions';
import {UAInfo, UAPortConfiguration} from '../../store/types/Devices';
import StatusIcon, {Statuses} from './StatusIcon';
import {Colors} from '../../styles/Colors';
import i18next from 'i18next';
import TextFieldWithMask from '../TextFieldWithMAsk/TextFieldWithMask';
import MacMask from '../TextFieldWithMAsk/Masks/MacMask';
import {getStatusNumber} from '../../views/Devices/DevicesList.utils';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            flex: 1,
            height: 'auto',
        },
    },
    select: {
        flex: 1,
    },

    disabledContainer: {

        '&  .MuiInput-underline::before':{
            borderBottom: `1px dashed ${Colors.LightGraySecondary}`,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before':{
            borderBottom: `1px dashed ${Colors.LightGraySecondary}`,
        }

    },
    selectMac: {
        flex: 1,
    },

    customOptionContainer: {
        alignItems: 'center',
        width: '100%',
    },

    details: {
        fontSize: 14,
        color: Colors.Gray,
        float: 'right',
    },
}));

interface AssignDeviceProps {
    refresh: boolean;
    device?: UAInfo | null;
    port?: UAPortConfiguration | null;
    onDeviceChange: (device: UAInfo | null) => void;
    onPortChange: (port: UAPortConfiguration | null) => void;
    errors?: { port?: string }
    callback?: () => void,
    readOnly?:boolean
}

const getStatus = (status: Statuses): string | undefined => {
    switch (status) {
        case Statuses.On:
            return i18next.t('screens:devices.hasFreeLines');
        case Statuses.Off:
            return i18next.t('screens:devices.noFreeLines');
        case Statuses.Hold:
            return i18next.t('screens:devices.notUsed');
    }
};

const AssignDevice: React.VFC<AssignDeviceProps> = (
    {
        refresh,
        device,
        port,
        onDeviceChange,
        onPortChange,
        errors,
        callback,
        readOnly
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const items = useSelector((state: ReduxState) => state.devices?.items);

    const [macList, setMacList] = useState<UAInfo[] | null>(null);
    const [deviceList, setDeviceList] = useState<UAInfo[] | null>(null);
    const [
        selectedPort,
        setSelectedPort,
    ] = useState<UAPortConfiguration | null>(port ?? null);
    const [selectedDevice, setSelectedDevice] = useState<UAInfo | null>(null);

    useEffect(() => {
        if (refresh) {
            callback?.();
            dispatch(
                actions.devicesList.request({
                    limit: 1000,
                    offset: 0,
                    with_available_ports: 1,
                    with_busy_ports: 1,
                    get_total: 1,
                    callback: callback
                }),
            );
        }
    }, [refresh]);

    useEffect(() => {
        if (device && port) {
            setSelectedDevice(device);
            setSelectedPort(port);
        }
    }, [device, port]);

    useEffect(() => {
        if (items) {
            setDeviceList(items);
            setMacList(items);
        }

    }, [items]);

    useEffect(() => {
        if (
            selectedDevice &&
            selectedDevice.ports_config &&
            (selectedDevice?.available_ports?.length ?? 0) > 0 &&
            !selectedPort
        ) {
            const newValue =
                selectedDevice.ports_config.filter((item) =>
                    selectedDevice.available_ports
                        ? item.port == selectedDevice.available_ports[0]
                        : false,
                )[0] || null;
            onPortChange(newValue);
            setSelectedPort(newValue);
        }
    }, [selectedDevice, selectedPort]);

    const renderOption = useCallback((option: UAInfo) => {
        const status = getStatusNumber(option);
        return (
            <div className={classes.customOptionContainer}>
                <StatusIcon state={status}/>
                <span>{option.name}</span>
                <span className={classes.details}>{getStatus(status)}</span>
            </div>
        );
    }, []);

    const filterOptions = useCallback((options: any[], state) => {
        let value = state.inputValue.substr(0, state.inputValue.indexOf('x'));

        if (value[value.length - 1] === ':') {
            value = value.substr(0, value.length - 1);
        }
        return options.filter((v) => v.mac.includes(value.toUpperCase()));
    }, []);

    const renderPortOption = useCallback((option: UAPortConfiguration) => {
        const used = !!option.extension_id || !!option.account_id;
        return (
            <span>
                {!used
                    ? t('screens:devices.portNumFreeLines', {
                        port: option.port,
                    })
                    : option.extension_id
                        ? `${option.port}. ${option.extension_id} ${
                            option.extension_name
                                ? `- ${option.extension_name}`
                                : ''
                        }`
                        : `${option.port}. ${t('common:reservedForId', {
                            id: option.account_id,
                        })}`}
            </span>
        );
    }, []);

    const resetData = (value: UAInfo | null) => {
        if (value) {
            setMacList([value]);
            setDeviceList([value]);
        } else {
            setMacList(items || []);
            setDeviceList(items || []);
        }

        setSelectedPort(null);
    };

    const setPort = (value: UAPortConfiguration | null) => {
        value ? setSelectedPort(value) : setSelectedPort(null);
    };

    const renderCustomInput = useCallback(
        (params, props) => (
            <TextFieldWithMask
                {...params}
                label={props?.label}
                placeholder={props?.placeholder}
                onChange={(e) => {
                    e.target.value = e.target.value.substr(
                        0,
                        e.target.value.indexOf('x'),
                    );

                    if (e.target.value[e.target.value.length - 1] === ':') {
                        e.target.value = e.target.value.substr(
                            0,
                            e.target.value.length - 1,
                        );

                        const pointer = e.target.selectionStart;
                        const element = e.target;

                        window.requestAnimationFrame(() => {
                            element.selectionStart = pointer;
                            element.selectionEnd = pointer;
                        });
                    }
                    props?.onTextChange(e);
                }}
                className={readOnly ? classes.disabledContainer : undefined}
                mask={MacMask}
            />
        ),
        [],
    );

    const onTextChange = (e: React.ChangeEvent<any>) => {
        const newList =
            macList?.filter((i) =>
                i.mac.includes(e.target.value.toUpperCase()),
            ) || [];

        setDeviceList(newList);
    };

    return (
        <Grid className={classes.container}>
            <>
                <SelectField
                    items={macList || []}
                    label={t('screens:devices.macAddress')}
                    getOptionLabel={(o: UAInfo) => o?.mac || ''}
                    value={selectedDevice}
                    classes={{container: classes.selectMac}}
                    onChange={(_, v) => {
                        setSelectedDevice(v);
                        onDeviceChange(v);
                        resetData(v);
                    }}
                    getOptionSelected={(o: UAInfo) =>
                        o.i_ua == selectedDevice?.i_ua
                    }
                    onTextChange={onTextChange}
                    customTextField={renderCustomInput}
                    filterOptions={filterOptions}
                    disableAutoSettingValue
                    disabled={readOnly}
                    className={readOnly ? classes.disabledContainer : undefined}
                />

                <SelectField
                    items={deviceList || []}
                    label={t('screens:devices.deviceName')}
                    value={selectedDevice}
                    classes={{container: classes.select}}
                    getOptionLabel={(o: UAInfo) => o?.name || ''}
                    renderOption={renderOption}
                    onChange={(_, v) => {
                        setSelectedDevice(v);
                        onDeviceChange(v);
                        resetData(v);
                    }}
                    getOptionSelected={(o: UAInfo) =>
                        o.i_ua == selectedDevice?.i_ua
                    }
                    onTextChange={(e) => {
                        setMacList(
                            items?.filter((i) =>
                                i.mac.includes(e.target.value),
                            ) || [],
                        );
                    }}
                    disableAutoSettingValue
                    disabled={readOnly}
                />

                <SelectField
                    items={selectedDevice?.ports_config || []}
                    label={t('screens:devices.linePort')}
                    value={selectedPort}
                    getOptionLabel={(p: UAPortConfiguration) =>
                        p.port?.toString() + '.'
                    }
                    classes={{container: classes.select}}
                    getOptionDisabled={(p: UAPortConfiguration) =>
                        !!p.extension_id || !!p.account_id
                    }
                    getOptionSelected={(o: UAPortConfiguration) =>
                        o.port == selectedPort?.port
                    }
                    renderOption={renderPortOption}
                    onChange={(_, v) => {
                        onPortChange(v);
                        setPort(v);
                    }}
                    disabled={!selectedDevice || readOnly}
                    required={!!selectedDevice}
                    helperText={errors?.port}
                    disableAutoSettingValue
                />
            </>
        </Grid>
    );
};

export default AssignDevice;
