import React, {useEffect, useMemo, useState} from 'react';
import {Grid} from '@material-ui/core';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import DialogContainer, {DialogButton} from '../AlertDialog/DialogContainer';

import CustomKeyboardDateTimePicker from '../KeyboardDateTimePicker/KeyboardDateTimePicker';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../store';
import dayjs from '../../services/customDayJs';
import {ReduxState} from '../../store/types';
import {CallHistory} from '../../store/types/CallHistory';
import {convertUserLocalTimeToUtc} from '../../utils/dateWithTimezoneConversion';
import DataGrid from '../DataGrid/DataGrid';
import {PaginationMode} from '../DataGrid/types';
import classNames from 'classnames';
import toast from 'react-hot-toast';
import {
    CallHistoryFormProps,
    dateFormat,
    CallHistoryDialogProps,
    dateFromDateTillValidationSchema,
    useStyles,
    generateColumns,
} from './CallHistoryDialog.utils';

const CallHistoryDialog: React.VFC<CallHistoryDialogProps> = (
    {
        isOpen,
        toggleVisibility,
        accountId,
        config,
        customHeader,
        downloadRecordingPermission,
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [isDownloadingGoingOn, setIsDownloadingGoingOn] = useState<boolean[]>(
        [],
    );

    const toggleTooltipVisibility = (index: number) => {
        const newDownloading = [...isDownloadingGoingOn];
        newDownloading[index] = false;
        setIsDownloadingGoingOn(newDownloading);
    };

    const dispatch = useDispatch();

    const {items, total} = useSelector<ReduxState,
        { items: CallHistory[]; total: number }>((state) => state.calls.extensionCallHistory);

    const customerCurrency = useSelector<ReduxState, string>(
        (state) => state.calls.customerCurrency || '',
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const isLoading = useSelector<ReduxState, boolean>(
        (state) => state.calls.extensionCallHistory.isLoading,
    );

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    const initialValues = useMemo(
        () => ({
            from: dayjs
                .utc()
                .utcOffset(timezoneOffset / 60)
                .subtract(1, 'month')
                .format(dateFormat),
            till: dayjs
                .utc()
                .utcOffset(timezoneOffset / 60)
                .format(dateFormat),
            cli: '',
            cld: '',
        }),
        [],
    );

    const {
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        resetForm,
        errors,
        setFieldError,
    } = useFormik<CallHistoryFormProps>({
        initialValues,
        onSubmit: (form) => {
            getCallHistory(form, false);
        },
        validationSchema: dateFromDateTillValidationSchema(timezoneOffset, userDateTimeFormat),
        enableReinitialize: true,
        validateOnChange: false,
    });

    useEffect(() => {
        getCallHistory(values, true);
    }, [accountId]);

    const getCallHistory = (
        formData: CallHistoryFormProps,
        withInitCalls?: boolean,
        limit?: number,
        offset?: number,
    ) => {
        if (accountId) {
            dispatch(
                actions.getExtensionCallHistory.request({
                    i_account: accountId,
                    limit: limit || 3,
                    offset: offset || 0,
                    ...formData,
                    cli: formData.cli ? `${formData.cli}%` : undefined,
                    cld: formData.cld ? `${formData.cld}%` : undefined,
                    from: convertUserLocalTimeToUtc(
                        formData.from,
                        timezoneOffset,
                    ),
                    till: convertUserLocalTimeToUtc(
                        formData.till,
                        timezoneOffset,
                    ),
                    withInitCalls,
                }),
            );
        }
    };

    const downloadFile = (
        i_xdr: number,
        call_recording_id: string,
        fileName: string,
        index: number,
    ) => {
        const newDownloadings = [...isDownloadingGoingOn];
        newDownloadings[index] = true;
        setIsDownloadingGoingOn(newDownloadings);
        toast(t<string>('tooltips:ringGroups.downloadWillStartShortly'));
        dispatch(
            actions.getExtensionCallHistoryFile.request({
                i_xdr,
                call_recording_id,
                fileName,
                callback: () => toggleTooltipVisibility(index),
            }),
        );
    };

    const columns = generateColumns(
        t,
        classes,
        timezoneOffset,
        customerCurrency,
        downloadFile,
        userDateTimeFormat,
        isDownloadingGoingOn,
        downloadRecordingPermission,
    );

    const data = items?.map((v) => ({id: v.i_xdr, ...v}));

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="call-history-dialog"
            header={
                customHeader
                    ? customHeader
                    : t('screens:extensions.callActivity', {
                        extensionNumber: config?.id,
                        name:
                            config?.name ||
                            t('screens:autoAttendants.unnamed'),
                    })
            }
            headerClass={classes.header}
            className={classes.modalContainer}
            dialogActionsButtons={[
                <DialogButton
                    className={classes.primaryActionButton}
                    key="close"
                    label={t('common:close')}
                    onClick={() => {
                        resetForm();
                        toggleVisibility?.();
                    }}
                />,
            ]}
        >
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                data-testid="call-history-form"
            >
                <Grid
                    item
                    className={classNames(
                        classes.itemsContainer,
                        classes.filterSeparation,
                    )}
                >
                    <CustomKeyboardDateTimePicker
                        id="from"
                        label={t('common:fromDate')}
                        value={values.from}
                        onChange={(v) => setFieldValue('from', v)}
                        dataQa={'call-history-filter-from'}
                        dataTestId={'call-history-filter-from'}
                        userDateTimeFormat={userDateTimeFormat}
                        handleSubmit={handleSubmit}
                        helperText={errors.from}
                        setFieldError={setFieldError}
                    />
                    <CustomKeyboardDateTimePicker
                        id="till"
                        label={t('common:toDate')}
                        value={values.till}
                        onChange={(v) => setFieldValue('till', v)}
                        dataQa={'call-history-filter-till'}
                        dataTestId={'call-history-filter-till'}
                        userDateTimeFormat={userDateTimeFormat}
                        handleSubmit={handleSubmit}
                        helperText={errors.till}
                        setFieldError={setFieldError}
                    />
                    <TextField
                        id="cli"
                        label={t('screens:calls.callingNumber')}
                        onChange={handleChange}
                        value={values.cli}
                        dataQa={'call-history-filter-calling-number'}
                        handleSubmit={handleSubmit}
                    />
                    <TextField
                        id="cld"
                        label={t('screens:calls.calledNumber')}
                        onChange={handleChange}
                        value={values.cld}
                        dataQa={'call-history-filter-called-number'}
                        handleSubmit={handleSubmit}
                    />
                    <Button
                        primary
                        accent
                        dataQa="call-history-search"
                        className={classes.button}
                        onClick={() => handleSubmit()}
                    >
                        {t('common:search')}
                    </Button>
                </Grid>
            </form>

            <DataGrid
                className={classes.table}
                classes={{pagination: classes.pagination}}
                columns={columns}
                data={data}
                rowCount={total || 0}
                loading={isLoading}
                onPageChange={(v) =>
                    getCallHistory(
                        values,
                        false,
                        v.pageSize,
                        v.pageSize * v.page,
                    )
                }
                onPageSizeChange={(v) =>
                    getCallHistory(values, false, v.pageSize, 0)
                }
                paginationMode={PaginationMode.Server}
                centeredRows
                pageSizeOptions={[3, 5, 10]}
                initialPageSize={3}
                narrowRows
            />
        </DialogContainer>
    );
};

export default CallHistoryDialog;
