import {
    CallingPartyDisplay,
    FollowMeSequence,
    TransportProtocol,
} from '../../../../store/types/AccountFollowMeSettings';
import { ForwardModeFlag } from '../../../../store/types/ServiceFeature';
import * as Yup from 'yup';
import i18n from '../../../../services/i18n';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../styles/Colors';
import {
    followRuleValidationSchema,
    followRuleValidationSchemaWithoutSIP,
    followRuleWithoutDescriptionSchema, numberNotReq,
} from './FollowRuleForm.utils';
import { ChangeDirtyStateDetailsTab } from '../../../../views/Extensions/ExtensionDetails/utils';
import {intervalFormInitialValues, IntervalFormProps} from "../../../IntervalSelect/IntervalSelect.utils";

export interface CallForwardingRule {
    id: string;
    weight?: string;
    description: string;
    number: string;
    intervals?: IntervalFormProps;
    ringFor: string;
    enabled: boolean;
    sipProxy?: string;
    keepOriginalCld: boolean;
    transportProtocol: TransportProtocol;
    callingPartyDisplay: CallingPartyDisplay;
}

export type ExtensionCallForwardingFormType = {
    callForwardingStatus: boolean;
    callForwardingLocked: boolean;
    callForwardingMethod: ForwardModeFlag;
    callForwardingRuleOrder: FollowMeSequence;
    callForwardingRules: CallForwardingRule[];
    callForwardingDeletedRules: CallForwardingRule[];
    setDefaultFollowMeSequence:boolean;
};

export const callForwardingRuleEmptyRule: CallForwardingRule = {
    id: '',
    weight: '',
    description: '',
    number: '',
    intervals: intervalFormInitialValues,
    ringFor: '15',
    enabled: true,
    keepOriginalCld: false,
    transportProtocol: TransportProtocol.UDP,
    callingPartyDisplay: CallingPartyDisplay.CallerNumberAndName,
};

export const forwardToError = i18n.t('errors:extensions.forwardTo');
export const sipError = i18n.t('errors:extensions.sipNumberEither');
export const max32Length = i18n.t('errors:extensions.max32Length');

export const extensionCallForwardingFormValidationSchema = Yup.object().shape({
    callForwardingRules: Yup.array().when('callForwardingStatus', {
        is: (value: any) => value,
        then:     Yup.array().when('callForwardingMethod', {
            is: (value: any) =>
                value === ForwardModeFlag.AdvancedForwarding ||
                value === ForwardModeFlag.ForwardToSIPUri,
            then: Yup.array().of(
                followRuleWithoutDescriptionSchema?.(followRuleValidationSchema) ||
                Yup.object(),
            ),
            otherwise: Yup.array().of(
                followRuleWithoutDescriptionSchema?.(
                    followRuleValidationSchemaWithoutSIP,
                ) || Yup.object(),
            ),
        }),
        otherwise: Yup.array().of(
            followRuleWithoutDescriptionSchema?.(
                numberNotReq,
            ) || Yup.object(),
        ),
    }),




});

export const extensionCallForwardingFormDefaultValues: ExtensionCallForwardingFormType = {
    callForwardingStatus: false,
    callForwardingLocked: false,
    callForwardingMethod: ForwardModeFlag.NoForwarding,
    callForwardingRuleOrder: FollowMeSequence.Simultaneous,
    callForwardingRules: [],
    callForwardingDeletedRules: [],
    setDefaultFollowMeSequence:true
};

export interface ExtensionCallForwardingFormProps {
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    permittedSipProxies?: string[];
}

export const useStyles = makeStyles(() => ({
    mainContainer: {
        padding: '22px 16px',
    },
    contentContainer: {
        margin: '0 20px',
    },
    radioContainer: {
        marginRight: 32,
    },
    row: {
        display: 'flex',
        alignItems: 'flex-start',
        margin: '3px 0',
    },
    phoneNumberInput: {
        width: 264,
    },
    sipProxyInput: {
        maxWidth: 244,
        width: 244,
        minWidth: 'unset',
    },
    transportProtocolSelect: {
        maxWidth: 144,
        width: 144,
        minWidth: 'unset',
    },
    timeoutInput: {
        width: 144,

        '& input[type="number"]::-webkit-inner-spin-button': {
            opacity: 1,
        },
        '& input[type="number"]::-webkit-outside-spin-button': {
            opacity: 1,
        },
        '& .MuiInput-input': {
            paddingRight: 5,
        },
    },
    controlMargin: {
        marginRight: 24,
    },
    callingPartySelect: {
        maxWidth: 264,
        width: 264,
        minWidth: 'unset',
    },
    secondRow: {
        margin: '24px 0',
    },
    numberLabel: {
        marginLeft: -90,
        marginTop: 17,

        '& .Mui-disabled': {
            '& .MuiInputAdornment-root': {
                color: Colors.Gray,
            },
        },
    },
    ruleContainer: {
        marginTop: 30,
    },
    tableContainer: {
        backgroundColor: 'transparent',
        border: 0,
        maxWidth: 1040,
        marginTop: 25,
        '& .MuiTableRow-root': {
            backgroundColor: 'transparent',
        },

        '& .MuiTable-root': {
            backgroundColor: 'transparent',
        },

        '& .drag-cell': {
            width: 40,
        },
    },
    ruleOrderSelect: {
        maxWidth: 240,
        width: 240,
        marginTop: 25,
        minWidth: 'unset',
    },
    ruleStatus: {
        marginLeft: -10,
    },
    badge: {
        paddingLeft: 15,
        '& > span.MuiBadge-badge': {
            backgroundColor: Colors.Gray4,
            color: Colors.Gray5,
        },
    },
    intervalText: {
        fontSize: 14,
        color: Colors.Text,
        '-webkit-line-clamp': 2,
        position: 'relative',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        maxHeight: 110,
        whiteSpace: 'normal',
        lineHeight: '20px',
    },
    otherInfoText: {
        fontSize: 14,
        color: Colors.Gray5,
    },
    otherInfoContainer: {
        width: 170,
    },
    createButton: {
        marginTop: 16,
    },
    sipText: {
        fontSize: 14,
        color: Colors.Gray5,
    },
    sipContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    sipHint: {
        fontSize: 12,
        color: Colors.PlaceHolder,
        maxWidth: 244,
        lineHeight: '12px',
        marginTop: 4,
    },
    removeDialogContainer: {
        '& .MuiDialogContent-root': {
            width: 325,
            minWidth: 325,
            backgroundColor: Colors.White,
        },
    },
    emptyListContainer: {
        fontSize: 14,
        color: Colors.Gray5,
        padding: '26px 0',
        borderBottom: `1px solid ${Colors.Border}`,
    },
    weightInput: {
        width: 80,
        '& input[type="number"]::-webkit-inner-spin-button': {
            opacity: 1,
        },
        '& input[type="number"]::-webkit-outside-spin-button': {
            opacity: 1,
        },
        '& .MuiInput-input': {
            paddingLeft: 12,
            paddingRight: 0,
        },

        '& .MuiInputLabel-root': {
            marginLeft: '12px!important',
        },
    },
    percentLabel: {
        marginLeft: -32,
        marginTop: 17,

        '& .Mui-disabled': {
            '& .MuiInputAdornment-root': {
                color: Colors.Gray,
            },
        },
    },
    horizontalAlign: {
        display: 'flex',
        alignItems: 'center',
    },
}));
