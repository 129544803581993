import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {Colors} from '../../styles/Colors';
import CompanyInfoForm from '../../components/Forms/MyProfile/CompanyInfo/CompanyInfoForm';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import {Permission, PermissionType} from "../../store/types/Permission";
import { generateTabs } from '../../utils/generateTabs';
import { useTranslation } from 'react-i18next';
import { useRawPermissions } from '../../hooks/usePermissions';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from '../../store/types';
import { CompanyInfoFormType } from '../../components/Forms/MyProfile/CompanyInfo/utils';
import { CustomerInfo, CustomerInfoDetails, CustomerOfficeType } from '../../store/types/CustomerInfo';
import { getCompanyInfoTransformer } from '../../utils/transformers';
import SelectField from '../../components/SelectField/SelectField';
import { getTabNumber } from '../../utils/getTabNumber';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import Loader from '../../components/Loader/Loader';
import { actions } from '../../store';
import { getPermission } from '../../utils/permissions/getPermission';

const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 41,
        padding: '0'
    },
    headerTitle: {
        height: 41,
        fontStyle: 'normal', 
        fontWeight: 500, 
        fontSize: 32, 
        lineHeight: 24, 
        display: 'flex', 
        alignItems: 'center', 
        color: Colors.Text
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1
    },
    extensionTop: {
        paddingTop: 57,
        display: 'flex',
        justifyContent: 'space-between',
    },
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    addButton: {
        '& .MuiButton-label': {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
        },
    },
    branchSelect: {
        width: '100%'
    },
    brachSelectContainer: {
        paddingLeft: 0,
        paddingRight: 90,
        paddingTop: 0,
        maxWidth: 912,
        width: '100%'
    },
    headerContainer: {
        display: 'flex',
        marginTop: 0,
        fontWeight: 500,
        background: Colors.SmokeBackground,
        padding: '48px 94px',
        paddingLeft: 0
    },
    bottomContainer: {
        height: 90
    },
    rowBoxHeader: {
        marginRight: 12,
        fontSize: 16,
        background: Colors.SmokeBackground
    },
    rootOnTopContainer: {
        background: Colors.SmokeBackground,
        width: '100%',
        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        paddingBottom: 90,
        overflowY: 'auto',
        maxHeight: '100vh',
        position: 'relative',
        height: '100vh',
        padding: 0
    }
}));

const MyCompanyDetails: React.VFC = () => {
    const classes = useStyles();
    usePageTitle();
    const { t } = useTranslation();
    const permissions = useRawPermissions();
    const dispatch = useDispatch();
    
    const companyInfoDetails = useSelector<ReduxState, CompanyInfoFormType>(
        (state) => state.company.companyInfoDetails as CompanyInfoFormType,
    );
    const i_office_type = useSelector<ReduxState, CustomerOfficeType | undefined>(
        (state) => state.generic?.customerOfficeType
    );
    const customerSubdivisionsList = useSelector<ReduxState, CustomerInfo[]>(
        (state) => state.generic?.customerSubdivisionsList || []
    );
    const [branchIndex, setBranchIndex] = useState<number>(0);
    const main_office_info = useSelector<ReduxState, CustomerInfoDetails | undefined>(
        (state) => state.generic?.globalCustomerInfo?.main_office_info
    );
    const isLoading = useSelector<ReduxState, boolean>(
        (state) => !!state.company.isLoading,
    );
    
    const [tabIndex, setTabIndex] = useState(
        getTabNumber([...Array(2).keys()]),
    );

    const branches = useMemo(
        () => {
            if(customerSubdivisionsList?.length && companyInfoDetails) {
                return customerSubdivisionsList.map(e =>
                    getCompanyInfoTransformer(e as CustomerInfoDetails)
                );
            } else {
                return [];
            }
        }, [customerSubdivisionsList, companyInfoDetails]);

    const branchesNames = useMemo(
        () => {
            return branches?.map((v) => v.name + (v.companyName ? ' - ' + v.companyName : '')) || [''];
        }, [branches]
    );

    const mainOfficeInfo = useMemo(
        () => {
                return getCompanyInfoTransformer(main_office_info || {});
        }, [main_office_info, companyInfoDetails]);

    useEffect(() => {
        dispatch(actions.getCompanyData.request());
    }, []);

    const noRootPermissionReadOnly = getPermission(permissions, ...Permission.MyCompany.CompanyInfo.value) === PermissionType.ReadOnly;
    const hqPermissionReadOnly = getPermission(permissions, ...Permission.MyCompany.CompanyInfo.Main.value) === PermissionType.ReadOnly;
    const branchPermissionReadOnly = getPermission(permissions, ...Permission.MyCompany.CompanyInfo.Branch.value) === PermissionType.ReadOnly;

    const { tabNames, tabs,perm } = useMemo(
        () => generateTabs(
        [
            {
                title: i_office_type === CustomerOfficeType.Hq
                    ? t('common:mainHq')
                    : t('common:branchSite'),
                permission: i_office_type === CustomerOfficeType.Hq
                    ? Permission.MyCompany.CompanyInfo.Main.value
                    : Permission.MyCompany.CompanyInfo.Branch.value,
                tab: (
                    <CompanyInfoForm
                        companyInfoDetails={companyInfoDetails}
                        readOnly={i_office_type === CustomerOfficeType.Hq
                            ? hqPermissionReadOnly
                            : branchPermissionReadOnly
                        }
                    />
                )
            },
            {
                title: i_office_type === CustomerOfficeType.Hq
                    ? t('common:branchSite')
                    : t('common:mainHq'),
                    permission: i_office_type === CustomerOfficeType.Hq
                        ? Permission.MyCompany.CompanyInfo.Branch.value
                        : Permission.MyCompany.CompanyInfo.Main.value,
                    skipReadOnly: true,
                    tab: i_office_type === CustomerOfficeType.Hq ? (
                    <div>
                        <SelectField
                                onChange={(_, value) => {
                                    const indx = branchesNames.indexOf(value);
                                    setBranchIndex(indx);
                                }}
                                label={t('screens:myCompany.branchOffice')}
                                items={branchesNames}
                                value={branchesNames?.length && branchIndex>=0 ? branchesNames[branchIndex] : ''}
                                dataQa="branch-select"
                                dataTestId="branch-select"
                                className={classes.branchSelect}
                                classes={{
                                    container: classes.brachSelectContainer
                                }}
                                getOptionLabel={(v: string) => v}
                                getOptionSelected={(
                                    option: string,
                                    value: string,
                                ) => value === option}
                                disableClearable
                                disableAutoSettingValue={true}
                                id="branch-select"
                            />
                        <CompanyInfoForm companyInfoDetails={branches[branchIndex]} hideCompanyName={true} readOnly={true} />
                    </div>
                ) : (
                    <CompanyInfoForm companyInfoDetails={mainOfficeInfo} hideCompanyName={false} readOnly={true} />
                ),
            }
        ],
        permissions,
    ), [i_office_type, companyInfoDetails, branchIndex]);
    
    const top = (
        <div className={classes.header}>
            <div className={classes.headerTitle}>{t('screens:myCompany.companyInfo')}</div>
        </div>
    );

    if (isLoading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="call-settings-loader"/>
            </div>
        );
    }

    return (
            <PermissionPlaceholder permission={Permission.MyCompany.CompanyInfo.value}>
                <PermissionProvider permission={Permission.MyCompany.CompanyInfo.value}>
                    {i_office_type == CustomerOfficeType.NoOffices ?
                    (
                        <div className={classes.rootOnTopContainer}>
                            <CompanyInfoForm companyInfoDetails={companyInfoDetails} scrollPaddingForNotTabMode={true} readOnly={noRootPermissionReadOnly}>
                                <div className={classes.headerContainer}>{top}</div>
                            </CompanyInfoForm>
                        </div>
                    ) : (
                        <div style={{position: 'relative', width: '100%'}}>
                            <DetailsWrapper
                                    tabs={tabs}
                                    tabsNames={tabNames}
                                    top={top}
                                    hideCancel
                                    defaultTabIndex={tabIndex}
                                    onTabChange={(_, index) => setTabIndex(index)}
                                    showActionButtons={false}
                                    doNotControlForms={true}
                                    perm={perm}
                                >
                            </DetailsWrapper>
                        </div>
                    )}
                </PermissionProvider>
            </PermissionPlaceholder>
    );
};

export default MyCompanyDetails;
