import React, {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import PasswordTextField from '../../../PasswordTextField/PasswordTextField';
import {ReduxState} from '../../../../store/types';
import {changePasswordValidationReqFiledsName, props2FA, useStyles} from './utils';
import {ConfigData, PasswordComplexityRules} from '../../../../store/types/ConfigData';
import PasswordRulesList from '../../../PasswordRulesList/PasswordRulesList';
import {getConfigFromPasswordRulesOrDefault} from '../../../../utils/passwordGenerator';
import classNames from 'classnames';
import TwoFaSetting from '../../../TwoFaSetting/TwoFaSetting';
import {ErrorTabInteface} from "../../RingGroups/generalFormUtils";
import {actions} from "../../../../store";
import {isAnyKEyFromSearchExistInSource} from "../../../../utils/isAnyKEyFromSearchExistInSource";

export type ChangePasswordFormType = {
    oldPassword: string;
    newPassword: string;
    confirmPassword?: string;
};

export type ChangePasswordFormProps = {
    rules?: {
        PasswordComplexityRules: PasswordComplexityRules[];
        PasswordMinLength: number;
    };
    handleAddValidation?: (newValue: boolean) => void;
    configData?: ConfigData
} & ErrorTabInteface;

export const changePasswordFormDefaultValues: ChangePasswordFormType = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
};

const ChangePasswordForm: React.VFC<ChangePasswordFormProps> = (
    {
        rules,
        handleAddValidation,
        configData,
        tabName,
        tabIndex
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const mfaError = useSelector((state: ReduxState) => state.auth?.individualMfaConfigError);
    const props2FAObject = props2FA();

    const [passwordRuleFollowed, setPasswordRuleFollowed] = useState({
        letters: false,
        numbers: false,
        symbols: false,
        length: false,
        uppercaseLetters: false,
        lowercaseLetters: false,
    });

    const markTabAsHaveError = (setTabError: boolean) => {
        if (tabName && tabIndex !== undefined) {
            dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
        }
    }

    const changePasswordErrors = useSelector<
        ReduxState,
        { [key in string]: string } | undefined
    >((state) => state.company.changePasswordErrors);

    const minPasswordLength = useSelector<ReduxState, number | undefined>(
        (state) => Number(state.generic.configData?.Web.PasswordMinLength),
    );

    const {
        values,
        handleChange,
        errors,
        setFieldError,
        setFieldValue,
    } = useFormikContext<ChangePasswordFormType>();

    const {oldPassword, newPassword} = values;

    useEffect(() => {
        handleAddValidation?.(!(!newPassword.length && !oldPassword.length));
    }, [newPassword, oldPassword]);

    useEffect(() => {
        if (changePasswordErrors?.old_password) {
            setFieldError('oldPassword', changePasswordErrors.old_password);
            markTabAsHaveError(true)
        }
    }, [changePasswordErrors]);


    useEffect(() => {

        const errorsKeys = Object.keys(errors);
        if (isAnyKEyFromSearchExistInSource(errorsKeys, changePasswordValidationReqFiledsName)) {
            markTabAsHaveError(true)
        } else {
            markTabAsHaveError(false)
        }

    }, [errors,values]);

    const setNewPassword = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        refreshPasswordRules(e.target.value);
        setFieldValue('newPassword', e.target.value);
    };

    const refreshPasswordRules = (value: string) => {
        const newPasswordRuleState = {
            letters: !!value.length && /[a-zA-Z]/g.test(value),
            uppercaseLetters: !!value.length && /[A-Z]/g.test(value),
            lowercaseLetters: !!value.length && /[a-z]/g.test(value),
            numbers: !!value.length && /\d/.test(value),
            symbols:
                !!value.length &&
                /[~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value),
            length: value.length >= (minPasswordLength ?? 6),
        };
        setFieldValue('newPassword', value)
        setPasswordRuleFollowed(newPasswordRuleState);
    };

    return (
        <div className={classes.inputs} data-qa="change-password-container">
            <Grid item className={classes.itemsContainer}>
                <Grid item xs={6} className={classes.inputsContainer}>
                    <PasswordTextField
                        id="oldPassword"
                        label={t('screens:myCompany.oldPassword')}
                        value={values.oldPassword}
                        onChange={handleChange}
                        dataQa="old-password-input"
                        setFieldError={setFieldError}
                        error={errors.oldPassword}
                        isRequired={true}
                        maxLength={32}
                    />
                    <PasswordTextField
                        id="newPassword"
                        label={t('screens:myCompany.newPassword')}
                        value={values.newPassword}
                        onChange={setNewPassword}
                        dataQa="new-password-input"
                        setFieldError={setFieldError}
                        error={errors.newPassword}
                        isRequired={true}
                        passwordRulesConfig={getConfigFromPasswordRulesOrDefault(
                            configData,
                            true
                        )}
                        refreshPasswordRules={refreshPasswordRules}
                        maxLength={32}
                    />
                </Grid>
                <Grid item xs={6} className={classes.descriptionContainer}>
                    <PasswordRulesList
                        passwordRuleFollowed={passwordRuleFollowed}
                        rules={rules ? {...rules, PasswordMinLength: minPasswordLength || 6} : undefined}
                    />
                </Grid>
            </Grid>

            {!mfaError && (
                <Grid item className={classNames(classes.itemsContainer, classes.itemsContainer2Fa)}>
                    <TwoFaSetting {...props2FAObject} />
                </Grid>
            )}
        </div>
    );
};

export default ChangePasswordForm;
