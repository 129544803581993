import {delay, put, select, takeLatest} from 'redux-saga/effects';
import * as actions from '../../actions';
import {api} from '../../services/axios';
import {AxiosResponse} from 'axios';
import {APIErrorInterface, ReduxState} from '../../types';
import {ActionType} from 'typesafe-actions';
import {AssignedExtension, RingGroupType, RingMembersWrapState} from '../../types/RingGroup';
import JSONFormData from '../../../utils/JSONFormData';
import {ExtensionsListItem} from '../../reducers/extensions/extensions/reducer';
import {toast} from 'react-hot-toast';
import {ApiFileList} from '../../types/ApiFile';
import i18n from '../../../services/i18n';
import {AddRingGroupForm} from '../../../views/RingGroups/AddRingGroup';
import {prepareRingGroupMemberData} from '../../../utils/extensions/prepareRingGroupMemberData';
import {CallQueue} from '../../types/CallQueue';
import {
    prepareRingGroupCallQueueAddData,
    prepareRingGroupCallQueueEditData,
} from '../../../utils/ringGroups/prepareRingGroupCallQueue';
import {compareObjectsAndReturnDifferencesInValues} from '../../../utils/compareObjects';
import qs from 'qs';
import {showErrorToast} from '../../../utils/showErrorToast';
import {Account, CallControl, CodecConverter, Customer} from '../../../services/endpoints';
import {CallWaitingFlag, ServiceFeature, ServiceFeatureName,} from '../../types/ServiceFeature';
import history from '../../../history';
import {getRoute, Routes} from '../../../routes/routes';
import {GetHuntGroupListResponse, GetSipCallsListResponse, SipCallState} from '../../actions/ringgroups/payloads';
import {ConverterHandler} from '../../types/CodeConverter';
import {CreateExtensionFaultCode} from '../../types/Extension';
import {AccountListResponse} from '../../types/Account';
import dayjs from '../../../services/customDayJs';
import {getDurationFromSec} from '../../../utils/transformers';
import {YesNo} from '../../types/CallScreening';

export const RING_GROUP_NOT_FOUND_BY_ID_ERROR_CODE = 'RING_GROUP_NOT_FOUND_BY_ID_ERROR_CODE';
export const ENABLE_NOTIFICATIONS_ACCESS_DENIED = 'Server.CallControl.enable_api_notifications.access_denied';
export const GET_SIP_CALLS_ACCESS_DENIED = 'Server.CallControl.get_sip_calls_list.access_denied';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const ERROR1 = 'Server.CallControl.sip.call_not_found';
const ERROR2 = 'Server.CallControl.sip.internal_server_error';
const ERROR3 = 'CallControl.callee_id.not_applicable';
const ERROR4 = 'Server.CallControl.callee_id.account_not_found';
const ERROR5 = 'Server.CallControl.callee_id.account_not_found';

const CALLS_ERRORS = [ERROR1, ERROR2, ERROR3, ERROR4, ERROR5];


export function* getRingGroupsList(
    action: ActionType<typeof actions.ringGroupsList.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const {filters} = yield select(
            (state: ReduxState) => state.ringgroups.ringGroupsList,
        );

        const body = new JSONFormData(session_id, csrf_token);

        const params: typeof filters = {
            limit: action.payload.limit,
            offset: action.payload.offset,
        };

        const extensionParams = {
            extension: !!filters && filters.extension,
            get_main_office_extensions: 1
        };

        body.setParams(extensionParams);

        const extensionResponse: AxiosResponse<{
            extensions_list: ExtensionsListItem[];
            total: number;
        }> = filters.extension
            ? yield api.post(Customer.GetExtensionsList, body)
            : null;

        !!filters.name && (params.name = filters.name);
        !!filters.groupNumber && (params.group_number = filters.groupNumber);
        !!filters.extension &&
        (params.i_c_ext =
            extensionResponse?.data?.extensions_list &&
            extensionResponse.data.extensions_list.length
                ? extensionResponse.data.extensions_list[0].i_c_ext
                : filters.extension);
        params.get_main_office_huntgroups = 1;

        body.setParams(params);

        const res: AxiosResponse<{
            huntgroup_list: RingGroupType[];
            total: number;
        }> = yield api.post(Customer.GetHuntGroupList, body);

        yield put(
            actions.ringGroupsList.success({
                items: res.data.huntgroup_list,
                total: res.data.total,
            }),
        );
    } catch (err) {
        //@ts-ignore
        yield put(actions.ringGroupsList.failure(err));
    }
}

export function* removeRingGroup(
    action: ActionType<typeof actions.removeRingGroup.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);

        const {filters} = yield select(
            (state: ReduxState) => state.ringgroups.ringGroupsList,
        );
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        yield api.post(Customer.DeleteCustomerHuntGroup, body);
        toast(i18n.t<string>('screens:ringGroups.ringGroupDeleted'));

        yield put(actions.removeRingGroup.success());

        if (action.payload.withRedirectToList) {
            location.replace('/ring-groups');
        } else {
            yield put(
                actions.ringGroupsList.request({
                    limit: 10,
                    offset: 0,
                    nameFilter: filters.name,
                    extensionFilter: filters.extension,
                }),
            );
        }
    } catch (error) {
        //@ts-ignore
        yield put(actions.removeRingGroup.failure(error));
    }
}

export function* getDataForNewRingGroupForm() {
    try {
        yield getRingbackToneFileList();
        yield getGroupMembersData();

        yield put(actions.prepareDataForAddNewRingGroup.success());
    } catch (err) {
        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
        yield put(
            //@ts-ignore
            actions.prepareDataForAddNewRingGroup.failure(err.response?.data),
        );
    }
}

export function* getGroupMembersData() {
    const {session_id, csrf_token} = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        get_main_office_huntgroups: 1,
        get_main_office_extensions: 1,
        offset: 0,
        limit: 1000
    });

    const ringGroups: AxiosResponse<{
        huntgroup_list: RingGroupType[];
        total: number;
    }> = yield api.post(Customer.GetHuntGroupList, body);

    const extensions: AxiosResponse<{
        extensions_list: ExtensionsListItem[];
        total: number;
    }> = yield api.post(Customer.GetExtensionsList, body);

    body.setParams({
        get_main_office_huntgroups: 1,
        get_main_office_extensions: 1,
        offset: 0,
        limit: 1000,
        with_enabled_unified_messaging: YesNo.Yes
    });

    const extensionsWithVoicemail: AxiosResponse<{
        extensions_list: ExtensionsListItem[];
        total: number;
    }> = yield api.post(Customer.GetExtensionsList, body);

    yield put(
        actions.groupMembersDetails.success({
            ringGroups: ringGroups.data.huntgroup_list,
            extensions: extensions.data.extensions_list,
            extensionsWithVoicemail: extensionsWithVoicemail.data.extensions_list
        }),
    );
}

export interface WrapUp extends ServiceFeature {
    isVisible: boolean,
    activity_monitoring: YesNo, // Call wrap-up
    call_wrap_up_time: number,   // Wrap-up duration, sec
    i_c_group: number,
    wrap_up_extend_time: number,  // Maximum wrap-up extension, sec
    hunt_while_wrapping_up: RingMembersWrapState,
    activity_monitoring_lock?: boolean
}

export interface HuntGroupInfo {
    huntgroup_info: {
        activity_monitoring: YesNo, // Call wrap-up
        call_wrap_up_time: number,   // Wrap-up duration, sec
        i_c_group: number,
        wrap_up_extend_time: number,  // Maximum wrap-up extension, sec
        hunt_while_wrapping_up: RingMembersWrapState
    }
}

function* getWrapUpData(i_c_group: number) {

    let init: WrapUp = {
        name: ServiceFeatureName.CallCenterActivity,
        attributes: [],
        isVisible: false,
        flag_value: YesNo.No,
        effective_flag_value: YesNo.No,
        activity_monitoring: YesNo.No,
        call_wrap_up_time: 120,
        i_c_group,
        wrap_up_extend_time: 45,
        hunt_while_wrapping_up: RingMembersWrapState.Always
    }

    try {
        const {service_features} = yield select((state) => state.generic.globalCustomerInfo.customer_info);

        const service = service_features.find((v: ServiceFeature) => v.name == ServiceFeatureName.CallCenterActivity);
        if (service_features && service) {
            const {session_id, csrf_token} = yield select((state) => state.auth);

            init = {
                ...service,
                isVisible: service.effective_flag_value,
                activity_monitoring_lock: service.effective_flag_value == YesNo.No
            };

            const body = new JSONFormData(session_id, csrf_token);

            body.setParams({
                i_c_group
            })

            const response: AxiosResponse<HuntGroupInfo> = yield api.post(
                Customer.GetHuntGroupInfo,
                body,
            );

            init = {...init, ...response.data.huntgroup_info};
        }

        return init;
    } catch (e) {
        return init;
    }
}

export function* getRingbackToneFileList() {
    try {
        const {session_id, csrf_token} = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            handler: ConverterHandler.RingBackTone,
        });

        const response: AxiosResponse<ApiFileList> = yield api.post(
            CodecConverter.GetFileList,
            body,
        );

        yield put(actions.ringbackTonesList.success(response.data.file_list));
    } catch (err) {
        //@ts-ignore
        yield put(actions.uploadRingbackTone.failure(err));
    }
}

export function* getOnHoldList() {
    try {
        const {session_id, csrf_token} = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            handler: ConverterHandler.AudioFile,
        });

        const response: AxiosResponse<ApiFileList> = yield api.post(
            CodecConverter.GetFileList,
            body,
        );

        yield put(actions.onHoldList.success(response.data.file_list));
    } catch (err) {
        yield put(actions.onHoldList.failure(err as Error));
    }
}

export function* getResponseMessageList(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    action: ActionType<typeof actions.onResponseMessageList.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        handler: ConverterHandler.ResponseMessage,
    });

    const response: AxiosResponse<ApiFileList> = yield api.post(
        CodecConverter.GetFileList,
        body,
    );

    yield put(actions.onResponseMessageList.success(response.data.file_list));
}

export function* uploadRingbackToneFile(
    action: ActionType<typeof actions.uploadRingbackTone.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            handler: 'ringback_tone',
            file_name: action.payload.name
                ? action.payload.name
                : action.payload.file.name,
        });

        body.append('upload_file', action.payload.file);

        yield api.post(CodecConverter.AddFile, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        yield getRingbackToneFileList();

        toast(i18n.t<string>('common:fileUploaded'));
        action.payload.callback && action.payload.callback();
        yield put(actions.uploadRingbackTone.success());
    } catch (err) {
        toast('Upload error.');

        //@ts-ignore
        yield put(actions.uploadRingbackTone.failure(err));
    }
}

export function* addRingGroup(
    action: ActionType<typeof actions.addRingGroup.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);

        const form = action.payload.form;

        body.setParams({
            name: form.name,
            id: form.number,
            hunt_sequence: form.ringStrategy,
            i_ringback_tone: form.ringbackTone,
            hunt_keep_original_cli: form.callerId,
            pickup_allowed: form.callPickupAllowed ? 'Y' : 'N',
        });

        const response: AxiosResponse<{ i_c_group: number }> = yield api.post(
            Customer.AddCustomerHuntGroup,
            body,
        );

        yield ringGroupMembers({members: []}, form, response.data.i_c_group);
        yield ringGroupCallQueue({}, form, response.data.i_c_group);

        toast(i18n.t<string>('screens:ringGroups.ringGroupCreated'));

        yield delay(1000);

        location?.replace(
            `ring-groups/${response.data.i_c_group}?${qs.stringify({
                tab: action.payload.redirectTab,
            })}`,
        );
        yield put(actions.addRingGroup.success());
    } catch (error) {
        showErrorToast(
            //@ts-ignore
            error?.response?.data?.faultstring || '', error?.response?.data?.faultcode || '',
            [
                CreateExtensionFaultCode.ExtHGNumberInUse,
                CreateExtensionFaultCode.ExtHGNameInUse,
            ],
        );
        //@ts-ignore
        yield put(actions.addRingGroup.failure(error?.response?.data));
    }
}

export function* createNewRingGroup(
    action: ActionType<typeof actions.createNewRingGroup.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);

        const form = action.payload.form;

        body.setParams({
            name: form.name,
            id: form.number,
            hunt_sequence: form.ringStrategy,
            hunt_keep_original_cli: form.callerId,
            pickup_allowed: form.callPickupAllowed ? 'Y' : 'N',
        });

        const response: AxiosResponse<{ i_c_group: number }> = yield api.post(
            Customer.AddCustomerHuntGroup,
            body,
        );

        if (form.callRecording !== undefined) {
            const flagValue = form.callRecording
                ? CallWaitingFlag.Enabled
                : CallWaitingFlag.Disabled;

            body.setParams({
                service_features: [
                    {
                        name: ServiceFeatureName.CallRecording,
                        effective_flag_value: flagValue,
                        flag_value: flagValue,
                    },
                ],
            });

            yield api.post(Customer.UpdateServiceFeatures, body);
        }
        yield ringGroupMembers({members: []}, form, response.data.i_c_group);
        yield ringGroupCallQueue({}, form, response.data.i_c_group);
        yield put(
            actions.ringGroupsList.request({
                limit: 10,
                offset: 0,
            }),
        );
        toast(i18n.t<string>('screens:ringGroups.ringGroupCreated'));

        action.payload.callback && action.payload.callback();

        history.push(
            getRoute(Routes.RingGroupsDetails, {
                id: response.data.i_c_group,
            }),
        );

        yield put(actions.createNewRingGroup.success());
    } catch (error) {
        showErrorToast(
            //@ts-ignore
            error.response?.data?.faultstring, error.response?.data?.faultcode,
            [
                CreateExtensionFaultCode.ExtHGNumberInUse,
                CreateExtensionFaultCode.ExtHGNameInUse,
            ],
        );
        //@ts-ignore
        yield put(actions.createNewRingGroup.failure(error?.response?.data));
    }
}

export function* ringGroupMembers(
    initialData: Partial<AddRingGroupForm>,
    changedData: Partial<AddRingGroupForm>,
    i_c_group: number,
) {
    if (changedData.members === undefined) {
        return;
    }

    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);

    const groupMembersDetails: AssignedExtension[] | undefined = yield select(
        (state: ReduxState) =>
            state.ringgroups.ringGroupDetails?.assigned_extensions,
    );

    const body = new JSONFormData(session_id, csrf_token);

    const oldExtensionsIds = initialData.members?.map((v) => v.id) || [];
    const newExtensionsIds = changedData.members?.map((v) => v.id) || [];

    const addAssingedExtensions: AssignedExtension[] = [];
    const changeAssingedExtensions: AssignedExtension[] = [];
    const removeAssingedExtensions: number[] = [];

    changedData.members?.forEach((v, index) => {
        if (oldExtensionsIds.includes(v.id)) {
            const i_cg_ext = groupMembersDetails?.find((w) => w.id === v.id)
                ?.i_cg_ext;
            changeAssingedExtensions.push({
                ...prepareRingGroupMemberData(v),
                hunt_order: index,
                i_cg_ext,
            });
        } else {
            addAssingedExtensions.push({
                ...prepareRingGroupMemberData(v),
                hunt_order: index,
            });
        }
    });

    initialData.members?.forEach((v) => {
        if (!newExtensionsIds.includes(v.id) && v.id) {
            const i_cg_ext = groupMembersDetails?.find((w) => w.id === v.id)
                ?.i_cg_ext;

            if (i_cg_ext) {
                removeAssingedExtensions.push(i_cg_ext);
            }
        }
    });

    const setupEmptyFields = (ae: AssignedExtension) => {
        return {
            ...ae,
            hunt_delay: ae.hunt_delay != undefined ? ae.hunt_delay : '',
            hunt_expire: ae.hunt_expire != undefined ? ae.hunt_expire : '',
        };
    };

    if (
        addAssingedExtensions.length ||
        changeAssingedExtensions.length ||
        removeAssingedExtensions.length
    ) {
        body.setParams({
            i_c_group,
            i_ringback_tone:
                changedData.ringbackTone || initialData.ringbackTone,
            add_extensions: addAssingedExtensions?.map(setupEmptyFields),
            chg_extensions: changeAssingedExtensions?.map(setupEmptyFields),
            del_extensions: removeAssingedExtensions,
        });

        yield api.post(Customer.UpdateCustomerHuntGroup, body);
    }
}

export function* ringGroupCallQueue(
    initialData: Partial<AddRingGroupForm>,
    changedData: Partial<AddRingGroupForm>,
    i_c_group: number,
) {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const groupMembersDetails: RingGroupType | undefined = yield select(
        (state: ReduxState) => state.ringgroups.ringGroupDetails,
    );

    if (changedData.callQueueStatus === undefined) {
        changedData.callQueueStatus = initialData.callQueueStatus;
    }

    if (initialData.callQueueStatus && !changedData.callQueueStatus) {
        body.setParams({
            i_c_queue: groupMembersDetails?.assigned_callqueue?.i_c_queue,
        });

        yield api.post(Customer.DeleteCallQueue, body);
    } else if (changedData.callQueueStatus) {

        //@ts-ignore
        let callQueue: CallQueue = {
            i_c_group,
            i_c_queue: groupMembersDetails?.assigned_callqueue?.i_c_queue,
        };

        if (initialData.callQueueStatus) {
            callQueue = {
                ...callQueue,
                ...prepareRingGroupCallQueueEditData(changedData),
            };
        } else {
            callQueue = {
                ...callQueue,
                ...prepareRingGroupCallQueueAddData(changedData),
            };
        }

        if (changedData.onHoldMusicFile && changedData.onHoldMusicFileName) {
            body.append('upload_file', changedData.onHoldMusicFile);
            callQueue.moh_action = 'set';
            callQueue.moh = changedData.onHoldMusicFileName;
        } else if (changedData.playOnHoldMusicStatus === false) {
            callQueue.moh_action = 'unset';
            callQueue.moh = '';
        }

        if (changedData.onIncomingCallLimit?.promptFile && changedData.onIncomingCallLimit?.promptName) {
            body.append('upload_file', changedData.onIncomingCallLimit.promptFile);
        }

        if (changedData.onMaxRingingTime?.promptFile && changedData.onMaxRingingTime?.promptName) {
            body.append('upload_file', changedData.onMaxRingingTime.promptFile);
        }

        if (changedData.onMaxWaitingTime?.promptFile && changedData.onMaxWaitingTime?.promptName) {
            body.append('upload_file', changedData.onMaxWaitingTime.promptFile);
        }

        body.setParams({callqueue_info: callQueue});

        const headers =
            changedData.onHoldMusicFile && changedData.onHoldMusicFileName
                ? {
                    'Content-Type': 'multipart/form-data',
                }
                : undefined;

        if (initialData.callQueueStatus) {
            if (Object.keys(callQueue).length > 2) {
                yield api.post(Customer.UpdateCallQueue,
                    body,
                    {
                        headers,
                    },
                );
            }
        } else {
            yield api.post(Customer.AddCallQueue,
                body,
                {
                    headers,
                },
            );
        }
    }
}

export function* getDataForRingGroupDetailsForm(
    action: ActionType<typeof actions.ringGroupDetails.request>,
) {
    try {

        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            i_c_group: action.payload.i_c_group,
            get_prompt_file: 1,
        });

        const response: AxiosResponse<{
            huntgroup_info: RingGroupType;
        }> = yield api.post(Customer.GetHuntGroupInfo, body);

        if (response.data?.huntgroup_info) {

            if (response.data.huntgroup_info?.assigned_callqueue?.i_c_queue) {
                const bodyQueueInfo = new JSONFormData(session_id, csrf_token);
                bodyQueueInfo.setParams({
                    i_c_queue: response.data.huntgroup_info.assigned_callqueue.i_c_queue
                });

                const responseQueueInfo: AxiosResponse<{
                    callqueue_info: CallQueue
                }> = yield api.post(Customer.GetCallQueueInfo, bodyQueueInfo);

                if (responseQueueInfo.data.callqueue_info) {
                    response.data.huntgroup_info.assigned_callqueue = responseQueueInfo.data.callqueue_info;

                    const bodyFiles = new JSONFormData(session_id, csrf_token);
                    bodyFiles.setParams({
                        handler: ConverterHandler.AudioFile,
                    });

                    const responseFiles: AxiosResponse<ApiFileList> = yield api.post(
                        CodecConverter.GetFileList,
                        bodyFiles,
                    );

                    if (response.data.huntgroup_info.assigned_callqueue?.on_incoming_call_limit?.i_prompt) {
                        const file = responseFiles.data.file_list?.find(e => e.id ===
                            response.data.huntgroup_info.assigned_callqueue?.on_incoming_call_limit?.i_prompt);
                        if (file) {
                            response.data.huntgroup_info.assigned_callqueue.on_incoming_call_limit.prompt_name = file.name;
                        }
                    }
                    if (response.data.huntgroup_info.assigned_callqueue?.on_max_ringing_time?.i_prompt) {
                        const file = responseFiles.data.file_list?.find(e => e.id ===
                            response.data.huntgroup_info.assigned_callqueue?.on_max_ringing_time?.i_prompt);
                        if (file) {
                            response.data.huntgroup_info.assigned_callqueue.on_max_ringing_time.prompt_name = file.name;
                        }
                    }
                    if (response.data.huntgroup_info.assigned_callqueue?.on_max_waiting_time?.i_prompt) {
                        const file = responseFiles.data.file_list?.find(e => e.id ===
                            response.data.huntgroup_info.assigned_callqueue?.on_max_waiting_time?.i_prompt);
                        if (file) {
                            response.data.huntgroup_info.assigned_callqueue.on_max_waiting_time.prompt_name = file.name;
                        }
                    }
                }
            }

            if (response.data.huntgroup_info.assigned_callqueue?.i_moh) {
                body.setParams({
                    id: response.data.huntgroup_info.assigned_callqueue?.i_moh,
                    info_only: '1',
                });

                const callQueueInfo: AxiosResponse<{
                    file_name: string;
                }> = yield api.post(CodecConverter.GetFile, body);
                response.data.huntgroup_info.assigned_callqueue.moh_file_name =
                    callQueueInfo.data.file_name;
            }


            const wrapUpData: WrapUp = yield getWrapUpData(action.payload.i_c_group);

            yield put(
                actions.ringGroupDetails.success({...response.data.huntgroup_info, wrapUp: wrapUpData}),
            );

            yield getRingbackToneFileList();
            yield getOnHoldList();
            yield getGroupMembersData();

            yield put(actions.prepareDataForAddNewRingGroup.success());
        } else {
            yield put(
                actions.ringGroupDetails.failure({
                    faultcode: RING_GROUP_NOT_FOUND_BY_ID_ERROR_CODE,
                    faultstring: i18n.t<string>('tooltips:ringGroups.ringGroupNotFound', {
                        value: action.payload.i_c_group
                    })
                } as APIErrorInterface),
            );
        }
    } catch (err) {
        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
        yield put(
            //@ts-ignore
            actions.prepareDataForAddNewRingGroup.failure(err.response?.data),
        );
    }
}

export function* editRingGroup(
    action: ActionType<typeof actions.editRingGroup.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);

        const ringGroup: Partial<RingGroupType> = {
            i_c_group: action.payload.i_c_group,
        };

        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        if (dataToSave.name) {
            ringGroup.name = dataToSave.name;
        }
        if (dataToSave.number) {
            ringGroup.id = dataToSave.number;
        }
        if (dataToSave.ringStrategy) {
            ringGroup.hunt_sequence = dataToSave.ringStrategy;
        }
        if (dataToSave.ringbackTone) {
            ringGroup.i_ringback_tone = dataToSave.ringbackTone;
        } else if (dataToSave.ringbackTone === null) {
            ringGroup.i_ringback_tone = null;
        }

        if (dataToSave.callerId) {
            ringGroup.hunt_keep_original_cli = dataToSave.callerId;
        }

        if (dataToSave.callPickupAllowed !== undefined) {
            ringGroup.pickup_allowed = dataToSave.callPickupAllowed ? 'Y' : 'N';
        }

        if (dataToSave.activity_monitoring !== undefined) {
            ringGroup.activity_monitoring = dataToSave.activity_monitoring ? YesNo.Yes : YesNo.No;
        }

        if (dataToSave.call_wrap_up_time !== undefined) {
            ringGroup.call_wrap_up_time = dataToSave.call_wrap_up_time || 0;
        }

        if (dataToSave.wrap_up_extend_time !== undefined) {
            ringGroup.wrap_up_extend_time = dataToSave.wrap_up_extend_time || 0;

        }

        if (dataToSave.hunt_while_wrapping_up !== undefined) {
            ringGroup.hunt_while_wrapping_up = dataToSave.hunt_while_wrapping_up || RingMembersWrapState.Always;
        }

        if (Object.keys(ringGroup).length > 1) {
            body.setParams(ringGroup);
            yield api.post(Customer.UpdateCustomerHuntGroup, body);
        }

        yield ringGroupMembers(
            action.payload.initialValues,
            dataToSave,
            action.payload.i_c_group,
        );
        yield ringGroupCallQueue(
            action.payload.initialValues,
            dataToSave,
            action.payload.i_c_group,
        );

        toast(i18n.t<string>('screens:ringGroups.ringGroupEdited'));

        yield delay(1000);

        if (!action.payload.blockRedirection) {
            location?.replace(
                `${location.href.substring(
                    0,
                    location.href.indexOf('?'),
                )}?${qs.stringify({
                    tab: action.payload.redirectTab,
                })}`,
            );
        }
        yield put(actions.editRingGroup.success());
    } catch (error) {
        showErrorToast(
            //@ts-ignore
            error.response?.data?.faultstring,
            //@ts-ignore
            error.response?.data?.faultcode,
            [
                CreateExtensionFaultCode.ExtHGNumberInUse,
                CreateExtensionFaultCode.ExtHGNameInUse,
            ],
        );
        //@ts-ignore
        yield put(actions.editRingGroup.failure(error?.response?.data));
    }
}

export function* getHuntGroupList(
    action: ActionType<typeof actions.getHuntGroupList.request>,
) {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    let limit = action.payload.limit;

    if (limit == undefined) {
        body.setParams({limit: 1});
        const limitRes: AxiosResponse<{ total: number }> = yield api.post(
            Customer.GetHuntGroupList,
            body,
        );
        limit = limitRes.data.total;
    }

    body.setParams({...action.payload, limit});
    const res: AxiosResponse<GetHuntGroupListResponse> = yield api.post(
        Customer.GetHuntGroupList,
        body,
    );

    yield put(actions.getHuntGroupList.success(res.data.huntgroup_list));
}

export function* changeCallQueueStatus(
    action: ActionType<typeof actions.editRingGroupCallQueueStatus.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        if (!action.payload.callQueueStatus && action.payload.i_c_queue) {
            body.setParams({
                i_c_queue: action.payload.i_c_queue,
            });
            yield api.post(Customer.DeleteCallQueue, body);
        } else {
            body.setParams({
                callqueue_info: {
                    i_c_group: action.payload.i_c_group,
                    ...prepareRingGroupCallQueueAddData({
                        //@ts-ignore
                        maximumNumberOfQueuedCallers: '10',
                    }),
                },
            });

            const response: AxiosResponse<{
                i_c_queue: number;
            }> = yield api.post(Customer.AddCallQueue, body);
            action.payload.i_c_queue = response.data.i_c_queue;
        }

        yield put(actions.editRingGroupCallQueueStatus.success(action.payload));
        toast(i18n.t<string>('screens:ringGroups.ringGroupEdited'));
    } catch (error) {
        //@ts-ignore
        if (error.response?.data?.faultstring) {
            //@ts-ignore
            toast(error.response?.data?.faultstring);
        }

        yield put(
            //@ts-ignore
            actions.editRingGroupCallQueueStatus.failure(error?.response?.data),
        );
    }
}

export function* holdCall(action: ActionType<typeof actions.holdCall.request>) {

    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {

        body.setParams({
            call: {
                id: action.payload.id,
                tag: action.payload.tag
            }
        });

        yield api.post(CallControl.HoldCall, body);

        if (action.payload.callback) {
            action.payload.callback();
        }

    } catch (e: any) {
        const apiError = e?.response?.data;

        if (apiError.faultcode) {
            handleErrors(apiError.faultcode, action.payload.callback);
        }
    }
}

export function* unHoldCall(action: ActionType<typeof actions.unHoldCall.request>) {

    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {

        body.setParams({
            call: {
                id: action.payload.id,
                tag: action.payload.tag
            }
        });

        yield api.post(CallControl.UnHoldCall, body);

        if (action.payload.callback) {
            action.payload.callback();
        }

    } catch (e: any) {
        const apiError = e?.response?.data;

        if (apiError.faultcode) {
            handleErrors(apiError.faultcode, action.payload.callback);
        }
    }
}


export function* terminateCall(action: ActionType<typeof actions.terminateCall.request>) {

    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {

        body.setParams({
            call: {
                id: action.payload.id,
                tag: action.payload.tag
            }
        });

        yield api.post(CallControl.TerminateCall, body);

        if (action.payload.callback) {
            action.payload.callback();
        }

    } catch (e: any) {
        const apiError = e?.response?.data;

        if (apiError.faultcode) {
            handleErrors(apiError.faultcode, action.payload.callback);
        }
    }
}

export function* joinToActiveCall(action: ActionType<typeof actions.joinToActiveCall.request>) {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {

        body.setParams({
            call_info: {...action.payload.call_info},
            callee_id: action.payload.extension?.id || '',
            participant_info: {...action.payload.participant_info}
        });


        yield api.post(
            CallControl.JoinToActiveCall,
            body,
        );

        if (action.payload.callback) {
            action.payload.callback();
        }

    } catch (e: any) {
        const apiError = e?.response?.data;

        if (apiError.faultcode) {
            handleErrors(apiError.faultcode, action.payload.callback);
        }
    }
}

function handleErrors(faultCode: string, callback?: () => void) {

    if (CALLS_ERRORS.includes(faultCode)) {
        let errorTranslationKey = faultCode;
        switch (faultCode) {
            case ERROR1:
                errorTranslationKey = 'callNotFound';
                break;
            case ERROR2:
            case ERROR3:
            case ERROR4:
            case ERROR5:
                errorTranslationKey = 'internalServerError';
                break;
        }

        toast(i18n.t<string>('errors:calls.' + errorTranslationKey, faultCode));
    }

    callback && callback();
}

export function* transferCall(action: ActionType<typeof actions.transferCall.request>) {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const cldId = action.payload.extTransferType
        ? action.payload.extension?.id : action.payload.ring?.id;

    try {
        body.setParams({
            call: {
                // @ts-ignore
                id: action.payload.callIdentifier.id,
                // @ts-ignore
                tag: action.payload.callIdentifier.tag
            },
            cld: cldId
        });


        yield api.post(
            CallControl.TransferCall,
            body,
        );

        if (action.payload.callback) {
            action.payload.callback();
        }

    } catch (e: any) {
        const apiError = e?.response?.data;

        if (apiError.faultcode) {
            handleErrors(apiError.faultcode, action.payload.callback);
        }
    }
}

export function* getSipCallsList(
    action: ActionType<typeof actions.getSipCallsList.request>,
) {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {

        // console.log({i_account,i_c_group,...action.payload})
        // @ts-ignore
        // body.setParams({i_account,i_c_group,...action.payload});

        yield api.post(
            CallControl.EnableApiNotifications,
            body,
        );

        body.setParams(action.payload);

        const resSipCalls: AxiosResponse<GetSipCallsListResponse> = yield api.post(
            CallControl.GetSipCallsList,
            body,
        );

        if (resSipCalls?.data?.calls_list?.length) {
            const formatTime = dateFormat.split(' ')[1];
            for (const call of resSipCalls.data.calls_list) {
                if (call.state === SipCallState.Connected) {
                    //For state connected, calculate the duration: 
                    //      server_time - connect_time and transform to HH:MM:SS format
                    if (resSipCalls.data.server_time && call.connect_time) {
                        const serverTime = dayjs(resSipCalls.data.server_time, dateFormat);
                        const connectTime = dayjs(call.connect_time, dateFormat);
                        const diff = dayjs.utc(serverTime.diff(connectTime));
                        call.duration = diff.format(formatTime);
                        const diff2 = serverTime.diff(connectTime) / 1000;
                        call.durationFormatSec = getDurationFromSec(diff2).string;
                    }
                } else {
                    //For others states calculate the duration: 
                    //  server_time - update_time and transform to HH:MM:SS format
                    if (resSipCalls.data.server_time && call.update_time) {
                        const serverTime = dayjs(resSipCalls.data.server_time, dateFormat);
                        const updateTime = dayjs(call.update_time, dateFormat);
                        const diff = dayjs.utc(serverTime.diff(updateTime));
                        call.duration = diff.format(formatTime);
                        const diff2 = serverTime.diff(updateTime) / 1000;
                        call.durationFormatSec = getDurationFromSec(diff2).string;
                    }
                }
            }
        }

        yield put(actions.getSipCallsList.success(resSipCalls.data.calls_list));

        action.payload.callback?.();
    } catch (error) {
        //@ts-ignore
        const apiError = error?.response?.data;
        yield put(
            actions.getSipCallsList.failure(apiError),
        );
    }
}

export function* getRingGroupsAccountList(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    action: ActionType<typeof actions.getRingGroupsAccountList.request>,
) {
    try {
        const {session_id, csrf_token} = yield select(
            (state: ReduxState) => state.auth,
        );

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            "has_extension": 1,
            "get_only_real_accounts": 1,
            "get_not_closed_accounts": 1,
            "get_status": 1,
            "get_total": 1,
            "limit": 1000,
            "offset": 0,
            "limit_alias_did_number_list": 100
        });

        const res: AxiosResponse<AccountListResponse> = yield api.post(Account.GetAccountList, body);
        yield put(actions.getRingGroupsAccountList.success(res.data?.account_list || []));
    } catch (error) {
        //@ts-ignore
        const apiError = error?.response?.data;
        yield put(actions.getRingGroupsAccountList.failure(apiError));
    }
}

export const ringGroupsSaga = [
    takeLatest(actions.joinToActiveCall.request, joinToActiveCall),
    takeLatest(actions.transferCall.request, transferCall),
    takeLatest(actions.holdCall.request, holdCall),
    takeLatest(actions.unHoldCall.request, unHoldCall),
    takeLatest(actions.terminateCall.request, terminateCall),
    takeLatest(actions.ringGroupsList.request, getRingGroupsList),
    takeLatest(actions.removeRingGroup.request, removeRingGroup),
    takeLatest(actions.uploadRingbackTone.request, uploadRingbackToneFile),
    takeLatest(actions.ringbackTonesList.request, getRingbackToneFileList),
    takeLatest(
        actions.prepareDataForAddNewRingGroup.request,
        getDataForNewRingGroupForm,
    ),
    takeLatest(actions.addRingGroup.request, addRingGroup),
    takeLatest(actions.createNewRingGroup.request, createNewRingGroup),

    takeLatest(
        actions.ringGroupDetails.request,
        getDataForRingGroupDetailsForm,
    ),
    takeLatest(actions.editRingGroup.request, editRingGroup),
    takeLatest(
        actions.editRingGroupCallQueueStatus.request,
        changeCallQueueStatus,
    ),
    takeLatest(actions.getHuntGroupList.request, getHuntGroupList),
    takeLatest(actions.onResponseMessageList.request, getResponseMessageList),
    takeLatest(actions.getSipCallsList.request, getSipCallsList),
    takeLatest(actions.getRingGroupsAccountList.request, getRingGroupsAccountList),
];
