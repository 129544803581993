import {useFormikContext} from 'formik';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../../store/types';
import {FollowMeSequence} from '../../../../store/types/AccountFollowMeSettings';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import {DialogButton} from '../../../AlertDialog/DialogContainer';
import CreationButton from '../../../CreationButton/CreationButton';
import DataGrid from '../../../DataGrid/DataGrid';
import {PaginationMode} from '../../../DataGrid/types';
import {reorder} from '../../../DataTable/DragDropDataTable';
import SelectField from '../../../SelectField/SelectField';
import {
    CallForwardingRule,
    ExtensionCallForwardingFormProps,
    ExtensionCallForwardingFormType,
    useStyles,
} from './CallForwardingForm.utils';
import {
    countRulesWeights,
    generateColumns,
    redistributeFullValuesToRulesWigth,
} from './FollowMeRulesList.utils';
import ForwardingRuleDialog from './ForwardingRuleDialog/ForwardingRuleDialog';
import {v4 as id} from 'uuid';
import {ForwardModeFlag} from "../../../../store/types/ServiceFeature";

const FollowMeRulesList: React.VFC<ExtensionCallForwardingFormProps> = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [
        isCallForwardingRuleDialogVisible,
        setIsCallForwardingRuleDialogVisible,
    ] = useState(false);
    const [isRemoveRuleDialogVisible, setIsRemoveRuleDialogVisible] = useState(
        false,
    );

    const [ruleToEdit, setRuleToEdit] = useState<CallForwardingRule | undefined>(undefined);
    const [ruleToEditIndex, setRuleToEditIndex] = useState<number | undefined>(
        undefined,
    );

    const permittedSipProxies = useSelector<ReduxState, string[]>(
        (state) => state.extensions.permittedSipProxies || [],
    );

    const {
        values,
        setFieldValue,
    } = useFormikContext<ExtensionCallForwardingFormType>();

    const onEditRule = useCallback(
        (rule: CallForwardingRule, index: number) => {
            setRuleToEdit(rule);
            setRuleToEditIndex(index);
            setIsCallForwardingRuleDialogVisible(true);
        },
        [],
    );

    const onRemoveRule = useCallback(
        (rule: CallForwardingRule, index: number) => {
            setRuleToEdit(rule);
            setRuleToEditIndex(index);
            setIsRemoveRuleDialogVisible(true);
        },
        [values.callForwardingRules],
    );

    const editRule = useCallback(
        (editedRule: CallForwardingRule) => {
            const callForwardingRules = [...values.callForwardingRules];
            if (ruleToEditIndex !== undefined && ruleToEdit) {
                callForwardingRules[ruleToEditIndex] = {
                    ...ruleToEdit,
                    ...editedRule,
                };
                setFieldValue('callForwardingRules', callForwardingRules);
                setIsCallForwardingRuleDialogVisible(false);
            }
        },
        [values.callForwardingRules, ruleToEdit, ruleToEditIndex],
    );

    const addRule = useCallback(
        (rule: CallForwardingRule) => {
            const callForwardingRules = [
                ...values.callForwardingRules,
                {
                    ...rule,
                    weight: !values.callForwardingRules.length ? '100' : '0',
                    id: `created_${id()}`,
                },
            ];
            setFieldValue('callForwardingRules', callForwardingRules);
            setIsCallForwardingRuleDialogVisible(false);
        },
        [values.callForwardingRules],
    );

    const removeRule = useCallback(() => {
        if (ruleToEditIndex !== undefined) {

            const weights = countRulesWeights(
                values.callForwardingRules,
                ruleToEditIndex,
                '0',
            ).filter((_, index) => index !== ruleToEditIndex);

            const afterRemove = values.callForwardingRules
                .filter((_, index) => {

                    if(index == ruleToEditIndex && !values.callForwardingRules[index].id.toString().includes('created'))
                    {
                        setFieldValue('callForwardingDeletedRules', [...values.callForwardingDeletedRules,values.callForwardingRules[index]]);
                    }

                    return index !== ruleToEditIndex
                })
                .map((v, index) => ({...v, weight: weights[index]}));

            setFieldValue(
                'callForwardingRules',
                afterRemove
            );

            setIsRemoveRuleDialogVisible(false);
        }
    }, [values.callForwardingRules, ruleToEditIndex]);

    const onChangeItemPosition = useCallback(
        (startIndex: number, endIndex: number) => {
            setFieldValue(
                'callForwardingRules',
                reorder(values.callForwardingRules, startIndex, endIndex),
            );
        },
        [values.callForwardingRules],
    );

    const onChangeRuleOrder = (
        _: unknown,
        value: { value: FollowMeSequence },
    ) => {
        setFieldValue('callForwardingRuleOrder', value.value);

        if (value.value === FollowMeSequence.PercentageList) {
            setFieldValue(
                'callForwardingRules',
                redistributeFullValuesToRulesWigth(values.callForwardingRules),
            );
        }
    };

    const columns = useMemo(
        () =>
            generateColumns(
                values.callForwardingMethod,
                values.callForwardingRuleOrder,
                t,
                classes,
                setFieldValue,
                onEditRule,
                onRemoveRule,
            ),
        [
            values.callForwardingRuleOrder,
            values.callForwardingMethod,
            setFieldValue,
        ],
    );

    const followMeSequences = Object.values(FollowMeSequence).map((v) => ({
        name: t(`enums:followMeSequences.${v}`),
        value: v,
    }));

    useEffect(() => {
        if ((values.callForwardingMethod === ForwardModeFlag.FollowMe ||
            values.callForwardingMethod === ForwardModeFlag.AdvancedForwarding)  &&
            values.callForwardingRuleOrder == undefined) {

            setFieldValue('callForwardingRuleOrder', FollowMeSequence.Simultaneous);

        }
    }, [values.callForwardingRules]);

    return (
        <>
            <SelectField
                dataQa="calling-party-display-select"
                label={t('screens:extensions.ruleOrder')}
                items={followMeSequences}
                value={followMeSequences.find(
                    (v) => v.value === values.callForwardingRuleOrder,
                )}
                getOptionLabel={(v: { name: string; value: string }) => v.name}
                getOptionSelected={(v: { name: string; value: string }) =>
                    v.value === values.callForwardingRuleOrder
                }
                onChange={onChangeRuleOrder}
                classes={{container: classes.ruleOrderSelect}}
                disableClearable
            />

            {!!values.callForwardingRules.length ? (
                <DataGrid<CallForwardingRule>
                    classes={{tableContainer: classes.tableContainer}}
                    columns={columns}
                    data={values.callForwardingRules}
                    rowCount={values.callForwardingRules?.length || 0}
                    dragEnabled={
                        values.callForwardingRuleOrder ===
                        FollowMeSequence.Order
                    }
                    onChangeItemPosition={onChangeItemPosition}
                    centeredRows
                    narrowRows
                    paginationMode={PaginationMode.Client}
                    hideHeader
                    hideFooter
                    hidePagination
                />
            ) : (
                <div className={classes.emptyListContainer}>
                    {t('screens:extensions.noForwardingRules')}
                </div>
            )}

            <CreationButton
                title={t('screens:extensions.addNewRule')}
                className={classes.createButton}
                onClick={() => {
                    setRuleToEdit(undefined);
                    setRuleToEditIndex(undefined);
                    setIsCallForwardingRuleDialogVisible(true);
                }}
            />

            <ForwardingRuleDialog
                isOpen={isCallForwardingRuleDialogVisible}
                toggleVisibility={() =>
                    setIsCallForwardingRuleDialogVisible(
                        !isCallForwardingRuleDialogVisible,
                    )
                }
                permittedSipProxies={permittedSipProxies}
                mode={values.callForwardingMethod}
                ruleToEdit={ruleToEdit}
                onEditRule={editRule}
                onAddRule={addRule}
            />

            <AlertDialog
                isOpen={isRemoveRuleDialogVisible}
                description={t('screens:extensions.deleteCallForwardingRule', {
                    name: ruleToEdit?.description,
                })}
                contentClass="alert-content"
                className={classes.removeDialogContainer}
                hideHeader={true}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() =>
                            setIsRemoveRuleDialogVisible(
                                !isRemoveRuleDialogVisible,
                            )
                        }
                    />,
                    <DialogButton
                        key="exlude"
                        label={t('common:delete')}
                        onClick={removeRule}
                    />,
                ]}
                dataQa="remove-forwarding-rule-modal"
                dataTestId="remove-forwarding-rule-modal"
            />
        </>
    );
};

export default FollowMeRulesList;
