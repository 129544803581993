import { YesNo } from './CallScreening';
import { APIErrorInterface } from './errors';

export enum ListFileFormat {
    CSV = 'csv',
    XLS = 'xls',
    XLSX = 'xlsx',
}

export interface UAInfo {
    name: string;
    used: string;
    ports_config: UAPortConfiguration[];
    mac: string;
    inventory_id: string;
    i_ua: number;
    type: string;
    busy_ports?: number[];
    available_ports?: number[];
    description?: string;
    profile?: string;
    i_tenant_customer?: number;
    tenant_customer_name?: string;
    is_multiservice?: number;
    shared?: string;
    ascii_key?: string;
    ua_profile_id?: number;
    i_customer?: number | null;
    i_ua_type?: number;
    ported?: 'Y' | 'N';
}

export interface UAInfoList extends UAInfo {
    id: number;
}

export interface UAPortConfiguration {
    account_id?: string;
    allowed_services?: UAPortApplicableServiceInfo[];
    i_account?: number;
    i_service_type?: number;
    physical_interface_number?: number;
    port: number;
    release_allowed: YesNo;
    extension_id?: string;
    i_did_number?: string;
    did_number?: string;
    extension_name?: string;
}

export interface UAPortApplicableServiceInfo {
    i_service_type: number;
}

export interface GetUAListResponse {
    total: number;
    ua_list: UAInfo[];
}

export enum UAUsage {
    Used = 'U',
    CompletelyUsedAllPorts = 'C',
    NotUsed = 'F',
    Default = 'A',
}

export interface GetUAListRequest {
    extension_id?: string;
    name?: string;
    mac?: string;
    inventory_id?: string;
    usage?: UAUsage | string;
    get_total: number;
    offset: number;
    limit?: number;
    with_available_ports?: number;
    with_busy_ports?: number;
    i_ua?: number;
    file_format?: ListFileFormat;
    callback?:()=>void
}

export interface AssignedPortInfo {
    device_name: string;
    i_account: number;
    i_ua: number;
    i_ua_link: number;
    i_ua_type: number;
    id: string;
    is_multiservice: number;
    mac: string;
    port: number;
    ua_info?: UAInfo;
}

export interface UAListPayload {
    items: UAInfo[];
    total: number;
    active?: number;
}

export interface DeviceFilters {
    extension_id?: string;
    name?: string;
    mac?: string;
    inventory_id?: string;
    usage?: UAUsage | string;
}

export interface ReleaseDevicePayload {
    i_c_ext: number;
    i_cpe?: string | null;
}

export interface ReleaseDeviceResponse {
    i_c_ext: number;
}

export interface GetAssignedCpePortsListRequest {
    get_total: number;
    i_account: number;
    limit: number;
    offset: number;
    withDeviceDetails?: boolean;
}

export interface GetUAInfoResponse {
    ua_info: UAInfo;
}

export interface GetAssignedCpePortsListResponse {
    assigned_cpe_ports_list: AssignedPortInfo[];
    total?: number;
}

export interface GetUATypeListRequest {
    get_total?: number;
    i_customer?: number;
    i_tenant_customer?: number;
    only_available?: number;
    with_hidden?: number;
    with_shared?: number;
    with_untenanted?: number;
}

export interface UATypeInfo {
    hidden?: number;
    i_ua_type?: number;
    manufacturer?: string;
    name?: string;
    ports?: number;
    product?: string;
    version?: string;
}

export interface UATypeListPayload {
    ua_type_list: UATypeInfo[];
    total?: number;
}

export interface AddUAPayload {
    i_ua: number;
}

export interface UploadFileListRequest {
    file_format?: ListFileFormat;
    file: File | null;
    name?: string;
    fileSizeReadable?: string;
    callback?: () => void;
    errorCallback?: (error: APIErrorInterface) => void;
}

export interface GetUploadFileApiErrorRequest {
    uuid: string;
    fileName: string;
    errorCallback?: (error: APIErrorInterface) => void;
}

export interface DeleteUARequest {
    i_ua: number;
    callback?: () => void;
}

export interface UpdateUaRequest {
    object: UAInfo;
    callback?: () => void;
}

export enum DevicesFaultCodes {
    EditDeviceMacDuplicate = 'Server.UA.mac_duplicate',
    EditDeviceGeneralError = 'Server.UA.internal_error'
}

export interface AssignUaRequest {
    i_account: number;
    i_ua: number;
    port: number;
    callback?: () => void;
}