import * as actions from '../../actions';
import {put, takeLatest, select, call} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {showErrorToast} from '../../../utils/showErrorToast';
import {AxiosError, AxiosResponse} from 'axios';
import JSONFormData from '../../../utils/JSONFormData';
import {api} from '../../services/axios';
import {
    AddUAPayload,
    GetAssignedCpePortsListResponse,
    GetUAInfoResponse,
    GetUAListResponse,
    ReleaseDevicePayload,
    ListFileFormat,
    UATypeListPayload,
    UAUsage,
} from '../../types/Devices';
import {ExtensionsListItem} from '../../reducers/extensions/extensions/reducer';
import {Account, Customer, Generic, UA} from '../../../services/endpoints';
import {APIErrorInterface} from '../../types';
import fileDownload from 'js-file-download';

export function* getDeviceList(
    action: ActionType<typeof actions.devicesList.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const {ua_type_list} = yield select((state) => state.devices);
    const {i_customer} = yield select((state) => state.generic.sessionData);

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams(action.payload);

    try {
        if (ua_type_list == undefined && i_customer) {
            yield call(getDeviceTypeList,
                actions.deviceTypeList.request({
                    get_total: 1,
                    i_customer: i_customer
                }));
        }

        const res: AxiosResponse<GetUAListResponse> = yield api.post(
            UA.GetUaList,
            body,
        );

        const useageOption = ['U', 'C'];

        body.setParams({
            ...action.payload,
            usage: action.payload.usage != undefined
            && useageOption.indexOf(action.payload.usage) != -1 ? action.payload.usage : 'U',
            get_total: 1,
            limit: 1,
        });

        const active: AxiosResponse<GetUAListResponse> = yield api.post(
            UA.GetUaList,
            body,
        );

        action.payload.callback?.();

        yield put(
            actions.devicesList.success({
                items: res.data.ua_list,
                total: res.data.total,
                active: action.payload.usage == 'F' ? 0 : active.data.total,
            }),
        );
    } catch (e) {
        // @ts-ignore
        showErrorToast(e);
        yield put(actions.devicesList.failure());
    }
}

export function* releaseDevice(
    action: ActionType<typeof actions.releaseDevice.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {
        if (action.payload.i_ua_link) {
            body.setParams({
                i_ua_link: action.payload.i_ua_link,
            });

            yield api.post(Account.ReleaseUA, body);

            action.payload.onSuccess?.();

            if (action.payload.withAssign === true) {
            } else {
                yield put(actions.releaseDevice.success());
            }
        } else {
            body.setParams({
                extension: action.payload.extension_id,
                get_main_office_extensions: 1
            });
            const extResponse: AxiosResponse<{
                extensions_list: ExtensionsListItem[];
                total: number;
            }> = yield api.post(Customer.GetExtensionsList, body);

            const {i_c_ext} = extResponse.data.extensions_list[0];
            const payload: ReleaseDevicePayload = {
                i_c_ext: i_c_ext || 0,
                i_cpe: null,
            };

            body.setParams(payload);
            yield api.post(Customer.UpdateCustomerExtension, body);

            yield put(actions.releaseDevice.success());
            action.payload.onSuccess?.();
        }

        action.payload.callback?.();
    } catch (e) {
        action.payload.callback?.();
        // @ts-ignore
        showErrorToast(e);
        yield put(actions.releaseDevice.failure());
    }
}

export function* fetchExtensionById(
    action: ActionType<typeof actions.getExtensionById.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        extension: action.payload.id,
        detailed_info: 1,
        get_main_office_extensions: 1
    });
    try {
        const res: AxiosResponse<{
            extensions_list: ExtensionsListItem[];
            total: number;
        }> = yield api.post(Customer.GetExtensionsList, body);

        const payload = {
            extension: res.data.extensions_list.length
                ? res.data.extensions_list[0]
                : undefined,
        };

        yield put(actions.getExtensionById.success(payload));
    } catch (e) {
        // @ts-ignore
        showErrorToast(e);
        yield put(actions.getExtensionById.failure());
    }
}

export function* assignDeviceToExtension(
    action: ActionType<typeof actions.assignDevice.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    if (action.payload.withRelease && action.payload.extension_id) {
        body.setParams({
            extension: action.payload.extension_id,
            get_main_office_extensions: 1
        });

        const extResponse: AxiosResponse<{
            extensions_list: ExtensionsListItem[];
        }> = yield api.post(Customer.GetExtensionsList, body);

        body.setParams({
            i_c_ext: extResponse.data.extensions_list[0].i_c_ext,
            i_cpe: null,
        });

        yield api.post(Customer.UpdateCustomerExtension, body);
    }

    body.setParams({
        port: action.payload.port,
        i_cpe: action.payload.i_cpe,
        i_c_ext: action.payload.i_c_ext,
        i_account: action.payload.i_account ? action.payload.i_account : undefined
    });

    try {
        yield api.post('Customer/update_customer_extension', body);
        yield put(actions.assignDevice.success());
        action.payload.onSuccess?.();
    } catch (e) {
        // @ts-ignore
        if (e.response?.data) {
            // @ts-ignore
            showErrorToast(e.response?.data.faultstring);
        } else {
            // @ts-ignore
            showErrorToast(e);
        }

        yield put(actions.assignDevice.failure());
    }
}

export function* fetchDeviceByIAccount(
    action: ActionType<typeof actions.fetchDeviceByIAccount.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    const {i_customer} = yield select((state) => state.generic.sessionData);

    try {
        body.setParams({
            i_account: action.payload.i_account,
            with_available_ports: 1
        });
        const res: AxiosResponse<GetUAInfoResponse> = yield api.post(
            UA.GetUaInfo,
            body,
        );

        body.setParams(action.payload);
        const resAvailable: AxiosResponse<GetAssignedCpePortsListResponse> = yield api.post(
            Account.GetAssignedCpePortsList,
            body
        );

        yield call(getDeviceTypeList,
            actions.deviceTypeList.request({
                get_total: 1,
                i_customer
            }));

        const items = resAvailable.data.assigned_cpe_ports_list || [];
        if (action.payload.withDeviceDetails && items.length > 0) {
            body.setParams({
                limit: 1000,
                offset: 0
            });
            const list: AxiosResponse<GetUAListResponse> = yield api.post(
                UA.GetUaList,
                body,
            );

            const devices = list.data?.ua_list ?? [];
            for (const port of items) {
                const device = devices.find(e => e.i_ua === port.i_ua);
                if (!device) continue;
                port.ua_info = device;
            }
        }

        yield put(
            actions.fetchDeviceByIAccount.success({
                items: items,
                available: res.data.ua_info
            }),
        );
    } catch (e) {
        // @ts-ignore
        showErrorToast(e);
        yield put(actions.fetchDeviceByIAccount.failure());
    }
}

export function* getDeviceTypeList(
    action: ActionType<typeof actions.deviceTypeList.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    try {
        body.setParams(action.payload);
        const res: AxiosResponse<UATypeListPayload> = yield api.post(
            UA.GetUaTypeList,
            body,
        );
        yield put(actions.deviceTypeList.success(res.data));
    } catch (e) {
        // @ts-ignore
        showErrorToast(e);
        yield put(actions.devicesList.failure());
    }
}

export function* addDevice(
    action: ActionType<typeof actions.addDevice.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    try {
        body.setParams({ua_info: action.payload});
        const res: AxiosResponse<AddUAPayload> = yield api.post(
            UA.AddUa,
            body,
        );
        yield put(actions.addDevice.success(res.data));
    } catch (e) {
        //@ts-ignore
        if ((e as AxiosError)?.isAxiosError) {
            const err = (e as AxiosError)?.response?.data as APIErrorInterface;
            yield put(actions.addDevice.failure(err));
        } else {
            yield put(actions.addDevice.failure(undefined));
        }
    }
}

export function* getDeviceListCsvFile(
    action: ActionType<typeof actions.getDeviceListCsvFile.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        ...action.payload,
        response_format: 'file'
    });

    try {
        const res: AxiosResponse<string> = yield api.post(
            UA.GetUaList,
            body,
            {responseType: 'blob'},
        );

        fileDownload(res.data, `${action.payload.fileName}.${action.payload.file_format || ListFileFormat.CSV}`);

        if (action.payload.callback) {
            action.payload.callback();
        }

        yield put(actions.getDeviceListCsvFile.success());
    } catch (e) {
        // @ts-ignore
        showErrorToast(e);
        yield put(actions.getDeviceListCsvFile.failure());
    }
}

export function* uploadDeviceListCsvFile(
    action: ActionType<typeof actions.uploadDeviceListCsvFile.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {
        body.setParams({
            file_name: action.payload.name,
            response_format: 'file',
            file_format: action.payload.file_format
        });

        if (action.payload.file) {
            body.append('upload_file', action.payload.file);

            yield api.post(UA.UploadUaList, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

        }

        if (action.payload.callback) {
            action.payload.callback();
        }

        yield put(actions.uploadDeviceListCsvFile.success());
    } catch (e) {
        // @ts-ignore
        if ((e as AxiosError)?.isAxiosError) {
            const err = (e as AxiosError)?.response?.data as APIErrorInterface;

            if (err && action.payload.errorCallback) {
                action.payload.errorCallback(err);
            }

            yield put(actions.uploadDeviceListCsvFile.failure(err));
        } else {
            yield put(actions.uploadDeviceListCsvFile.failure(undefined));
        }
    }
}

export function* getUploadUAApiErrorFile(
    action: ActionType<typeof actions.getUploadUAApiErrorFile.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        uuid: action.payload.uuid,
        keep_stored: 1
    });

    try {
        const res: AxiosResponse<string> = yield api.post(
            Generic.GetApiErrorFile,
            body,
            {responseType: 'blob'},
        );

        fileDownload(res.data, action.payload.fileName);

        yield put(actions.getUploadUAApiErrorFile.success());
    } catch (e) {
        // @ts-ignore
        if ((e as AxiosError)?.isAxiosError) {

            const data = (e as AxiosError)?.response?.data;
            if (data) {
                const isJsonBlob = data instanceof Blob && data.type === "application/json";

                //@ts-ignore
                const responseData = isJsonBlob ? (yield data?.text()) : data || {};
                const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;

                const err = responseJson as APIErrorInterface;
                if (err && action.payload.errorCallback) {
                    action.payload.errorCallback(err);
                }

                yield put(actions.getUploadUAApiErrorFile.failure(err));
            } else {
                yield put(actions.getUploadUAApiErrorFile.failure(undefined));
            }
        } else {
            yield put(actions.getUploadUAApiErrorFile.failure(undefined));
        }
    }
}

export function* deleteUA(
    action: ActionType<typeof actions.deleteUA.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams(action.payload);

    try {
        const res: AxiosResponse<{ success: number }> = yield api.post(
            UA.DeleteUA,
            body,
        );

        if (res.data.success === 1) {
            action.payload.callback?.();
            yield put(actions.deleteUA.success());
        } else {
            throw res.data;
        }
    } catch (e) {
        // @ts-ignore
        if (e.response) {
            // @ts-ignore
            showErrorToast(e.response.data.faultstring);
        } else {
            showErrorToast(undefined);
        }
        yield put(actions.devicesList.failure());
    }
}

export function* assignUA(
    action: ActionType<typeof actions.assignUA.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams(action.payload);

    try {
        const res: AxiosResponse<{ success: number }> = yield api.post(
            Account.AssignUA,
            body,
        );

        if (res.data.success === 1) {
            action.payload.callback?.();
            yield put(actions.assignUA.success());
        } else {
            throw res.data;
        }
    } catch (e) {
        action.payload.callback?.();
        // @ts-ignore
        if (e?.response) {
            // @ts-ignore
            showErrorToast(e.response.data.faultstring);
        } else {
            showErrorToast(undefined);
        }
        const err = (e as AxiosError)?.response?.data as APIErrorInterface;
        yield put(actions.assignUA.failure(err));
    }
}

export function* updateUa(
    action: ActionType<typeof actions.updateUa.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    try {
        body.setParams({ua_info: action.payload.object});
        yield api.post(
            UA.UpdateUa,
            body,
        );
        if (action.payload.callback) {
            action.payload.callback();
        }
        yield put(actions.updateUa.success());
    } catch (e) {
        //@ts-ignore
        if ((e as AxiosError)?.isAxiosError) {
            const err = (e as AxiosError)?.response?.data as APIErrorInterface;
            yield put(actions.updateUa.failure(err));
        } else {
            yield put(actions.updateUa.failure(undefined));
        }
    }
}

export const devicesSaga = [
    takeLatest(actions.devicesList.request, getDeviceList),
    takeLatest(actions.releaseDevice.request, releaseDevice),
    takeLatest(actions.getExtensionById.request, fetchExtensionById),
    takeLatest(actions.assignDevice.request, assignDeviceToExtension),
    takeLatest(actions.fetchDeviceByIAccount.request, fetchDeviceByIAccount),
    takeLatest(actions.deviceTypeList.request, getDeviceTypeList),
    takeLatest(actions.addDevice.request, addDevice),
    takeLatest(actions.getDeviceListCsvFile.request, getDeviceListCsvFile),
    takeLatest(actions.uploadDeviceListCsvFile.request, uploadDeviceListCsvFile),
    takeLatest(actions.getUploadUAApiErrorFile.request, getUploadUAApiErrorFile),
    takeLatest(actions.deleteUA.request, deleteUA),
    takeLatest(actions.updateUa.request, updateUa),
    takeLatest(actions.assignUA.request, assignUA),
];
