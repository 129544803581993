import React from 'react';
import i18n from 'i18next';
import {AssignedExtension, CallerID, RingStrategy} from '../../store/types/RingGroup';
import {Colors} from '../../styles/Colors';
import * as Yup from 'yup';
import {ReactComponent as CallHistory} from '../../assets/call_history.svg';
import DataItemActions from '../../components/DataTable/DataItemActions';
import {TFunction} from 'react-i18next';
import MembersList from '../../components/RingGroups/MembersList';
import history from '../../history';
import {Column} from 'react-table';
import {makeStyles} from '@material-ui/core';
import {RingGroupType} from '../../store/types/RingGroup';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';
import CustomizedIconButton from '../../components/IconButton/IconButton';
import classNames from 'classnames';
import {Routes, getRoute} from '../../routes/routes';
import {Permission, PermissionType} from '../../store/types/Permission';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import PermissionLinkCell from "../../components/DataTable/PermissionLinkCell";
import {CustomerOfficeType} from '../../store/types/CustomerInfo';
import {ReactComponent as StrategyRandom} from '../../assets/strategyRandom.svg';
import {ReactComponent as StrategyLeastUsed} from '../../assets/strategyLeastUsed.svg';
import {ReactComponent as StrategySimultaneous} from '../../assets/strategySimultaneous.svg';
import {ReactComponent as StrategyOrder} from '../../assets/strategyOrder.svg';
import {APIErrorInterface} from '../../store/types';
import {SipCall, SipCallState, SipCallType} from '../../store/actions/ringgroups/payloads';
import RingGroupsCallsCell from './RingGroupsCallsCell';
import RingGroupsIncomingCall from './RingGroupsIncomingCall';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        background: Colors.SmokeBackground,
        '& .MuiButton-label': {
            color: Colors.White,
        },
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '48px 96px',
        '& .MuiTableContainer-root': {
            width: 'unset !important',
            maxWidth: 1040
        },
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 17,
    },
    filtersContainer: {
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 1040
    },
    headerCell: {
        fontWeight: 700,
        fontSize: 12,
    },
    filterSeparation: {
        marginRight: 18,
        width: 'calc(50%)'
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    tableContainer: {
        maxWidth: 1040,
        overflowX: 'hidden',

        '& .MuiTableRow-head': {
            '& :nth-child(1)': {
                textAlign: 'center',
            },
        },

        '& table': {
            '& thead': {
                '& tr': {
                    '& td[aria-colindex="5"]': {
                        paddingLeft: '0px !important',
                    }
                }
            },

            '& tbody': {
                '& tr': {
                    '& td[aria-colindex="5"]': {
                        paddingLeft: '0px !important',
                    }
                }
            }
        }
    },
    centeredRow: {
        display: 'flex',
        justifyContent: 'center',
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
        }
    },
    searchButton: {
        marginLeft: 6,
        width: 96,
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
        height: 56,
        marginTop: 24,
        width: '100%'
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    actions: {
        display: 'flex',
        paddingLeft: 0,
        marginLeft: '-3.5rem',

        '& .MuiIconButton-root': {
            marginRight: 5,
        },
    },
    actionWrapper: {
        marginRight: 5,
        marginLeft: 5,
    },
    maxHeightCell: {
        flex: 1,
        height: 50,
        alignSelf: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        overflow: 'hidden',
    },
    disableOverflow: {
        overflow: 'unset',
    },
    marginFromIcon: {
        marginLeft: 8
    },
    strategy: {
        fontSize: 12,
        fontWeight: 400,
        color: "#111111"
    }
}));

export type CreateNewRingGroupFormData = {
    name: string;
    number: string;
    callerId: CallerID;
    ringStrategy: RingStrategy;
    callRecording: boolean;
};

const emptyInputText = i18n.t<string>('errors:common.emptyInput');

export const ringGroupFormValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required(emptyInputText)
        .max(32, i18n.t<string>('errors:ringGroups.numberMaxLength32')),
    number: Yup.string()
        .matches(/[0-9]+/, i18n.t<string>('errors:ringGroups.numberOnlyDigits'))
        .max(5, i18n.t<string>('errors:ringGroups.numberMaxLength5'))
        .required(emptyInputText),
    callerId: Yup.string().required(),
    ringStrategy: Yup.string().required(),
});

export const generateColumns = (
    t: TFunction<string>,
    ringGroupDelete: (id: number, name: string) => void,
    onCallHistoryClick: (param: RingGroupType) => void,
    classes: ReturnType<typeof useStyles>,
    i_office_type: CustomerOfficeType,
    i_customer: number,
    sipCallsList?: SipCall[],
    sipCallsApiError?: APIErrorInterface,
): Column<RingGroupType>[] => {
    return [
        {
            Header: t<string>('common:number'),
            accessor: 'id',
            Cell: function Cell(params) {
                return (
                    <div
                        className={classNames(
                            classes.maxHeightCell,
                            classes.centeredRow,
                        )}
                    >
                        <PermissionLinkCell
                            text={params.row.original.id}
                            onClick={() => {
                                history.push(
                                    getRoute(Routes.RingGroupsDetails, {
                                        id: params.row.original.i_c_group,
                                    }),
                                );
                            }}
                            permissions={Permission.CloudPBX.RingGroups.RingGroupDetails.value}
                            customizePermission={(value) => {
                                if (value === PermissionType.Visible && params.row.original.i_customer !== i_customer) {
                                    return PermissionType.Hidden;
                                }
                                return value;
                            }}
                        />
                    </div>
                );
            },
            maxWidth: 100,
            minWidth: 100,
        },
        {
            Header: t<string>('common:name'),
            accessor: 'name',
            Cell: function Cell(params) {
                return (
                    <div className={classes.maxHeightCell}>
                        <OverflowTooltip
                            dataQa="hunt_keep_orginal_cli"
                            text={params.row.original.name}
                            tooltip={params.row.original.name}
                            copy={false}
                        />
                    </div>
                );
            },
            width: 1,
        },
        {
            Header: t<string>('screens:ringGroups.ringStrategy'),
            accessor: 'hunt_sequence',
            Cell: function Cell(params) {
                const code = params.row.original.hunt_sequence;
                const value = t('enums:ringStrategy.' + code, code)

                return (
                    <div className={classNames(classes.maxHeightCell, classes.strategy)}>
                        {code === 'Order' && (<StrategyOrder></StrategyOrder>)}
                        {code === 'Random' && (<StrategyRandom></StrategyRandom>)}
                        {code === 'Simultaneous' && (<StrategySimultaneous></StrategySimultaneous>)}
                        {code === 'LeastUsed' && (<StrategyLeastUsed></StrategyLeastUsed>)}
                        <OverflowTooltip
                            dataQa="hunt_keep_orginal_cli"
                            text={value}
                            tooltip={value}
                            copy={false}
                            classes={{
                                text: classes.marginFromIcon
                            }}
                        />
                    </div>
                );
            },
            maxWidth: 160,
            minWidth: 160,
        },
        {
            Header: t<string>('screens:ringGroups.calls'),
            accessor: 'assigned_callqueue',
            Cell: function Cell(params) {
                const hideValue = sipCallsApiError !== undefined && sipCallsApiError != null;
                const rgId = params.row.original.id;
                const assignedCallQueueId = params.row.original.assigned_callqueue?.i_c_queue || -1;
                const active = sipCallsList?.filter((v: SipCall) =>
                    v.type === SipCallType.Incoming
                    && v.state === SipCallState.Connected
                    && v.callee?.huntgroup_id === rgId
                )?.map(e => e.tracking_id);
                const activeAmount = active ? [...new Set(active)].length : 0;
                const queued = sipCallsList?.filter((v: SipCall) =>
                    v.type === SipCallType.Incoming
                    && v.state === SipCallState.Queued
                    && (v.callee?.huntgroup_id === rgId || v.queue_info?.i_c_queue === assignedCallQueueId)
                )?.map(e => e.tracking_id);
                const queuedAmount = queued ? [...new Set(queued)].length : 0;
                const onHold = sipCallsList?.filter((v: SipCall) =>
                    v.type === SipCallType.Incoming
                    && v.state === SipCallState.Holding
                    && (v.callee?.huntgroup_id === rgId || v.queue_info?.i_c_queue === assignedCallQueueId)
                )?.map(e => e.tracking_id);
                const onHoldAmount = onHold ? [...new Set(onHold)].length : 0;
                return (
                    <div className={classes.maxHeightCell} data-testid='calls-labels-container'>
                        {hideValue
                            ? (<div> &mdash; </div>)
                            : (<RingGroupsIncomingCall
                                queued={queuedAmount}
                                active={activeAmount} 
                                onHold={onHoldAmount} 
                                />)
                        }
                    </div>
                );
            },
            maxWidth: 160,
            minWidth: 160,
        },
        {
            Header: t<string>('screens:ringGroups.groupMembers'),
            accessor: 'assigned_extensions',
            Cell: function Cell(params) {
                const extensions = params.row.original.assigned_extensions?.filter(
                    (v: AssignedExtension) => v.type === 'Account',
                ) || [];
                const ringGroups = params.row.original.assigned_extensions?.filter(
                    (v: AssignedExtension) => v.type === 'Group',
                ) || [];

                return (
                    <MembersList
                        extensions={extensions}
                        ringGroups={ringGroups}
                    />
                );
            },
            minWidth: 170,
            maxWidth: 170,
        },
        {
            Header: '',
            accessor: 'hunt_keep_original_cli',
            Cell: function Cell(params) {
                return (
                    <div className={classes.maxHeightCell}>
                        <RingGroupsCallsCell
                            assignedExtensions={params.row.original.assigned_extensions}
                            sipCalls={sipCallsList}
                            sipCallsApiError={sipCallsApiError}
                        />
                    </div>
                );
            },
            minWidth: 170,
            maxWidth: 170,
        },
        {
            Header: t<string>('common:actions'),
            accessor: 'i_c_group',
            Cell: function Cell(params) {
                return (
                    <div
                        className={classNames(
                            classes.maxHeightCell,
                            classes.disableOverflow,
                        )}
                    >
                        <div className={classes.actions}>
                            <div className={classes.actionWrapper}>
                                <PermissionProvider
                                    permission={
                                        Permission.CloudPBX.RingGroups
                                            .ViewCallHistory.value
                                    }
                                    skipReadOnly
                                >
                                    <CustomizedIconButton
                                        onClick={() =>
                                            onCallHistoryClick(
                                                params.row.original,
                                            )
                                        }
                                        data-testid="view-call-history-list-item-button"
                                        data-qa="view-call-history-list-item-button"
                                        tooltipText={t(
                                            'tooltips:ringGroups.viewCallHistory',
                                        )}
                                    >
                                        <CallHistory/>
                                    </CustomizedIconButton>
                                </PermissionProvider>
                            </div>

                            <DataItemActions
                                onDelete={() =>
                                    ringGroupDelete(
                                        params.row.original.i_c_group,
                                        params.row.original.name,
                                    )
                                }
                                onEdit={() => {
                                    history.push(
                                        getRoute(Routes.RingGroupsDetails, {
                                            id: params.row.original.i_c_group,
                                        }),
                                    );
                                }}
                                editPermission={
                                    Permission.CloudPBX.RingGroups
                                        .RingGroupDetails.value
                                }
                                deletePermission={
                                    Permission.CloudPBX.RingGroups
                                        .DeleteRingGroup.value
                                }
                                customizeDeletePermission={(value) => {
                                    if (value === PermissionType.Visible && i_office_type === CustomerOfficeType.Branch) {
                                        return PermissionType.ReadOnly;
                                    }
                                    return value;
                                }}
                                skipEditPermissionReadOnly={false}
                                customizeEditPermission={(value) => {
                                    if (value === PermissionType.Visible && params.row.original.i_customer !== i_customer) {
                                        return PermissionType.ReadOnly;
                                    }
                                    return value;
                                }}
                            />
                        </div>
                    </div>
                );
            },
            minWidth: 140,
            maxWidth: 140,
        },
    ];
};
