import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InvoiceFilters from './InvoiceFilters';
import CheckboxWithIcon from '../../../components/Checkbox/CheckboxWithIcon';
import { useTranslation } from 'react-i18next';
import DataGrid from '../../../components/DataGrid/DataGrid';
import {
    generateColumns,
    InvoiceTableRow,
    useStyles,
} from './InvoiceList.utils';
import { actions } from '../../../store';
import { ReduxState } from '../../../store/types';
import {
    convertUserLocalDateToUtc,
    convertUserLocalTimeToUtc,
} from '../../../utils/dateWithTimezoneConversion';
import dayjs from '../../../services/customDayJs';
import { invoiceEntityTransformer } from '../../../utils/transformers';
import {
    PageChangeEvent,
    PaginationMode,
} from '../../../components/DataGrid/types';
import toast from 'react-hot-toast';
import { InvoiceFiltersType, statusOptions } from './InvoiceFilters.utils';

const InvoiceList = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [items, setItems] = useState<{
        data: InvoiceTableRow[];
        total: number;
    }>({ data: [], total: 0 });

    const invoiceList = useSelector(
        (state: ReduxState) => state.billing.invoicesTab?.invoiceList,
    );
    const isLoading = useSelector(
        (state: ReduxState) => state.billing.invoicesTab?.isLoading,
    );
    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );
    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_date_time_format ||
            '',
    );

    const userDateFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_date_format ||
            '',
    );


    const curency = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.iso_4217 || 'USD',
    );

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [isDownloadingGoingOn, setIsDownloadingGoingOn] = useState<boolean[]>(
        [],
    );

    const [filters, setFilters] = useState<InvoiceFiltersType>({
        invoiceNumber: '',
        statusFilter: statusOptions[0],
        afterFilter: convertUserLocalTimeToUtc(
            dayjs().subtract(6, 'months').format(),
            timezoneOffset,
        ),
        beforeFilter: '',
        coveringFilter: '',
        showVoid: false,
    });

    const handlePageChange = (param: PageChangeEvent) => {
        setPage(param.page);
    };

    const handlePageSizeChange = (param: PageChangeEvent) => {
        setPageSize(param.pageSize);
        setPage(0);
    };
    const toogleTooltipVisibility = (index: number) => {
        const newDownloadings = [...isDownloadingGoingOn];
        newDownloadings[index] = false;
        setIsDownloadingGoingOn(newDownloadings);
    };

    const downloadFile = (invoice_number: number, index: number) => {
        const newDownloadings = [...isDownloadingGoingOn];
        newDownloadings[index] = true;
        setIsDownloadingGoingOn(newDownloadings);
        toast(t<string>('common:downloadWillStartShortly'));
        dispatch(
            actions.getInvoiceSelectedFile.request({
                invoice_number: invoice_number,
                get_pdf: '1',
                fileName: t('screens:billing.invoiceName', {
                    number: invoice_number,
                }),
                callback: () => toogleTooltipVisibility(index),
            }),
        );
    };

    const columns = useMemo(() => {
        return generateColumns(
            t,
            curency,
            timezoneOffset,
            downloadFile,
            userDateTimeFormat,
        );
    }, [isLoading, timezoneOffset, userDateTimeFormat, curency]);

    const fetchData = () => {
        dispatch(
            actions.getInvoicesListAction.request({
                issued_after: filters.afterFilter
                    ? convertUserLocalDateToUtc(
                          filters.afterFilter,
                          timezoneOffset,
                      )
                    : undefined,
                issued_before: filters.beforeFilter
                    ? convertUserLocalDateToUtc(
                          filters.beforeFilter,
                          timezoneOffset,
                      )
                    : undefined,
                covers_date: filters.coveringFilter
                    ? convertUserLocalTimeToUtc(
                          filters.coveringFilter,
                          timezoneOffset,
                      )
                    : undefined,
                status: filters.statusFilter,
                hide_void: !filters.showVoid ? 1 : 0,
                invoiceNumber: filters.invoiceNumber,
                limit: pageSize,
                offset: page * pageSize,
            }),
        );
    };

    useEffect(() => {
        fetchData();
    }, [pageSize, page]);

    useEffect(() => {
        if (invoiceList) {
            const { items, total } = invoiceList;
            setItems({
                total: total || 0,
                data: items.map(invoiceEntityTransformer) as InvoiceTableRow[],
            });
        }
    }, [invoiceList]);

    return (
        <>
            <div style={{ maxWidth: 1140, minWidth: 1002 }}>
                <InvoiceFilters
                    initData={filters}
                    onClick={fetchData}
                    onChange={(values: InvoiceFiltersType) => setFilters(values)}
                    userDateTimeFormat={userDateTimeFormat}
                    userDateFormat={userDateFormat}
                />
            </div>
            <div style={{ maxWidth: 1040, minWidth: 1002 }}>

                <CheckboxWithIcon
                    dataQa={'show-void-invoice-checkbox'}
                    label={t('screens:billing.invoicesCheckboxLabel')}
                    tooltipText={t('tooltips:billing.invoicesCheckbox')}
                    containerStyle={classes.checkbox}
                    onChange={() =>
                        setFilters((prevState) => ({
                            ...prevState,
                            showVoid: !prevState.showVoid,
                        }))
                    }
                    checked={filters.showVoid}
                    value={filters.showVoid}
                    skipPermission
                />
                <DataGrid<InvoiceTableRow>
                    data={items.data}
                    columns={columns}
                    rowCount={items.total}
                    loading={isLoading}
                    classes={{
                        header: classes.header,
                        tableContainer: classes.tableContent,
                    }}
                    paginationMode={PaginationMode.Server}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    initialPageSize={10}
                    dataQa={'invoice-table'}
                />
            </div>
        </>

    );
};

export default InvoiceList;
